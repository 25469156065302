export default {
	"830753-0": "Recharger",
	"830753-1": "Effectuer un rechargement d'actifs rapidement",
	"830753-2": "Veuillez télécharger le justificatif",
	"830753-3": "Confirmer le rechargement",
	"830753-4": "Données de réponse du serveur incorrectes:",
	"830753-5": "Échec du téléchargement de l'image:",
	account: {
		Start_making_money_plan: "Commencez à gagner de l'argent avec un plan",
		Market: "Marché",
		Migital_currency: "Monnaie Migital",
		Foreign_exchange: "Change de devises",
		Noble_metal: "métal noble",
		Send_Cryop_Now: "Envoyer Cryop maintenant",
		Send: "Envoyer",
		Receive: "Recevoir",
		Buy: "Acheter",
		Currency_account: "Compte en devises",
		Contract_account: "Compte de contrat",
		Transfer: "Transfert",
		Select_a_wallet: "Sélectionnez un portefeuille",
		Select_Coin: "Sélectionner une pièce",
		Confirm: "Confirmer",
		Wallet: "Portefeuille",
		Availavle: "Disponible",
		Frozen: "Congelé",
		Convert: "Convertir",
		Receiving_Address: "Adresse de réception",
		Amount: "Montant",
		Max: "Max",
		Send_Now: "Envoyer maintenant",
		Please_check_if: "Veuillez vérifier si votre adresse de livraison est correcte avant l'envoi pour éviter toute perte d'actifs.",
		new1:'crypto-monnaie',
		new2:'indice',
		new3:'En gros',
		new4:'Forex',
		new5:'solde total',
	},
	"314962-0": "Google 2FA",
	"314962-1": "Téléchargez Google Authenticator pour lier",
	"314962-2": "Copier le code de vérification",
	"314962-3": "Entrez le code de vérification",
	"314962-4": "Demande échouée:",
	"314962-5": "Code de vérification copié",
	"314962-6": "Impossible de copier le texte:",
	"314962-7": "Échec de la copie, veuillez réessayer",
	"199917-0": "Paramètres",
	"199917-1": "Notifications",
	"199917-2": "Google 2FA",
	"199917-3": "Email",
	"199917-4": "Langue",
	"199917-5": "Version",
	"199917-6": "Déconnexion",
	"516529-0": "Paramètres du compte",
	"516529-1": "Comprendre vos informations de compte",
	"516529-2": "Support en ligne",
	"516529-3": "Partager l'application",
	"516529-4": "Paramètres de base",
	"516529-5": "Paramètres de l'e-mail",
	"516529-6": "Changer la langue",
	"516529-7": "Alerte de prix",
	"516529-8": "Légal et information",
	"516529-9": "Conditions d'utilisation",
	"516529-10": "Politique anti-blanchiment",
	"516529-11": "Politique des cookies",
	"516529-12": "Obtenir des réponses et de l'aide",
	"516529-13": "Démarrage",
	"516529-14": "Échanger des jetons",
	"516529-15": "Dépannage",
	"516529-16": "Recommander à des amis",
	"516529-17": "Partager avec des amis et obtenir des commissions de parrainage",
	"516529-18": "Copier votre lien",
	"516529-19": "Configurer votre e-mail pour recevoir des mises à jour sur le marché",
	"516529-20": "Vérifier maintenant",
	"516529-21": "Entrez votre e-mail...",
	"516529-22": "Entrez le code de vérification...",
	"516529-23": "Votre e-mail d'abonnement ne sera utilisé que pour des informations utiles ou des rappels liés à nos services et ne sera pas abusé !",
	"516529-24": "Enregistrer",
	"516529-25": "Sélectionnez votre langue de site web",
	"516529-26": "Nous sommes engagés à protéger la sécurité et la confidentialité de vos données",
	"516529-27": "Chinois traditionnel",
	"516529-28": "Japonais",
	"516529-29": "Envoyer le code de vérification",
	"516529-30": "Copié avec succès !",
	"516529-31": "Renvoyer",
    home:{
        USDTAccount: 'USDT Account',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: 'Open',
        ETHTodayProfit: 'ETH Today\'s Profit',
        totalRevenue: 'Total Revenue',
        portfolio: 'Portfolio',
        reliableSecurity: 'Reliable Security Guarantee',
        stableReliable: 'Stable and Reliable Investment',
        convenienEasy: 'Convenien and Easy Operation',
        pleaseLookForward: 'Please look forward to...',
        rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
    },
	"ConnectW":"Connect Wallet",
	"dappbrowser":"This website only accepts access from decentralized wallet DAPP browsers.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "Earn Interest",
    "$": "$",
    "ETH Today's Profit": "ETH Today's Profit",
    "Wallet": "Wallet",
    "Record": "Record",
    "Support": "Support",
    "General": "General",
    "Notifications": "Notifications",
    "Invite Friends": "Invite Friends",
    "FAQ": "FAQ",
    "Privacy & Security": "Privacy & Security",
    "Legality": "Legality",
	"Authenticator":"Authenticator",
	"Settings":"Settings",
	"Change Language":"Change Language",
	"Daytime mode":"Daytime mode",
	"Black mode":"Black mode",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',





    //钱包
    TransactiónDetails:'Transactión Details',
	dealdetails:'deal details',
    ViewTransactionHistory:'View Transaction History',
    Deposit:'Deposit',
    Rechargeaddress:'Recharge address：',
    DepositAmount:'Deposit Amount：',
    EnterDepositAmount:'Enter Deposit Amount',
    EnterHash:'Enter Hash',
    Hash:'Hash：',
    Voucherimage:'Voucher image：',
    Continue:'Continue',
    Withdraw:'Withdraw',
    Withdrawal:'Withdrawal：',
    EnterAmounttoWithdraw:'Enter Amount to Withdraw',
    Available:"Available：",
    ReceiveAddress:'Receive Address：',
    EnterWalletAddress:'Enter Wallet Address',
    WithdrawalDesc:'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
    Exchange:'Exchange',
    Send:'Send:',
    Max:'Max:',
    rechargeAmount:'Please input a recharge amount',
    hashcode:'hash code and screenshot cannot be empty at the same time',
    correcthashcode:'please input a correct hash code',
    maximumwithdrawalamount:'The maximum withdrawal amount is',
    Incorrectamountformat:'Incorrect amount format',
	copy:'copy',


	//tab2
	"Total Profit":"Total Profit",
	"Today's Profit":"Today's Profit",
	"What is Automated Trading?":"What is Automated Trading?",
	"Learn about earning":"Learn about earning",
	"Staking Period":"Staking Period",
	"Staking Amount":"Staking Amount",
	"Yield":"Yield",
	"Limit":"Limit",
	"Subscribe":"Subscribe",
	"Record":"Record",

	//tab3
	"Proof of Stake":"Proof of Stake",
	"ETH Today is Price":"ETH Today is Price",
	"ETH Today's Increase":"ETH Today's Increase",
	"Joint Staking":"Joint Staking",
	"Individual Staking":"Individual Staking",
	"tab3long1":"Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2":"Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1":"Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2":"By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3":"Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4":"Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction":"Introduction",
	"webviewlong5":"Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6":'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process":"The Staking Process",
	"webviewlong7":"To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks":"Staking Rewards and Risks",
	"webviewlong8":"By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking":"Joint Staking and Individual Staking",
	"webviewlong9":"For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion":"Conclusion",
	"webviewlong10":"Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total":"Total",

	//record
	"Account":"Account",
	"interest-bearing":"interest-bearing",
	"POS Staking":"POS Staking",
	"convert":"convert",
	"transaction":"transaction",
	"pledge":"pledge",
	"recordno":"You don't have any news yet. The list is empty.",
	"Oops":"Oops",
	"recharge":"recharge",
	"payment":"payment",
	"staking":"staking",
	"income":"income",
	"Pledge quantity":"Pledge quantity",
	"cycle":"cycle",
	"Days Remaining":"Days Remaining",
	"cumulative gain":"cumulative gain",
	"Price increase":"Price increase",
	"Price decrease":"Price decrease",
	"Opening price":"Opening price",
	"Closing price":"Closing price",
	"loss":"loss",
	"pfofit":"pfofit",
	"amount":"amount",

	//notifiCations
	"notifiCationslong1":"Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2":"The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3":"Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4":"Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5":"VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6":"VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7":"VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8":"VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9":"VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10":"VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11":"VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12":"VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13":"VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn":"Refer and Earn",
	"Copy Link":"Copy Link",

	//faq
	"Frequently Asked Questions":"Frequently Asked Questions",

	//authenticator
	"mailbox":"mailbox",
	"Front photo":"Front photo",
	"Back photo":"Back photo",
	"Handheld ID photo":"Handheld ID photo",
	"define":"define",

	//legality
	"legalitylong1":"Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2":"Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3":"1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4":"2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5":"3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6":"Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7":'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language":"language",
	"Confirm":"Confirm",

	"newtext1":"Sign out",
	"newtext2":"Cancel",
	"newtext3":"Are you sure you want to log out of this account?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"Login",
	"newtext9":"username",
	"newtext10":"Mobile phone number",
	"newtext11":"Please enter user name",
	"newtext12":"Please enter your mobile phone number",
	"newtext13":"password",
	"newtext14":"Please enter password",
	"newtext15":"No account yet",
	"newtext16":"Register",
	"newtext17":"Country",
	"newtext18":"Select a country",
	"newtext19":"Mobile phone number verification code",
	"newtext20":"Please enter the verification code",
	"newtext21":"Email address",
	"newtext22":"Please enter your email address",
	"newtext23":"Confirm password",
	"newtext24":"Please enter the confirmation password",
	"newtext25":"Have an account",
	"newtext26":"Send verification code",
	"newtext27":"Mobile phone number is incorrect",
	"newtext28":"Sent successfully",
	"newtext29":"Registration successful",

	"newtext30":'currency',
	"newtext31":'Forex',
	"newtext32":'precious metals',

	"newtext33":'Compte',
	"newtext34":'Activités de la plateforme',
	"newtext35":'Service client',
	"newtext36":'invitation',
	"newtext37":'Vérification KYC',
	"newtext38":'paramètre',
	wallet_p:{
		text1: "Copier l'adresse",
		text2: 'Confirmer',
		text3: 'Échanger de',
		text4: "Échanger pour",
		text5: "MAX",
		text6: "Inviter",
		text7: 'Obtenir des récompenses par parrainage',
		text8: "Lorsque vos amis participent à l'arbitrage IA et louent des machines minières, vous pouvez obtenir récompenses par parrainage",
		text9: "copier",
		text10: 'Partager le lien',
		text11: "Vérification KYC",
		text12: "Pays",
		text13: "Veuillez sélectionner un pays",
		text14: "Prénom",
		text15: "Veuillez entrer votre prénom",
		text16: "Nom",
		text17: "Veuillez entrer votre nom",
		text18: "Type de certificat",
		text19: "Veuillez sélectionner le type de certificat",
		text20: "Numéro de certificat",
		text21: "Veuillez entrer le numéro de certificat",
		text22: "Veuillez télécharger une photo de la face avant de votre carte d'identité",
		text23: "Veuillez télécharger une photo de la face arrière de votre carte d'identité",
		text24: "Veuillez télécharger une photo de la face avant de votre passeport",
		text25: "Veuillez télécharger une photo de la face arrière de votre passeport",
		text26: "E-mail",
		text27: "Veuillez entrer votre e-mail",
		text28: "envoyer",
		text29: "Code de vérification",
		text30: "Veuillez entrer votre code de vérification",
		text31: "Carte d'identité",
		text32: "passeport",
		text33: "annuler",
		text34: "Pays/Région",
		text35: "Veuillez entrer les informations correctes pour soumettre à l'authentification",
		text36: "Envoyé avec succès!",
		text37: "Erreur de format de l'e-mail!",
		text38: "le permis de conduire",
		text39: "Veuillez télécharger une photo du recto de votre permis de conduire",
		text40: "Veuillez télécharger une photo du verso de votre permis de conduire",
		
		text41: "Numéro d'identification",
		text42: "Veuillez entrer votre numéro d'identification",
		text43: "Numéro de passeport",
		text44: "Veuillez entrer le numéro de passeport",
		text45: "numéro de permis de conduire",
		text46: "Veuillez entrer votre numéro de permis de conduire",
	},
	jiaoyi:{
		text1 : 'Deuxième contrat',
		text2 : 'Contrat perpétuel',
		text3:'Transaction au comptant',
		text4:'Déléguez immédiatement',
		text5:'Type de compte',
		text6:'délai de livraison',
		text7:'Gamme de prix',
		text8:'Prix d\'achat',
		text9 : 'coût',
		text10 : 'Équilibre',
		text11 : 'au moins',
		text12 : 'attendu',
		text13:'Montant de l\'achat',
		text14 : 'direction',
		text15:'Prix d\'achat',
		text16 : 'Prix en temps réel',
		text17 : 'Compte à rebours',
		text18 : 'Mon contrat',
		text19 : 'Bénéfice',
		text20 : 'Contrat',
		text21:'prix de livraison',
		text22:'délai de livraison',
		text23 : 'Prix du marché',
		text24 : 'en hausse',
		text25 : 'chute',
		text26 : 'Prix d\'achat',
		text27 : 'maximum',
		text28 : 'Bénéfice',
		text29 : 'frais de traitement',
		text30 : 'Acheter',
		text31 : 'Vendre',
		text32:'Quantité d\'achat',
		text33:'compte réel',
		text34 : 'Compte virtuel',
		text35:'Aucune donnée pour l\'instant',
		text36 : 'Position actuelle',
		text37:'Commission historique',
		text38:'Fermer la position',
		text39 : 'Marge',
		text40 : 'Profits et pertes',
		text41 : 'tenir',
		text42 : 'Dossier d\'achat',
		text43:'Vente de disque',
		text44 : 'Disponible',
		text45 : 'converti ',
		text46 : 'Authentification réussie ',
		text47:'Temps d\'achat',
		text48:'non lu',
		text49:'Lire',
		text50:'Ordre d\'hôte',
		text51 : 'Arbitrage total',
		text58 : 'Les gains d\'aujourd\'hui',
		text52:'Comment fonctionnent les robots à intelligence artificielle',
		text53:'produit d\'arbitrage',
		text54 : 'jour ',
		text55 : 'Montant',
		text56 : 'Revenu quotidien',
		text57:'Type de devise',
		text59 : 'Rejoignez AI arbitrage',
		text60:'Zéro risque, retours rapides',
		text61 : 'Arbitrage IA',
		text62 : ' Gains attendus ',
		text63:'Type de pièce d\'arbitrage',
		text64:'Montant du séquestre',
		text65 : 'Hébergez maintenant',
		text66 : 'Revenu quotidien envoyé sur votre portefeuille USDT',
		text67 : ' Fonds d\'investissement zéro risque ',
		text68:'Vous pouvez retirer vos fonds à tout moment',
		text69:'L\'intelligence artificielle fonctionne 24 heures sur 24',
		text70:'Revenu cumulé',
		text71:'Délai d\'expiration',
		text72 : ' tous ',
		text73 : 'En cours',
		text74 : 'Complet',
		text75:'Détails de l\'hébergement',
		text76:'Sélectionnez la devise',
		text77:'Assistance technique',
		text78:'Envoyer un e-mail',
		text79:'Type de transaction',
		
		text80 : 'Chargement...',
		text81 : 'Plus rien',
		text82 : 'Livre blanc',
	},

	swapadd : {
		text1:'Politique en matière de cookies',
		text2 : 'Nous nous engageons à protéger la sécurité et la confidentialité de vos données',
		texte3 : 'Introduction',
		text4:'Cette politique en matière de cookies explique comment notre échange décentralisé utilise des cookies et des technologies de suivi similaires. En accédant ou en utilisant Exchange, vous acceptez l\'utilisation de cookies comme décrit dans la présente politique. ',
		text5 : 'Que sont les cookies ? ',
		text6:'Les cookies sont de petits fichiers texte qui sont placés sur votre appareil (ordinateur, téléphone mobile ou tablette) lorsque vous visitez un site Internet. Ils sont largement utilisés pour améliorer votre expérience de navigation et pour fournir des fonctionnalités de personnalisation sur les sites Web. Les cookies peuvent être des cookies de session (qui sont temporaires et supprimés lorsque vous fermez votre navigateur) ou des cookies persistants (qui restent sur votre appareil pendant une période plus longue). ',
		text7:'Comment utilisons-nous les cookies',
		text8 : 'Les fins pour lesquelles les échanges utilisent des cookies incluent, sans s’y limiter : ',
		text9:'Activer les fonctions et fonctionnalités de base de l\'échange',
		text10:'Analyser et améliorer les performances et les fonctionnalités de l\'échange',
		text11:'Personnalisez votre expérience et mémorisez vos préférences',
		text12 : 'Suivez et comprenez vos habitudes d\'utilisation sur l\'échange',
		text13 : 'Fournir des communications publicitaires et marketing pertinentes ',
		text14:'Types de cookies que nous utilisons',
		text15:'a) Cookies essentiels : Ces cookies sont nécessaires au fonctionnement de la plateforme d\'échange et vous permettent d\'accéder et d\'utiliser ses fonctionnalités. Sans ces cookies, certains services pourraient ne pas être disponibles. ',
		text16:'b) Cookies d\'analyse : ces cookies collectent des informations sur la façon dont vous utilisez l\'échange, telles que les pages que vous visitez et les erreurs que vous rencontrez. Nous utilisons ces informations pour analyser et améliorer les performances et les fonctionnalités d\'Exchange. ',
		text17:'c) Cookies de fonctionnalité : ces cookies permettent à Exchange de mémoriser vos choix (tels que les préférences linguistiques) et de fournir des fonctionnalités améliorées et plus personnalisées. ',
		text18:'d) Cookies publicitaires : ces cookies sont utilisés pour fournir des publicités pertinentes pour vous et vos intérêts. Ils peuvent également être utilisés pour limiter le nombre de fois où vous voyez une publicité et pour mesurer l\'efficacité des campagnes publicitaires. ',
		text19 : 'Cookies tiers ',
		text20 : 'Les échanges peuvent permettre à des fournisseurs de services tiers de placer des cookies sur votre appareil à diverses fins, notamment l\'analyse et la publicité. Ces tiers ont leurs propres politiques de confidentialité et peuvent collecter des informations sur vos activités en ligne sur différents sites Web ou services en ligne. ',
		text21:'Gestion des Cookies',
		text22:'Vous pouvez gérer ou supprimer les Cookies à tout moment via les paramètres de votre navigateur. Veuillez toutefois noter que la désactivation ou la suppression de certains cookies peut affecter la fonctionnalité et les performances de l\'échange. ',
		text23 : 'D\'accord',
		text24 : 'En utilisant Exchange, vous acceptez l\'utilisation de cookies comme décrit dans cette politique. Si vous n\'acceptez pas l\'utilisation de cookies, vous pouvez les désactiver ou les supprimer via les paramètres de votre navigateur ou ne pas utiliser Exchange. ',
		text25:'Mettre à jour la politique',
		text26 : 'Nous pouvons périodiquement mettre à jour cette politique en matière de cookies pour refléter les changements dans nos pratiques ou les changements dans la loi applicable. Nous vous recommandons de consulter régulièrement cette politique pour obtenir les dernières informations. ',
		text27 : 'Si vous avez des questions ou des préoccupations concernant la façon dont nous utilisons les cookies, veuillez contacter notre équipe de support client. ',

		text28:'Obtenir un dépannage et de l\'aide',
		text29 : "Pour commencer ",
		text30:'Échange de jetons',
		text31 : "Dépannage ",
		text32 : ' Qu\'est-ce qu\'un échange décentralisé ? ',
		text33 : ' DEX est un échange de crypto-monnaie qui fonctionne sur un réseau blockchain décentralisé et est généralement construit sur des contrats intelligents. Contrairement aux échanges centralisés traditionnels, CEX s\'appuie sur une agence centrale pour faciliter les transactions et détenir les fonds des utilisateurs, DEX peut négocier directement avec les deux parties. contrats intelligents. ',
		text34:'Sécurité des échanges décentralisés',
		text35 : ' DEX fonctionne à l\'aide de contrats intelligents sur la blockchain, permettant des transactions directes entre acheteurs et vendeurs, éliminant ainsi le besoin d\'institutions tierces. DEX fonctionne sur une blockchain publique, les transactions sont transparentes et vérifiables, et tout le monde peut vérifier l\'équité et l\'intégrité du DEX. ',
		text36 : 'Qu\'est-ce qu\'un portefeuille décentralisé ? ',
		text37 : ' Un portefeuille décentralisé est un portefeuille de crypto-monnaie composé de 12 clés. Les utilisateurs peuvent utiliser 12 clés pour se connecter au portefeuille en utilisant la clé sur n\'importe quelle même blockchain pour accéder aux actifs numériques, tant que la clé n\'est pas divulguée, personne ne peut obtenir les actifs numériques dans le portefeuille décentralisé.',
		text38 : 'Qu\'est-ce qu\'une adresse de portefeuille ? ',
		text39 : 'L\'adresse du portefeuille de crypto-monnaie est une clé publique obtenue à partir d\'une clé privée via l\'algorithme de signature de courbe elliptique. La clé publique est une adresse de portefeuille obtenue grâce à une série de conversions. Une opération de hachage irréversible est utilisée pendant le processus de conversion. l’adresse est principalement utilisée pour l’envoi et la réception de crypto-monnaie. ',
		text40 : ' Sécurité de l\'utilisation de DEX pour échanger des fonds ',
		text41 : ' Le mécanisme de fonctionnement de DEX est construit sur la blockchain. Il ne passe par aucune institution tierce et adopte un système non dépositaire pour l\'échange direct. Toutes les transactions sont exécutées à l\'aide de contrats intelligents. blockchain, réalisant véritablement la réalisation des actifs. Transparence, confidentialité et sécurité. ',
		text42 : 'Pourquoi y a-t-il une différence de prix pour le même token sur la bourse ? ',
		text43 : ' Offre et demande du marché : la bourse propose l\'achat et la vente d\'actifs, mais en raison de la différence entre l\'offre et la demande du marché des principales bourses, les prix fixés par la bourse pour la vente et l\'achat changeront, et la demande pour certains jetons est plus élevé. Habituellement, le prix sera supérieur au prix du marché et le prix sera inférieur sur les bourses où la demande est plus faible. ',
		text44 : "Volume des transactions : la quantité de crypto-monnaie achetée et vendue sur une bourse, qui peut affecter le prix. Un volume de transactions plus élevé indique généralement une activité de marché accrue, ce qui peut favoriser une découverte plus précise des prix. Les bourses avec de faibles volumes de transactions peuvent avoir des écarts acheteur-vendeur plus larges et des prix plus imprécis. ",
		texte45 : ' Liquidité : fait référence à la facilité et à la difficulté d\'acheter ou de vendre un actif sans provoquer de changement de prix significatif. Les crypto-monnaies plus liquides ont tendance à avoir des prix plus cohérents d’une bourse à l’autre. Lorsque la liquidité d’une bourse est faible, cela peut entraîner des fluctuations de prix et d’importantes différences de prix. ',
		text46 : 'Sélection des jetons : tous les jetons n\'ont pas le même potentiel de profit, et différents jetons peuvent avoir différents degrés de liquidité, de volume de transactions et de volatilité des prix.',
		text47 : 'Qu\'est-ce qu\'un écart de taux de change ? ',
		text48 : ' Les différentiels de taux de change font référence aux variations des prix des cryptomonnaies entre différentes bourses. Étant donné que les crypto-monnaies sont dispersées dans le monde et négociées sur diverses plateformes, la dynamique de l’offre et de la demande, la liquidité, les volumes de transactions et les conditions du marché peuvent différer d’une bourse à l’autre. ',
		text49 : 'Qu\'est-ce que l\'échange DEX ? ',
		text50 : ' L\'échange DEX, également connu sous le nom d\'échange de jetons ou d\'échange d\'actifs, fait référence au processus d\'échange d\'une crypto-monnaie ou d\'un jeton contre une autre crypto-monnaie ou un autre jeton via un accord d\'échange sur DEX. Ces transactions sont généralement facilitées par des contrats intelligents sur la blockchain, éliminant ainsi le besoin d\'intermédiaires. ',
		text51 : ' Pourquoi les spreads ont-ils disparu sur les principales bourses ? ',
		text52 : ' Lorsqu\'il n\'y a pas de différence dans le prix des jetons sur les différentes bourses, cela signifie que le marché est relativement stable. La raison de cette situation est que les traders du marché négocient à plat et arbitrent automatiquement sur la base de transactions déséquilibrées, et les géants du marché y participent. transactions pour exploiter un grand nombre de financements pour l\'arbitrage commercial, maintenir les prix des jetons de marché à un niveau stable, le volume et la liquidité des transactions, un volume de transactions plus élevé et une liquidité suffisante, ainsi que la consolidation du marché sont autant de raisons qui font disparaître les spreads sur les bourses',
		text53 : 'Quelle est l\'adresse indiquée ? ',
		text54 : 'L\'adresse spécifiée est l\'adresse de réception de la crypto-monnaie que l\'utilisateur doit remplir une fois l\'échange réussi. Les utilisateurs qui effectuent l\'échange pour la première fois doivent lier l\'adresse de réception commune',
		text55 : 'Qu\'est-ce que le dérapage des prix ? ',
		text56 : ' Le glissement de prix, également connu sous le nom de glissement, est la différence entre le prix attendu d\'une transaction de cryptomonnaie et le prix auquel la transaction est réellement exécutée. Le dérapage des prix est généralement causé par la volatilité du marché, la liquidité, la taille des commandes, le type de commande, etc. Dans un marché volatil et en évolution rapide comme celui des cryptomonnaies, les prix peuvent changer rapidement. ',
		text57 : 'Qu\'est-ce que l\'échange GAS ? ',
		text58 : 'GAS est les frais GAS que les utilisateurs doivent payer à la blockchain lors du retrait de crypto-monnaie après un échange réussi. Les frais GAS sont utilisés pour traiter les commandes des utilisateurs et en même temps diffusés dans la blockchain pour confirmer le succès de la transaction de l\'utilisateur. . Habituellement, les frais GAS pour le retrait d\'actifs de change sont de 0,1 %. ',
		text59 : 'Pourquoi y a-t-il des commandes en attente ? ',
		text60 : ' Congestion du réseau : pendant les périodes de forte demande ou d\'activité accrue, les réseaux blockchain peuvent devenir encombrés. Cette congestion peut entraîner des délais de traitement des transactions plus lents et des frais plus élevés. Des fluctuations importantes du marché ou des augmentations de l\'activité commerciale peuvent provoquer une congestion du réseau. ',
		text61 : ' Problèmes d\'évolutivité : certains réseaux blockchain sont confrontés à des défis d\'évolutivité, ce qui signifie que la blockchain peut ne pas être en mesure de gérer de gros volumes de transactions rapidement et efficacement. Cela peut entraîner des retards et des vitesses de transaction plus lentes. ',
		text62 : ' Contraintes de liquidité : disponibilité des acheteurs et des vendeurs sur le marché. Sur certains marchés de crypto-monnaie ou dans certaines crypto-monnaies, la liquidité est limitée. Ce manque de liquidité peut entraîner un ralentissement des transactions. Il peut y avoir moins de participants achetant ou vendant à un prix spécifique, ce qui entraîne un élargissement de l\'écart acheteur-vendeur. ',
		text63 : ' Taille de l\'ordre au marché : la taille de l\'ordre affecte la vitesse de négociation, en particulier lorsqu\'il s\'agit d\'un ordre important par rapport à la liquidité disponible. Lorsque les ordres interagissent avec l\'offre ou la demande disponible sur le marché, le fait de passer un grand nombre d\'ordres d\'achat ou de vente peut entraîner des exécutions partielles ou une exécution plus lente, ce qui entraîne des ordres en attente. ',
		text64 : "Les actifs des commandes en attente sont-ils en sécurité ? ",
		text65 : ' Les fonds des ordres en attente ne seront pas perdus sur le marché de la cryptographie. Lorsqu\'un ordre de transaction est en attente, vous devez attendre que la blockchain corresponde à l\'ordre. Une fois l\'ordre correspondant, la transaction sera exécutée immédiatement. ',
		text66:'Qu\'est-ce que le traitement accéléré des commandes ? ',
		text67 : ' Étant donné que la relation entre l\'offre et la demande n\'existe plus lorsque le commerçant initie la commande, la commande correspondante ne peut pas être mise en correspondance dans la blockchain. Les utilisateurs peuvent choisir d\'augmenter les fonds de la transaction pour terminer la transaction de commande en attente avec la nouvelle relation entre l\'offre et la demande. ',
	},
	login: {
		Get_Started: 'Commencer',
		Best_Swap: 'La meilleure plateforme d\'échange au monde',
		Decentralized_cryptocurrency: 'Plateforme d\'échange de crypto-monnaie décentralisée, permettant un échange rapide et transparent entre différents actifs numériques !',
		Cookies_Privacy: 'Confidentialité des cookies',
		We_use_cookies: 'Nous utilisons des cookies pour améliorer votre expérience de navigation, analyser le trafic du site et fournir un contenu personnalisé. En continuant à utiliser ce site, vous acceptez notre utilisation des cookies !',
		Accept: 'Accepter',
		Sum: 'Confiance',
		Swap: 'Échanger',
		Balance: 'Équilibre',
		Name: 'Nom',
		Price_Change: 'Changement de prix',
		Sort_Type: 'Type de tri',
		Add_Asset: 'Ajouter un actif',
		Add_the_cryptocurrency: 'Ajoutez les actifs de crypto-monnaie dont vous avez besoin',
		Current_Market_Conditions: 'Conditions actuelles du marché',
		Market: 'Marché',
		Overview: 'Aperçu',
		Coin_Information: 'Informations sur la pièce',
		Market_Value: 'Valeur marchande',
		Total_Supply: 'Offre totale',
		In_Circulation: 'En circulation',
		Trading_Volume: 'Volume des échanges',
		Official_Website: 'Site officiel',
	},
	swap: {
		title1: "Enregistrements de Transaction",
		title2: "Voir vos enregistrements d'opérations historiques",
		title3: "Transfert",
		title4: "Approuver",
		title5: "Toutes les Monnaies",
		title6: "Oups!",
		title7: "Vous n'avez pas d'enregistrements.",
		title8: "La liste est vide.",
		title9: 'Sélectionner la Monnaie',
		title10: "Sélectionnez rapidement le type de crypto-monnaie",
		title11: "Tout",
		title12: "Toutes les Monnaies",
	},
	policy: {
		text1: "Politique AML",
		text2: "Nous nous engageons à protéger votre sécurité et confidentialité des données.",
		text3: "Notre bourse décentralisée adhère strictement aux lois, règlements et directives applicables en matière de lutte contre le blanchiment d'argent (AML) et le financement du terrorisme (CFT). Nous nous engageons à prendre toutes les mesures raisonnables pour empêcher l'utilisation de la Bourse pour le blanchiment d'argent, le financement du terrorisme ou toute autre activité illégale.",
		text4: "Vérification de l'Identité du Client",
		text5: "Pour assurer la sécurité et la conformité de la Bourse, nous exigeons que tous les utilisateurs passent par une vérification d'identité. Les utilisateurs doivent fournir des informations d'identification personnelles précises, complètes et à jour et soumettre des documents justificatifs. Nous nous réservons le droit de demander des informations ou des documents supplémentaires si nécessaire.",
		text6: "Surveillance des Activités de Transaction",
		text7: "La Bourse surveillera et analysera les activités de transaction des utilisateurs afin d'identifier toute activité suspecte ou inhabituelle. Si nous détectons des indices de blanchiment d'argent, de financement du terrorisme ou d'autres comportements illégaux, nous prendrons des mesures appropriées et pourrons signaler aux autorités compétentes.",
		text8: "Limites de Transaction et Exigences de Rapport",
		text9: "Pour se conformer aux exigences des lois sur la lutte contre le blanchiment d'argent et le financement du terrorisme, nous pouvons fixer des limites de transaction pour les utilisateurs et exiger des informations ou des rapports supplémentaires pour certains types ou montants de transactions. Les utilisateurs doivent coopérer et fournir rapidement les informations requises.",
		text10: "Collaboration et Signalement",
		text11: "Si la Bourse identifie une activité suspecte ou a des raisons de croire qu'un utilisateur est impliqué dans le blanchiment d'argent, le financement du terrorisme ou toute autre activité illégale, nous collaborerons activement avec les agences d'application de la loi et les autorités compétentes et signalerons comme requis par les lois applicables.",
		text12: "Formation des Employés et Contrôles de Conformité",
		text13: "Nous fournissons une formation régulière à nos employés sur la lutte contre le blanchiment d'argent et le financement du terrorisme pour nous assurer qu'ils sont conscients des lois, règlements et contrôles internes de conformité pertinents. Nous établissons et maintenons également des procédures et des contrôles internes pour garantir que nos opérations se conforment aux exigences de lutte contre le blanchiment d'argent et le financement du terrorisme.",
		text14: "Responsabilités de Conformité des Utilisateurs",
		text15: "En tant qu'utilisateur, vous êtes responsable de respecter les lois et règlements applicables en matière de lutte contre le blanchiment d'argent et le financement du terrorisme. Vous devez vous assurer que vos fonds proviennent de sources légales et éviter toute activité de blanchiment d'argent, de financement du terrorisme ou d'autres activités illégales. Si vous détectez une activité suspecte ou avez des préoccupations liées au blanchiment d'argent, vous devriez immédiatement la signaler à la Bourse.",
		text16: "Veuillez noter que la Bourse se réserve le droit de modifier ou de mettre à jour la politique de lutte contre le blanchiment d'argent à tout moment conformément aux lois et règlements applicables.",
		text17: "Si vous avez des questions ou nécessitez des clarifications supplémentaires, veuillez contacter notre équipe d'assistance clientèle.",
	},
	Service: {
		text1: "Conditions Générales de Service",
		text2: "Nous nous engageons à protéger votre sécurité et confidentialité des données.",
		text3: "Avant d'utiliser la Bourse, veuillez lire attentivement et comprendre les Conditions Générales de Service suivantes. En accédant ou en utilisant la Bourse, vous reconnaissez avoir lu, compris et accepté de vous conformer à ces conditions. Si vous n'êtes pas d'accord avec une partie quelconque de ces conditions, veuillez ne pas utiliser la Bourse.",
		text4: "Acceptation des Conditions",
		text5: "En utilisant la Bourse, vous reconnaissez et acceptez que ces Conditions Générales de Service constituent un accord juridique entre vous et la Bourse et régissent vos actions. Si vous n'êtes pas d'accord avec ces conditions, veuillez ne pas utiliser la Bourse.",
		text6: "Description du Service",
		text7: "La Bourse est une plateforme décentralisée conçue pour fournir des services de négociation et d'échange d'actifs numériques. La Bourse agit uniquement en tant que lieu de transaction et n'intervient pas directement dans les transactions entre utilisateurs. La Bourse ne fournit pas de conseils juridiques, financiers ou d'investissement et n'est pas responsable de vos activités de négociation.",
		text8: "Éligibilité de l'Utilisateur",
		text9: "Vous devez avoir au moins 18 ans (ou l'âge légal de la majorité) et avoir une capacité juridique complète pour utiliser la Bourse. Si vous utilisez la Bourse au nom d'une entité ou d'une organisation, vous déclarez et garantissez avoir l'autorité d'accepter ces conditions au nom de cette entité ou organisation.",
		text10: "Inscription et Sécurité du Compte",
		text11: "Vous devez vous inscrire pour utiliser la Bourse. Vous devez fournir des informations personnelles précises, complètes et à jour pour l'inscription et être responsable de la protection de la sécurité de vos informations de compte. Vous êtes seul responsable de toutes les activités menées via votre compte.",
		text12: "Règles d'Utilisation",
		text13: "En utilisant la Bourse, vous acceptez de vous conformer à toutes les lois, règlements et règles applicables et de respecter les lignes directrices suivantes :",
		text14: "(a) Ne pas violer aucune loi, règle ou réglementation applicable.",
		text15: "(b) Ne pas s'engager dans des activités illégales, frauduleuses, trompeuses ou diffamatoires.",
		text16: "(c) Ne pas interférer ou perturber le fonctionnement normal de la Bourse.",
		text17: "(d) Ne pas porter atteinte aux droits ou intérêts d'un tiers.",
		text18: "(e) Ne pas manipuler le marché ou abuser des services de la Bourse.",
		text19: "(f) Ne pas utiliser des outils automatisés, des scripts ou d'autres moyens pour interférer avec le fonctionnement normal de la Bourse.",
		text20: "(g) Ne pas propager de spams, de courriels indésirables ou de messages non sollicités.",
		text21: "Divulgation des Risques",
		text22: "La négociation d'actifs numériques comporte des risques, y compris mais sans s'y limiter, les risques de marché, les risques techniques et les risques de sécurité. Vous devez soigneusement évaluer votre tolérance au risque et décider si vous souhaitez participer à la négociation. La Bourse n'est pas responsable des pertes d'investissement que vous pourriez subir.",
		text23: "Frais et Taxes",
		text24: "La Bourse peut imposer des frais et taxes liés à votre utilisation des services de la Bourse. Vous acceptez de payer les frais et taxes applicables et comprenez que ces frais et taxes peuvent être modifiés à tout moment.",
		text25: "Politique de Confidentialité",
		text26: "La Bourse protégera vos informations personnelles conformément aux lois sur la confidentialité applicables. Veuillez consulter notre Politique de Confidentialité pour comprendre comment nous recueillons, utilisons et protégeons vos informations personnelles.",
		text27: "Propriété Intellectuelle",
		text28: "La Bourse et son contenu associé, y compris mais sans s'y limiter, les marques, logos, graphiques, textes et logiciels, sont la propriété de la Bourse et sont protégés par les lois sur la propriété intellectuelle applicables. Sans l'autorisation écrite de la Bourse, vous ne pouvez pas utiliser, copier, modifier, distribuer ou afficher tout contenu de la Bourse.",
		text29: "Exonération de Responsabilité",
		text30: "La Bourse n'est pas responsable de toutes pertes directes ou indirectes subies en conséquence de l'utilisation de la Bourse, y compris mais sans s'y limiter, les pertes de négociation, la perte de données, l'interruption des affaires ou d'autres pertes économiques.",
		text31: "Termination",
		text32: "La Bourse se réserve le droit de terminer, suspendre ou restreindre votre accès à la Bourse à tout moment. La Bourse se réserve également le droit de modifier ou de mettre à jour ces Conditions Générales de Service à tout moment. Vous devriez consulter ces Conditions Générales de Service périodiquement pour obtenir les informations les plus récentes.",
		text33: "Divers",
		text34: "Ces Conditions Générales de Service constituent l'accord intégral entre vous et la Bourse concernant la Bourse et remplacent tous les accords antérieurs verbaux ou écrits entre vous et la Bourse. Si une disposition de ces Conditions Générales de Service est jugée invalide ou non applicable, les dispositions restantes demeureront en vigueur.",
		text35: "Si vous avez des questions ou des préoccupations, veuillez contacter notre équipe d'assistance clientèle."
	},
	swap_change: {
		text1: "Echanger",
		text2: "Réaliserez un échange rapide et fluide de divers actifs.",
		text3: "Vous Payez",
		text4: "Solde",
		text5: 'Vue d\'ensemble',
		text6: "Impact du Spread Taux des Frais Réseau",
		text7: "Confirmer la Transaction",
		text8: "Offre et Demande du Marché",
		text9: 'Vous Recevez',
		text10: "effectuez le transfert d'actifs numériques rapidement.",
		text11: "Vous Envoyez",
		text12: "Entrez l'adresse de réception...",
		text13: "Entrez le montant à envoyer...",
		text15: "Envoyer",
		text14: 'Avant d\'envoyer, veuillez confirmer que l\'adresse de réception est correcte. Saisir une mauvaise adresse peut entraîner une perte permanente.',
		text16:'Sélectionnez la devise',
		text17:'La même devise ne peut pas être échangée',
	}
	}
