export default {

	account: {
		Start_making_money_plan: "Start making money plan",
		Market: "Market",
		Migital_currency: "Migital currency",
		Foreign_exchange: "Foreign exchange",
		Noble_metal: "Noble metal",
		Send_Cryop_Now: "Send Cryop Now",
		Send: "Send",
		Receive: "Receive",
		Buy: "Buy",
		Currency_account: "Currency account",
		Contract_account: "Contract account",
		Transfer: "Transfer",
		Select_a_wallet: "Select a wallet",
		Select_Coin: "Select Coin",
		Confirm: "Confirm",
		Wallet: "Wallet",
		Availavle: "Availavle",
		Frozen: "Frozen",
		Convert: "Convert",
		Receiving_Address: "Receiving Address",
		Amount: "Amount",
		Max: "Max",
		Send_Now: "Send Now",
		Please_check_if: "Please check whether your delivery address is correct before sending to avoid asset loss.",
		new1:'cryptocurrency',
		new2:'Index',
		new3:'Bulk',
		new4:'Forex',
		new5:'Total assets',
	},

	"314962-0": "Google 2FA",
	"314962-1": "Download google Authenticator to bind",
	"314962-2": "Copy code",
	"314962-3": "Enter verification code",
	"314962-4": "Request failed:",
	"314962-5": "Verification code copied",
	"314962-6": "Unable to copy text:",
	"314962-7": "Copy failed, please try again",
	"199917-0": "Settings",
	"199917-1": "Notifications",
	"199917-2": "Google 2FA",
	"199917-3": "Email",
	"199917-4": "Language",
	"199917-5": "Version",
	"199917-6": "Logout",
	"516529-0": "Account Settings",
  "516529-1": "Understand Your Account Information",
  "516529-2": "Online Support",
  "516529-3": "Share the Application",
  "516529-4": "Basic Settings",
  "516529-5": "Email Settings",
  "516529-6": "Change Language",
  "516529-7": "Price Alert",
  "516529-8": "Legal & Information",
  "516529-9": "Terms of Service",
  "516529-10": "Anti-Money Laundering Policy",
  "516529-11": "Cookie Policy",
  "516529-12": "Get Answers and Help",
  "516529-13": "Getting Started",
  "516529-14": "Exchange Tokens",
  "516529-15": "Troubleshooting",
  "516529-16": "Refer to Friends",
  "516529-17": "Share with Friends and Get Referral Commissions",
  "516529-18": "Copy Your Link",
  "516529-19": "Set Up Your Email to Receive Market Updates",
  "516529-20": "Verify Now",
  "516529-21": "Enter Email...",
  "516529-22": "Enter Verification Code...",
  "516529-23": "Your Subscription Email Will Only Be Used for Useful Information or Reminders Related to Our Services, and Will Not Be Abused!",
  "516529-24": "Save",
  "516529-25": "Select Your Website Language",
  "516529-26": "We Are Committed to Protecting Your Data Security and Privacy",
  "516529-27": "Traditional Chinese",
  "516529-28": "Japanese",
  "516529-29": "Send Verification Code",
  "516529-30": "Copied Successfully!",
  "516529-31": "Resend",
    home:{
        USDTAccount: 'USDT Account',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: 'Open',
        ETHTodayProfit: 'ETH Today\'s Profit',
        totalRevenue: 'Total Revenue',
        portfolio: 'Portfolio',
        reliableSecurity: 'Reliable Security Guarantee',
        stableReliable: 'Stable and Reliable Investment',
        convenienEasy: 'Convenien and Easy Operation',
        pleaseLookForward: 'Please look forward to...',
        rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
    },
	"ConnectW":"Connect Wallet",
	"dappbrowser":"This website only accepts access from decentralized wallet DAPP browsers.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "Earn Interest",
    "$": "$",
    "ETH Today's Profit": "ETH Today's Profit",
    "Wallet": "Wallet",
    "Support": "Support",
    "General": "General",
    "Notifications": "Notifications",
    "Invite Friends": "Invite Friends",
    "FAQ": "FAQ",
    "Privacy & Security": "Privacy & Security",
    "Legality": "Legality",
	"Authenticator":"Authenticator",
	"Settings":"Settings",
	"Change Language":"Change Language",
	"Daytime mode":"Daytime mode",
	"Black mode":"Black mode",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',





    //钱包
    TransactiónDetails:'Transactión Details',
	dealdetails:'deal details',
    ViewTransactionHistory:'View Transaction History',
    Deposit:'Deposit',
    Rechargeaddress:'Recharge address：',
    DepositAmount:'Deposit Amount：',
    EnterDepositAmount:'Enter Deposit Amount',
    EnterHash:'Enter Hash',
    Hash:'Hash：',
    Voucherimage:'Voucher image：',
    Continue:'Continue',
    Withdraw:'Withdraw',
    Withdrawal:'Withdrawal：',
    EnterAmounttoWithdraw:'Enter Amount to Withdraw',
    Available:"Available：",
    ReceiveAddress:'Receive Address：',
    EnterWalletAddress:'Enter Wallet Address',
    WithdrawalDesc:'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
    Exchange:'Exchange',
    Send:'Send:',
    Max:'Max:',
    rechargeAmount:'Please input a recharge amount',
    hashcode:'hash code and screenshot cannot be empty at the same time',
    correcthashcode:'please input a correct hash code',
    maximumwithdrawalamount:'The maximum withdrawal amount is',
    Incorrectamountformat:'Incorrect amount format',
	copy:'copy',


	//tab2
	"Total Profit":"Total Profit",
	"Today's Profit":"Today's Profit",
	"What is Automated Trading?":"What is Automated Trading?",
	"Learn about earning":"Learn about earning",
	"Staking Period":"Staking Period",
	"Staking Amount":"Staking Amount",
	"Yield":"Yield",
	"Limit":"Limit",
	"Subscribe":"Subscribe",
	"Record":"Record",

	//tab3
	"Proof of Stake":"Proof of Stake",
	"ETH Today is Price":"ETH Today is Price",
	"ETH Today's Increase":"ETH Today's Increase",
	"Joint Staking":"Joint Staking",
	"Individual Staking":"Individual Staking",
	"tab3long1":"Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2":"Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1":"Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2":"By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3":"Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4":"Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction":"Introduction",
	"webviewlong5":"Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6":'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process":"The Staking Process",
	"webviewlong7":"To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks":"Staking Rewards and Risks",
	"webviewlong8":"By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking":"Joint Staking and Individual Staking",
	"webviewlong9":"For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion":"Conclusion",
	"webviewlong10":"Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total":"Total",

	//record
	"Account":"Account",
	"interest-bearing":"interest-bearing",
	"POS Staking":"POS Staking",
	"convert":"convert",
	"transaction":"transaction",
	"pledge":"pledge",
	"recordno":"You don't have any news yet. The list is empty.",
	"Oops":"Oops",
	"recharge":"recharge",
	"payment":"payment",
	"staking":"staking",
	"income":"income",
	"Pledge quantity":"Pledge quantity",
	"cycle":"cycle",
	"Days Remaining":"Days Remaining",
	"cumulative gain":"cumulative gain",
	"Price increase":"Price increase",
	"Price decrease":"Price decrease",
	"Opening price":"Opening price",
	"Closing price":"Closing price",
	"loss":"loss",
	"pfofit":"pfofit",
	"amount":"amount",

	//notifiCations
	"notifiCationslong1":"Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2":"The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3":"Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4":"Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5":"VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6":"VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7":"VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8":"VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9":"VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10":"VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11":"VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12":"VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13":"VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn":"Refer and Earn",
	"Copy Link":"Copy Link",

	//faq
	"Frequently Asked Questions":"Frequently Asked Questions",

	//authenticator
	"mailbox":"mailbox",
	"Front photo":"Front photo",
	"Back photo":"Back photo",
	"Handheld ID photo":"Handheld ID photo",
	"define":"define",

	//legality
	"legalitylong1":"Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2":"Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3":"1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4":"2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5":"3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6":"Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7":'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language":"language",
	"Confirm":"Confirm",

	"newtext1":"Sign out",
	"newtext2":"Cancel",
	"newtext3":"Are you sure you want to log out of this account?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"Login",
	"newtext9":"username",
	"newtext10":"Mobile phone number",
	"newtext11":"Please enter user name",
	"newtext12":"Please enter your mobile phone number",
	"newtext13":"password",
	"newtext14":"Please enter password",
	"newtext15":"No account yet",
	"newtext16":"Register",
	"newtext17":"Country",
	"newtext18":"Select a country",
	"newtext19":"Mobile phone number verification code",
	"newtext20":"Please enter the verification code",
	"newtext21":"Email address",
	"newtext22":"Please enter your email address",
	"newtext23":"Confirm password",
	"newtext24":"Please enter the confirmation password",
	"newtext25":"Have an account",
	"newtext26":"Send verification code",
	"newtext27":"Mobile phone number is incorrect",
	"newtext28":"Sent successfully",
	"newtext29":"Registration successful",

	"newtext30":'currency',
	"newtext31":'Forex',
	"newtext32":'precious metals',

	"newtext33":'Account',
	"newtext34":'Platform Activities',
	"newtext35":'Online Service',
	"newtext36":'Invite',
	"newtext37":'KYC Verification',
	"newtext38":'Setting',
	wallet_p:{
		text1: "Copy Address",
		text2: 'Confirm',
		text3: 'Swap from',
		text4: "Swap to",
		text5: "MAX",
		text6: "Invite",
		text7: 'Get referral rewards',
		text8: "When your friends participate in AI arbitrage and rent mining machines,you can get referral rewards",
		text9: "copy",
		text10: 'Share Link',
		text11: "KYC Verification",
		text12: "Country",
		text13: "Please select a country",
		text14: "First name",
		text15: "Please enter your first name",
		text16: "Last name",
		text17: "Please enter your last name",
		text18: "Certificate Type",
		text19: "Please select Certificate Type",
		text20: "Ceritificate number",
		text21: "Please enter then ceritificate number",
		text22: "Please upload a photo of the front of your ID card",
		text23: "Please upload a photo of the back of your ID card",
		text24: "Please upload a photo of the front of your passport",
		text25: "Please upload a photo of the back of your passport",
		text26: "Email",
		text27: "Please enter your email",
		text28: "send",
		text29: "Vertify code",
		text30: "Please enter your vertify code",
		text31: "ID",
		text32: "passport",
		text33: "cancel",
		text34: "Country/Region",
		text35: "Please enter the correct information to submit for authentication",
		text36: "Successfully sent!",
		text37: "Email format error!",
		text38: "Driver's license",
		text39: "Please upload a photo of the front of your driver's license",
		text40: "Please upload a photo of the reverse side of your driver's license",
		
		text41: "ID number",
		text42: "Please enter your ID number",
		text43: "Passport number",
		text44: "Please enter passport number",
		text45: "driver's license number",
		text46: "Please enter your driver's license number",
	},
	jiaoyi:{
		text1:'Second',
		text2:'Perpetual',
		text3:'Spot',
		text4:'Entrust Now',
		text5:'Account Type',
		text6:'Staking Amount',
		text7:'Price Range',
		text8:'Purchase Price',
		text9:'Free',
		text10:'Balance',
		text11:'At Least',
		text12:'Expected',
		text13:'Purchase Amount',
		text14:'Direction',
		text15:'Purchase Price',
		text16:'Live price',
		text17:'Delivery Time',
		text18:'My Contract',
		text19:'Profit',
		text20:'Contract',
		text21:'Delivery Price',
		text22:'Delivery Time',
		text23:'Market price',
		text24:'Up',
		text25:'Down',
		text26:'Purchase Price',
		text27:'MAX',
		text28:'Margin',
		text29:'Handling fees',
		text30:'Buy',
		text31:'Sell',
		text32:'Purchase quantity',
		text33:'Real Account',
		text34:'Virtual Users',
		text35:'No data yet',
		text36:'Current position',
		text37:'Historical commission',
		text38:'Closing a Position',
		text39:'Margin',
		text40:'Profit and Loss',
		text41:'hold',
		text42:'Sell ​​record',
		text43:'Selling record',
		text44:'Available',
		text45:'Convert',
		text46:'Authentication successful',
		text47:'Purchase time',
		text48:'Unread',
		text49:'Read',
		text50:'Host order',
		text51:'Total arbitrage',
		text58:'Today\'s earnings',
		text52:'How artificial intelligence robots work',
		text53:'arbitrage product',
		text54:'day',
		text55:'Amount',
		text56:'Daily income',
		text57:'Currency type',
		text59:'Join AI arbitrage',
		text60:'Zero risk, quick returns',
		text61:'AI arbitrage',
		text62:'Expected earnings',
		text63:'Arbitrage coin type',
		text64:'Escrow amount',
		text65:'Host now',
		text66:'Daily income sent to your USDT wallet',
		text67:'Investment Fund Zero Risk',
		text68:'You can withdraw your funds at any time',
		text69:'Artificial intelligence works 24 hours a day',
		text70:'Cumulative income',
		text71:'Expiration time',
		text72:'all',
		text73:'In progress',
		text74:'Complete',
		text75:'Hosting details',
		text76:'Currency',
		text77:'Technical support',
		text78:'Send email',
		text79:'Transaction Type',
		
		text80:'Loading...',
		text81:'No more',
		text82:'White Paper',
	},

	swapadd:{
		text1:'Cookies Policy',
		text2:'We are committed to protecting your data security and privacy',
		text3:'Introduction',
		text4:'This Cookies Policy explains how our decentralized exchange uses cookies and similar tracking technologies. By accessing or using the Exchange, you agree to the use of Cookies as described in this Policy. ',
		text5:'What are Cookies? ',
		text6:'Cookies are small text files that are placed on your device (computer, mobile phone or tablet) when you visit a website. They are widely used to enhance your browsing experience and to provide personalization features on websites. Cookies can be session cookies (which are temporary and are deleted when you close your browser) or persistent cookies (which remain on your device for a longer period of time). ',
		text7:'How we use Cookies',
		text8: 'The purposes for which exchanges use cookies include but are not limited to:',
		text9:'Enable basic functions and features of the exchange',
		text10:'Analyze and improve the performance and functionality of the exchange',
		text11:'Personalize your experience and remember your preferences',
		text12:'Track and understand your usage patterns on the exchange',
		text13:'Provide relevant advertising and marketing communications',
		text14:'Types of Cookies we use',
		text15:'a) Essential Cookies: These cookies are necessary for the operation of the exchange and enable you to access and use its features. Without these Cookies, some services may not be available to you. ',
		text16:'b) Analytics Cookies: These cookies collect information about how you use the exchange, such as the pages you visit and any errors you encounter. We use this information to analyze and improve the performance and functionality of the Exchange. ',
		text17:'c) Functionality Cookies: These cookies allow the Exchange to remember your choices (such as language preferences) and provide enhanced, more personalized functionality. ',
		text18:'d) Advertising Cookies: These cookies are used to provide advertising relevant to you and your interests. They may also be used to limit the number of times you see an ad and to measure the effectiveness of advertising campaigns. ',
		text19:'Third Party Cookies',
		text20:'Exchanges may allow third-party service providers to place Cookies on your device for a variety of purposes, including analytics and advertising. These third parties have their own privacy policies and may collect information about your online activities across different websites or online services. ',
		text21:'Cookies Management',
		text22:'You can manage or delete Cookies at any time through your browser settings. However, please note that disabling or deleting certain cookies may affect the functionality and performance of the exchange. ',
		text23:'Agree',
		text24:'By using the Exchange, you agree to the use of Cookies as described in this policy. If you do not agree to the use of Cookies, you can disable or delete them through your browser settings or not use the Exchange. ',
		text25:'Update policy',
		text26:'We may periodically update this Cookies Policy to reflect changes in our practices or changes in applicable law. We recommend that you review this policy regularly for the latest information. ',
		text27:'If you have any questions or concerns about the way we use Cookies, please contact our customer support team. ',

		text28:'Get troubleshooting and help',
		text29:'Getting Started',
		text30:'Exchange tokens',
		text31:'Troubleshooting',
		text32:'What is a decentralized exchange? ',
		text33: 'DEX is a cryptocurrency exchange that runs on a decentralized blockchain network and is usually built on smart contracts. Unlike traditional centralized exchanges, CEX relies on a central agency to facilitate transactions and hold User funds, DEX can directly trade with both parties through smart contracts. ',
		text34:'Security of decentralized exchanges',
		text35: 'DEX operates using smart contracts on the blockchain, allowing direct transactions between buyers and sellers, eliminating the need for third-party institutions. DEX runs on a public blockchain, transactions are transparent and auditable, and anyone can check to verify the fairness and integrity of the DEX. ',
		text36:'What is a decentralized wallet? ',
		text37: 'A decentralized wallet is a cryptocurrency wallet consisting of 12 keys. Users can use 12 keys to log in to the wallet using the key on any same blockchain to access digital assets. As long as the key is not leaked, no one can obtain the digital assets in the decentralized wallet',
		text38:'What is a wallet address? ',
		text39:'The cryptocurrency wallet address is a public key obtained from a private key through the elliptic curve signature algorithm. The public key is a wallet address obtained through a series of conversions. An irreversible hash operation is used during the conversion process. The wallet address is mainly used for sending and receiving. Cryptocurrency. ',
		text40:'Safety of using DEX to exchange funds',
		text41:'The operating mechanism of DEX is built on the blockchain. It does not go through any third-party institutions and adopts a non-custodial system for direct exchange. All transactions are run using smart contracts. All transactions can be queried on the blockchain, truly realizing the realization of assets. Transparency, privacy and security. ',
		text42: 'Why is there a price difference for the same token on the exchange? ',
		text43: 'Market supply and demand: The exchange provides the purchase and sale of assets, but due to the different market supply and demand of major exchanges, the prices set by the exchange for sale and purchase will change, and the demand for certain tokens is higher. Usually the price will be higher than the market price, and the price will be lower on exchanges with lower demand. ',
		text44: 'Trading volume: The amount of cryptocurrency bought and sold on an exchange, which can affect the price. Higher trading volume generally indicates more market activity, which can promote more accurate price discovery. Exchanges with low trading volumes may have wider bid-ask spreads and more inaccurate pricing. ',
		text45: 'Liquidity: refers to the ease and difficulty of buying or selling an asset without causing a significant price change. Cryptocurrencies with higher liquidity tend to have prices that are more consistent across exchanges. When liquidity on an exchange is low, it can lead to price fluctuations and large price differences. ',
		text46: 'Token Selection: Not all tokens have the same profit potential, and different tokens may have varying degrees of liquidity, trading volume, and price volatility.',
		text47:'What is an exchange rate spread? ',
		text48: 'Exchange rate differentials refer to changes in cryptocurrency prices between different exchanges. Because cryptocurrencies are dispersed globally and traded on a variety of platforms, supply and demand dynamics, liquidity, trading volumes and market conditions may differ between exchanges. ',
		text49:'What is DEX exchange? ',
		text50:'DEX exchange, also known as token exchange or asset exchange, refers to the process of exchanging one cryptocurrency or token for another cryptocurrency or token through an exchange agreement on DEX. These transactions are typically facilitated by smart contracts on the blockchain, eliminating the need for intermediaries. ',
		text51: 'Why have spreads disappeared on major exchanges? ',
		text52:'When there is no difference in the price of tokens on different exchanges, it means that the market is relatively stable. The reason for this situation is that market traders trade flat and automatically arbitrage based on unbalanced transactions, and market giants participate in transactions to exploit a large number of Funding for trading arbitrage, keeping market token prices flat, trading volume and liquidity, higher trading volume and sufficient liquidity, and market consolidation are all reasons that cause spreads to disappear on exchanges',
		text53:'What is the designated address? ',
		text54: 'The specified address is the cryptocurrency receiving address that the user needs to fill in after the exchange is successful. Users who make the exchange for the first time need to bind the common receiving address',
		text55:'What is price slippage? ',
		text56: 'Price slippage, also known as slippage, is the difference between the expected price of a cryptocurrency trade and the price at which the trade is actually executed. Price slippage is usually caused by market volatility, liquidity, order size, order type, etc. In a fast-moving and volatile market like cryptocurrency, prices can change rapidly. ',
		text57:'What is exchange GAS? ',
		text58: 'GAS is the GAS fee that users need to pay to the blockchain when withdrawing cryptocurrency after a successful exchange. The GAS fee is used to process user orders and at the same time broadcast in the blockchain to confirm the success of the user\'s transaction. Usually the GAS fee for withdrawing exchange assets is 0.1%. ',
		text59:'Why are there pending orders? ',
		text60: 'Network congestion: During periods of high demand or increased activity, blockchain networks can become congested. This congestion can lead to slower transaction processing times and higher fees. Large market fluctuations or surges in trading activity can cause network congestion. ',
		text61: 'Scalability issues: Some blockchain networks face scalability challenges, which means the blockchain may not be able to handle large volumes of transactions quickly and efficiently. This can lead to delays and slower transaction speeds. ',
		text62: 'Liquidity constraints: The availability of buyers and sellers in the market. In some cryptocurrency markets or specific cryptocurrencies, liquidity is limited. This lack of liquidity may cause transactions to slow down. There may be fewer participants buying or selling at a specific price, causing the bid-ask spread to widen. Order execution slows down. ',
		text63: 'Market order size: The size of the order affects the speed of trading, especially when it is a large order relative to the available liquidity. When orders interact with available supply or demand in the market, placing a large number of buy or sell orders may result in partial fills or slower execution, resulting in pending orders. ',
		text64:'Are the assets in pending orders safe? ',
		text65: 'Funds in pending orders will not be lost in the crypto market. When a transaction order is pending, you need to wait for the blockchain to match the order. Once the order is matched, the transaction will be executed immediately. ',
		text66:'What is accelerated order processing? ',
		text67: 'Since the supply and demand relationship no longer exists when the trader initiates the order, the corresponding order cannot be matched in the blockchain. Users can choose to increase transaction funds to complete the pending order transaction with the new supply and demand relationship. ',
	},
	"830753-0": "Recharge",
	"830753-1": "Quickly perform asset recharge",
	"830753-2": "Please upload the voucher",
	"830753-3": "Confirm recharge",
	"830753-4": "Server response data incorrect:",
	"830753-5": "Image upload failed:",
	login: {
		Get_Started: 'Get Started',
		Best_Swap: 'World\'s Best Swap Platform',
		Decentralized_cryptocurrency: 'Decentralized cryptocurrency exchange platform, enabling fast and seamless exchange between different digital assets!',
		Cookies_Privacy: 'Cookies Privacy',
		We_use_cookies: 'We use cookies to enhance your browsing experience, analyze website traffic, and provide personalized content. By continuing to use this website, you agree to our use of cookies!',
		Accept: 'Accept',
		Sum: 'Trust',
		Swap: 'Swap',
		Balance: 'Balance',
		Name: 'Name',
		Price_Change: 'Price Change',
		Sort_Type: 'Sort Type',
		Add_Asset: 'Add Asset',
		Add_the_cryptocurrency: 'Add the cryptocurrency assets you need',
		Current_Market_Conditions: 'Current Market Conditions',
		Market: 'Market',
		Overview: 'Overview',
		Coin_Information: 'Coin Information',
		Market_Value: 'Market Value',
		Total_Supply: 'Total Supply',
		In_Circulation: 'In Circulation',
		Trading_Volume: 'Trading Volume',
		Official_Website: 'Official Website',
	},
	swap: {
		title1: "Transaction Records",
		title2: "View your historical operation records",
		title3: "Transfer",
		title4: "Approve",
		title5: "All Coins",
		title6: "Oops!",
		title7: "You don't have records.",
		title8: "The list is empty.",
		title9: 'Select Coin',
		title10: "Quickly select the type of cryptocurrency",
		title11: "All",
		title12: "All Coins",
	},
	policy:{
		text1:"AML Policy",
		text2: "We are committed to protecting your data security and privacy.",
		text3: "Our decentralized exchange strictly adheres to applicable anti-money laundering (AML) and countering the financing of terrorism (CFT) laws, regulations, and guidelines. We are committed to taking all reasonable measures to prevent the Exchange from being used for money laundering, terrorist financing, or any other illegal activities.",
		text4: "Customer Identity Verification",
		text5: "To ensure the security and compliance of the Exchange, we require all users to undergo identity verification. Users must provide accurate, complete, and up-to-date personal identification information and submit supporting documents as proof. We reserve the right to request additional information or documents as needed.",
		text6: "Monitoring of Transaction Activities",
		text7: "The Exchange will monitor and analyze user transaction activities to identify any suspicious or unusual activities. If we detect any indications of money laundering, terrorist financing, or other illegal behavior, we will take appropriate action and may report to the relevant authorities.",
		text8: "Transaction Limits and Reporting Requirements",
		text9: "To comply with the requirements of anti-money laundering and countering the financing of terrorism laws, we may set transaction limits for users and require additional information or reporting for specific types or amounts of transactions. Users must cooperate and promptly provide the required information.",
		text10: "Collaboration and Reporting",
		text11:"If the Exchange identifies any suspicious activity or has reason to believe that a user is involved in money laundering, terrorist financing, or other illegal activities, we will actively cooperate with law enforcement agencies and relevant authorities, and report as required by applicable laws.",
		text12: "Employee Training and Compliance Controls",
		text13: "We provide regular training to our employees on anti-money laundering and countering the financing of terrorism to ensure they are aware of relevant laws, regulations, and internal compliance controls. We also establish and maintain internal procedures and controls to ensure our operations comply with applicable anti-money laundering and countering the financing of terrorism requirements.",
		text14: "User Compliance Responsibilities",
		text15: "As a user, you are responsible for complying with applicable anti-money laundering and countering the financing of terrorism laws and regulations. You must ensure that your funds come from legitimate sources and refrain from engaging in any money laundering, terrorist financing, or other illegal activities. If you detect any suspicious activity or have any concerns related to money laundering, you should immediately report it to the Exchange.",
		text16: "Please note that the Exchange reserves the right to modify or update the anti-money laundering policy at any time in accordance with applicable laws and regulations.",
		text17: "If you have any questions or require further clarification, please contact our customer support team."
	},
	Service: {
	 text1: "Terms of Service",
		text2:"We are committed to protecting your data security and privacy.",
		text3: "Before using the Exchange, please carefully read and understand the following Terms of Service. By accessing or using the Exchange, you acknowledge that you have read, understood, and agreed to comply with these terms. If you do not agree with any part of these terms, please refrain from using the Exchange.",
		test4: "Acceptance of Terms",
		text5: "By using the Exchange, you acknowledge and agree that these Terms of Service constitute a legal agreement between you and the Exchange, and govern your actions. If you do not agree to these terms, please do not use the Exchange.",
		text6: "Service Description",
		text7: "The Exchange is a decentralized platform designed to provide trading and exchange services for digital assets. The Exchange acts solely as a venue for transactions and does not directly participate in transactions between users. The Exchange does not provide legal, financial, or investment advice and is not responsible for your trading activities.",
		text8: "User Eligibility",
		text9: "You must be at least 18 years old (or the legal age of majority) and have full legal capacity to use the Exchange. If you are using the Exchange on behalf of an entity or organization, you represent and warrant that you have the authority to accept these terms on behalf of that entity or organization.",
		text10: "Registration and Account Security",
		text11: "You need to register an account to use the Exchange. You must provide accurate, complete, and up-to-date personal information for registration and are responsible for protecting the security of your account information. You are solely responsible for all activities conducted through your account.",
		text12: "Rules of Use",
		text13: "By using the Exchange, you agree to comply with all applicable laws, regulations, and rules, and adhere to the following guidelines:",
		text14: "(a) Do not violate any applicable laws, regulations, or rules.",
		text15: "(b) Do not engage in any illegal, fraudulent, deceptive, or defamatory activities.",
		text16: "(c) Do not interfere with or disrupt the normal operation of the Exchange.",
		text17: "(d) Do not infringe upon the rights or interests of any third party.",
		text18: "(e) Do not manipulate the market or abuse the services of the Exchange.",
		text19: "(f) Do not use any automated tools, scripts, or other means to interfere with the normal operation of the Exchange.",
		text20: "(g) Do not propagate any spam, junk mail, or unsolicited messages.",
		text21: "Risk Disclosure",
		text22: "Digital asset trading involves risks, including but not limited to market risks, technical risks, and security risks. You must carefully assess your own risk tolerance and decide whether to participate in trading. The Exchange is not liable for any investment losses incurred by you.",
		text23: "Fees and Charges",
		text24: "The Exchange may impose fees and charges related to your use of the Exchange services. You agree to pay the applicable fees and charges and understand that these fees and charges may be subject to change at any time.",
		text25: "Privacy Policy",
		text26: "The Exchange will protect your personal information in accordance with applicable privacy laws. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.",
		text27: "Intellectual Property",
		text28: "The Exchange and its related content, including but not limited to trademarks, logos, graphics, text, and software, are the property of the Exchange and are protected by applicable intellectual property laws. Without the written permission of the Exchange, you may not use, copy, modify, distribute, or display any content of the Exchange.",
		text29: "Disclaimer of Liability",
		text30: "The Exchange is not liable for any direct or indirect losses incurred as a result of using the Exchange, including but not limited to trading losses, data loss, business interruption, or other economic losses.",
		text31: "Termination",
		text32: "The Exchange reserves the right to terminate, suspend, or restrict your access to the Exchange at any time. The Exchange also reserves the right to modify or update these Terms of Service at any time. You should review these Terms of Service periodically for the latest information.",
		text33: "Miscellaneous",
		text34: "These Terms of Service constitute the entire agreement between you and the Exchange regarding the Exchange and supersede all prior oral or written agreements between you and the Exchange. If any provision of these Terms of Service is deemed invalid or unenforceable, the remaining provisions shall remain in effect.",
		text35: "If you have any questions or concerns, please contact our customer support team."
   },
	swap_change: {
		text1: "Swap",
		text2: "Achieve seamless exchange of various assets quickly.",
		text3: "You Pay",
		text4: "Balance",
		text5: 'Overview',
		text6: "Slippage impact Rate Network Fee",
		text7: "Confirm Transaction",
		text8: "Market Supply and Demand",
		text9: 'You Get',
		text10: "complete the transfer digital assets quickly.",
		text11: "You Send",
		text12: "Enter receiving address...",
		text13: "Enter send amount...",
		text15: "Send",
		text14: 'Before sending,please confirm that the receiving address is correct.inputting the wrong address may result in permanent loss.',
		text16:'Select currency',
		text17:'The same currency cannot be exchanged',
	}
}
