//越南语
export default {
	"830753-0": "Nạp lại",
	"830753-1": "Nhanh chóng nạp lại tài sản",
	"830753-2": "Vui lòng tải lên bằng chứng",
	"830753-3": "Xác nhận nạp lại",
	"830753-4": "Dữ liệu phản hồi từ máy chủ không đúng:",
	"830753-5": "Tải lên hình ảnh thất bại:",
	account: {
		Start_making_money_plan: "Bắt đầu lập kế hoạch kiếm tiền",
		Market: "Chợ",
		Migital_currency: "Tiền tệ tiền tệ",
		Foreign_exchange: "Ngoại hối",
		Noble_metal: "Kim loại quý",
		Send_Cryop_Now: "Gửi Cryop ngay",
		Send: "Gửi",
		Receive: "Nhận được",
		Buy: "Mua",
		Currency_account: "Tài khoản tiền tệ",
		Contract_account: "Tài khoản hợp đồng",
		Transfer: "Chuyển khoản",
		Select_a_wallet: "Chọn ví",
		Select_Coin: "Chọn đồng xu",
		Confirm: "Xác nhận",
		Wallet: "Cái ví",
		Availavle: "Có sẵn",
		Frozen: "Đông cứng",
		Convert: "Chuyển thành",
		Receiving_Address: "Địa chỉ nhận hàng",
		Amount: "Số lượng",
		Max: "Tối đa",
		Send_Now: "Gửi ngay bây giờ",
		Please_check_if: "Vui lòng kiểm tra xem địa chỉ giao hàng của bạn có chính xác hay không trước khi gửi để tránh mất tài sản.",
		new1:'tiền điện tử',
		new2:'chỉ số',
		new3:'số lượng lớn',
		new4:'Ngoại hối',
		new5:'tổng tài sản',
	},
	"314962-0": "Google 2FA",
  "314962-1": "Tải xuống Google Authenticator để liên kết",
  "314962-2": "Sao chép mã xác thực",
  "314962-3": "Nhập mã xác thực",
  "314962-4": "Yêu cầu thất bại:",
  "314962-5": "Mã xác thực đã được sao chép",
  "314962-6": "Không thể sao chép văn bản:",
  "314962-7": "Sao chép thất bại, vui lòng thử lại",
  "199917-0": "Cài đặt",
  "199917-1": "Thông báo",
  "199917-2": "Google 2FA",
  "199917-3": "Email",
  "199917-4": "Ngôn ngữ",
  "199917-5": "Phiên bản",
  "199917-6": "Đăng xuất",
    home:{
        USDTAccount: 'tài khoản USDT',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: 'Ủy quyền',
        ETHTodayProfit: 'Lợi nhuận ETH hôm nay',
        totalRevenue: 'Tổng doanh thu',
        portfolio: 'đầu tư',
        reliableSecurity: 'Đảm bảo an ninh đáng tin cậy',
        stableReliable: 'Đầu tư ổn định và đáng tin cậy',
        convenienEasy: 'Hoạt động thuận tiện và dễ dàng',
        pleaseLookForward: 'Xin vui lòng chờ đợi...',
        rateOfReturn: 'Tỷ lệ phản hồi',
		walletnot: 'Vui lòng cài đặt MetaMask hoặc bất kỳ ví mở rộng Ethereum nào',
		wallettip: 'Vui lòng liên kết địa chỉ của bạn để tránh ảnh hưởng đến trải nghiệm',
    },
	"ConnectW":"Kết nối ví",
	"dappbrowser":"Trang web này chỉ chấp nhận quyền truy cập từ trình duyệt DAPP ví phi tập trung.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "kiếm lãi",
    "$": "$",
    "ETH Today's Profit": "Lợi nhuận ETH hôm nay",
    "Wallet": "cái ví",
    "Record": "Đặt hàng",
    "Support": "dịch vụ khách hàng",
    "General": "toàn diện",
    "Notifications": "thông báo",
    "Invite Friends": "Mời bạn bè",
    "FAQ": "vấn đề thường gặp",
    "Privacy & Security": "Quyền riêng tư và bảo mật",
    "Legality": "Chính sách bảo mật",
	"Authenticator":"Đã xác minh",
	"Settings":"cài đặt",
	"Change Language":"thay đổi ngôn ngữ",
	"Daytime mode":"chế độ ngày",
	"Black mode":"Chế độ ban đêm",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'khai mạc',
    'High':'Cao nhất',
    'Low':'thấp nhất',
    'Close':'đóng cửa',
    'Up':'mua lên',
    'Down':'Mua hoặc bán',
    'Time':'thời gian',
    'TransactionFee':'phí xử lý',
    'AvailableBalance':'Số dư khả dụng',
    'submit':'đăng ký',
    'Direction':'phương hướng',
    'Profit':'lợi nhuận',
    'Quantity':'Số lượng',
    'Price':'giá ',
    'different':'Nhấp vào các thang đo khác nhau để hiển thị giới hạn tối thiểu',
    'lessthan':'Ít nhất không kém hơn',
    'least':'ít nhất：',
    'Transactionhistory':'Ghi lại giao dịch',
    'Transactionrecords':'Ghi lại giao dịch',
    'Number':'Số lượng',
    'Profitloss':'mất lợi nhuận',
	'Ror':'trở lại',
	'operate':'vận hành',





    //钱包
    TransactiónDetails:'Chi tiết giao dịch',
	dealdetails:'chi tiết giao dịch',
    ViewTransactionHistory:'Xem lịch sử giao dịch',
    Deposit:'nạp tiền',
    Rechargeaddress:'Địa chỉ nạp tiền：',
    DepositAmount:'Số tiền gửi：',
    EnterDepositAmount:'Nhập số tiền gửi',
    EnterHash:'Nhập giá trị băm',
    Hash:'Giá trị băm：',
    Voucherimage:'Hình ảnh thông tin xác thực：',
    Continue:'Tiếp tục',
    Withdraw:'rút',
    Withdrawal:'lấy lại：',
    EnterAmounttoWithdraw:'Nhập số tiền rút',
    Available:"có thể sử dụng được：",
    ReceiveAddress:'địa chỉ nhận：',
    EnterWalletAddress:'Nhập địa chỉ ví',
    WithdrawalDesc:'Rút tiền bằng tiếng Anh yêu cầu phí xử lý 1% và yêu cầu xác nhận từ nút mạng trước khi có thể ghi có. Vui lòng không chuyển tiền điện tử cho người lạ.',
    Exchange:'trao đổi',
    Send:'chuyển giao:',
    Max:'tối đa:',
    rechargeAmount:'Vui lòng nhập số tiền nạp',
    hashcode:'Giá trị băm và ảnh chụp màn hình không được để trống cùng lúc',
    correcthashcode:'Vui lòng nhập giá trị băm chính xác',
    maximumwithdrawalamount:'Số tiền rút tối đa là',
    Incorrectamountformat:'Định dạng số tiền không chính xác',
	copy:'sao chép',


	//tab2
	"Total Profit":"Tổng lợi nhuận",
	"Today's Profit":"Lợi nhuận hôm nay",
	"What is Automated Trading?":"Giao dịch tự động là gì？",
	"Learn about earning":"Tìm hiểu về thu nhập",
	"Staking Period":"Thời hạn cam kết",
	"Staking Amount":"Số lượng cam kết",
	"Yield":"lợi ích",
	"Limit":"giới hạn",
	"Subscribe":"đăng ký",

	//tab3
	"Proof of Stake":"Bằng chứng về cổ phần",
	"ETH Today is Price":"Giá ETH hôm nay",
	"ETH Today's Increase":"ETH tăng giá ngày hôm nay",
	"Joint Staking":"Cam kết chung",
	"Individual Staking":"Cam kết cá nhân",
	"tab3long1":"Những người tham gia được ghép nối thông qua hợp đồng thông minh để thực hiện đặt cược chung, với tổng số tiền đặt cược đạt 32 ETH.",
	"tab3long2":"Cá cược độc lập không yêu cầu hợp đồng thông minh để phù hợp với người tham gia.  Cược cá nhân có thể hoàn thành khi số tiền đặt cược đạt 32 ETH",


	//web_view
	"webviewlong1":"Các đợt airdrop tiền điện tử hỗ trợ sự phát triển của các dự án dựa trên blockchain mới nổi.  Nguồn gốc của họ kết nối các thành viên cộng đồng thông qua phần thưởng và mục tiêu chung.  Kết quả của đợt airdrop thành công là một cộng đồng mạnh mẽ hơn, các dự án tốt hơn và phần thưởng dành cho người dùng cuối.  Trong những năm qua, chúng tôi đã thấy nhiều đợt airdrop được thực hiện trên chuỗi khối Proof-of-Work (POW).  Chuỗi Ethereum bằng chứng cổ phần (POS) hiện hỗ trợ airdrop tiền điện tử cho hàng trăm dự án trên hàng chục mạng.  Khi PoS trở thành mô hình đồng thuận thống trị, số lượng airdrop trên mạng Ethereum không có dấu hiệu chậm lại.",
	"webviewlong2":"Bằng cách đặt cược tiền điện tử của mình, bạn có đủ điều kiện để mở khóa các phần thưởng airdrop có giá trị dựa trên số lượng tiền điện tử mà bạn đặt cược.",
	"webviewlong3":"Phần thưởng vốn chủ sở hữu chỉ dành cho những người nắm giữ cổ phần và đến từ hàng trăm đợt airdrop dự án khác nhau, với tỷ suất lợi nhuận cao hơn nhiều so với bất kỳ đợt airdrop độc lập nào.",
	"webviewlong4":"Phần thưởng airdrop thường gây khó khăn cho chủ sở hữu tiền điện tử gửi tiền trên các sàn giao dịch và một số người giám sát hoàn toàn không hỗ trợ airdrop.  Đây là lý do tại sao việc quản lý tiền điện tử của riêng bạn lại quan trọng.  Ethereum là một thực thể không giám sát và có thể được đặt cược trên tất cả các mạng lớn",
	"Introduction":"giới thiệu",
	"webviewlong5":"Ethereum, nền tảng blockchain hàng đầu, đang chuyển đổi từ cơ chế đồng thuận bằng chứng công việc (PoW) sang cơ chế bằng chứng cổ phần (PoS) thông qua nâng cấp Ethereum 2.0. Đặt cược PoS là một phần quan trọng của quá trình chuyển đổi này, mang đến cho người dùng cơ hội hỗ trợ hoạt động và bảo mật mạng đồng thời có khả năng kiếm được phần thưởng.  Bài viết này cung cấp cái nhìn tổng quan về đặt cược Ethereum PoS và lợi ích của nó.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"Ethereum 2.0 và Bằng chứng cổ phần (PoS)",
	"webviewlong6":'Ethereum 2.0 là một bản nâng cấp mạng lớn được thiết kế để cải thiện khả năng mở rộng, bảo mật và khả năng phục hồi.  Một trong những thay đổi quan trọng là việc chuyển từ cơ chế đồng thuận PoW tiêu tốn nhiều năng lượng sang cơ chế PoS thân thiện với môi trường hơn.  Trong PoS, người xác thực được chọn để tạo các khối mới và xác nhận giao dịch dựa trên số lượng tiền điện tử mà họ nắm giữ và sẵn sàng “đặt cọc” làm tài sản thế chấp.',
	"The Staking Process":"Quy trình cầm cố",
	"webviewlong7":"Để tham gia đặt cược Ethereum PoS, người dùng phải đặt cược ít nhất 32 ETH bằng cách gửi số tiền đó vào hợp đồng tiền gửi Ethereum 2.0.  Sau khi đặt cược, ETH sẽ bị khóa trong một khoảng thời gian và đóng vai trò là tài sản thế chấp để đảm bảo an ninh cho mạng.  Trình xác thực được chọn dựa trên số lượng ETH đặt cược và hoạt động trực tuyến của họ.  Họ chịu trách nhiệm đề xuất và xác thực các khối mới, cũng như xác nhận các giao dịch trên mạng.",
	"Staking Rewards and Risks":"Đặt cược phần thưởng và rủi ro",
	"webviewlong8":"Bằng cách tham gia đặt cược PoS, người dùng có thể kiếm được phần thưởng dưới dạng ETH mới được đúc và phí giao dịch.  Phần thưởng dựa trên số lượng ETH đặt cược và hoạt động tổng thể của mạng.  Tuy nhiên, đặt cược đi kèm với rủi ro, chẳng hạn như có thể bị phạt nếu người xác thực có hành động ác ý hoặc không trực tuyến liên tục.  Ngoài ra, ETH đặt cược sẽ bị khóa trong một khoảng thời gian, khiến nó có tính thanh khoản kém và dễ bị biến động về giá.",
	"Joint Staking and Individual Staking":"Cam kết chung và cam kết cá nhân",
	"webviewlong9":"Đối với những người dùng không có đủ 32 ETH để đặt cược riêng lẻ, việc đặt cược chung sẽ cung cấp một tùy chọn khác.  Trong đặt cược chung, nhiều người dùng gộp ETH của họ lại với nhau để đạt được số tiền đặt cược cần thiết.  Việc tổng hợp này thường được tạo điều kiện thuận lợi thông qua các hợp đồng thông minh hoặc dịch vụ đặt cược, cho phép người dùng tham gia đặt cược Ethereum PoS với số lượng ETH nhỏ hơn.",
	"Conclusion":"Tóm lại là",
	"webviewlong10":"Đặt cược Ethereum PoS là một khía cạnh quan trọng của bản nâng cấp Ethereum 2.0, mang đến cho người dùng cơ hội hỗ trợ bảo mật mạng, cải thiện tính phân cấp và kiếm phần thưởng.  Bằng cách hiểu rõ quy trình đặt cược, phần thưởng và rủi ro của nó, người dùng có thể đưa ra quyết định sáng suốt về việc tham gia vào quá trình phát triển quan trọng này của mạng Ethereum.",


	//mywallet
	"Total":"số tiền một lần",

	//record
	"Account":"tài khoản",
	"interest-bearing":"Quan tâm",
	"POS Staking":"Cam kết POS",
	"convert":"trao đổi",
	"transaction":"buôn bán",
	"pledge":"thế chấp",
	"recordno":"Bạn vẫn chưa nghe thấy gì cả.  Danh sách trống.",
	"Oops":"Ối",
	"recharge":"nạp tiền",
	"payment":"chi trả",
	"staking":"lời hứa",
	"income":"thu nhập",
	"Pledge quantity":"Số lượng cam kết",
	"cycle":"xe đạp",
	"Days Remaining":"Số ngày còn lại",
	"cumulative gain":"lợi nhuận tích lũy",
	"Price increase":"Giá tăng",
	"Price decrease":"rớt giá",
	"Opening price":"giá mở cửa",
	"Closing price":"Giá đóng cửa",
	"loss":"sự mất mát",
	"pfofit":"lợi nhuận",
	"amount":"Số lượng",

	//notifiCations
	"notifiCationslong1":"Lưu ý: Đăng ký VIP xác định cấp độ của bạn dựa trên giá trị được lưu trữ của tài khoản tổng thể tương ứng.  Phạm vi này cần khấu trừ tổng giá trị được lưu trữ sau khi rút tiền từ tài khoản hiện có để xác định cấp độ thành viên.",
	"notifiCationslong2":"Thời hạn thành viên cũng được chia theo các cấp độ khác nhau.  Để duy trì tư cách thành viên của mình, bạn cần nạp ít nhất 5.000 USDT mỗi lần trong thời gian quy định để được hưởng các lợi ích thành viên.  Sau mỗi lần nạp thẻ thành viên, chu kỳ sẽ được tính lại và có thể được tích lũy.",
	"notifiCationslong3":"Dịch vụ khách hàng được chia theo cấp độ thành viên.  Dịch vụ khách hàng chính thức được cung cấp bởi nhân viên APP để cung cấp các dịch vụ thống nhất cho các thành viên.  Số lượng thư ký chuyên trách được hạn chế để đảm bảo phục vụ tốt hơn.  Thư ký cá nhân độc quyền cung cấp cho người dùng các dịch vụ lễ tân và tư vấn quản lý tài chính độc quyền.",
	"notifiCationslong4":"Liên hệ trước với bộ phận chăm sóc khách hàng để đặt lịch hẹn nạp tiền.  Nếu bạn hoàn tất việc nạp tiền trong thời gian quy định, bạn có thể nhận thêm phần thưởng nạp tiền dành riêng dựa trên cấp độ thành viên tương ứng.",
	"notifiCationslong5":"VIP1: Dựa trên tài khoản UID, nếu giá trị tích lũy là 10.000 USD, bạn có thể nhận được phần thưởng 177 USDT.  Sau khi đạt đến cấp độ VIP và nhận được huy chương thành viên thông thường, điểm sẽ được chuyển vào tài khoản.",
	"notifiCationslong6":"VIP2: Dựa trên tài khoản UID, nếu giá trị tích lũy đạt 30.000 USD, bạn sẽ nhận được 777 USDT.  Số tiền thưởng sẽ được gửi vào tài khoản ngay sau khi đạt cấp VIP và nhận Huy hiệu thành viên Đồng.",
	"notifiCationslong7":"VIP3: Theo tài khoản UID, nếu giá trị tích lũy là 70.000 đô la Mỹ, bạn sẽ nhận được 1.777 USDT.  Sau khi đạt cấp VIP và nhận Huy chương Thành viên Bạc, điểm sẽ được chuyển vào tài khoản.  Ngoài ra, bạn sẽ nhận được thêm 1,5% tiền thưởng trên giá trị đặt phòng tiếp theo.",
	"notifiCationslong8":"VIP4: Theo tài khoản UID, giá trị tích lũy được lưu trữ là 150.000 đô la Mỹ và sẽ được thưởng 2.777 USDT.  Sau khi đạt cấp VIP và nhận Huy chương Thành viên Vàng, điểm sẽ được chuyển vào tài khoản.  Ngoài ra, bạn sẽ nhận được thêm 2% tiền thưởng cho giá trị nạp thêm của lần đặt phòng tiếp theo.",
	"notifiCationslong9":"VIP5: Dựa trên tài khoản UID, nếu giá trị tích lũy đạt 300.000 USD, 4777 USDT sẽ được tặng làm quà tặng.  Sau khi đạt cấp VIP và nhận được huy hiệu VIP Kim cương, điểm sẽ được chuyển vào tài khoản.  Ngoài ra, bạn sẽ nhận được thêm 2,5% tiền thưởng trên giá trị tích lũy của lần đặt phòng tiếp theo.",
	"notifiCationslong10":"VIP6: Dựa trên tài khoản UID, giá trị tích lũy đạt 800.000 USD và 16.888 USDT sẽ được tặng làm quà tặng.  Số tiền thưởng sẽ được airdrop vào tài khoản ngay sau khi đạt cấp VIP và nhận được huy hiệu thành viên Kim cương đen, đồng thời sẽ nhận được thêm 3% số tiền nạp đăng ký trước tiếp theo.  Bạn sẽ có một thư ký riêng tận tâm để cung cấp bất kỳ lời khuyên tài chính nào hoặc giải quyết các vấn đề về thuế.",
	"notifiCationslong11":"VIP7: Dựa trên tài khoản UID, nếu giá trị tích lũy đạt 3 triệu USD, bạn sẽ nhận được chuyến du lịch sang trọng trong 15 ngày tới Châu Âu (có giảm giá).  Được trao giải VIP danh dự trọn đời toàn cầu của CoinBpos và nhận được Huân chương Thành viên Crown.  Bạn sẽ nhận được thêm 3,5% tiền thưởng khi nạp tiền cho lần đặt chỗ tiếp theo.",
	"notifiCationslong12":"VIP8: Theo tài khoản UID, nếu giá trị tích lũy đạt 8 triệu đô la Mỹ, bạn có thể nhận được phiếu quà tặng tiệc hàng năm của CoinBpos Ma-rốc.  Phần thưởng: 1 BTC và nhận VIP danh dự trọn đời toàn cầu của CoinBpos.  Bạn có thể nhận thêm 4% tiền thưởng khi nạp tiền cho lần đặt chỗ tiếp theo.",
	"notifiCationslong13":"VIP9: Dựa trên tài khoản UID, nếu bạn gửi tổng cộng 30 triệu đô la Mỹ, bạn có thể nhận được 3% cổ phần CoinBpos.  Và được hưởng cổ tức lợi nhuận hàng năm là 3%.  Tặng kèm ghim kỷ niệm bằng vàng nguyên khối Cronix.",

	//invite
	"Refer and Earn":"Tham khảo và kiếm tiền",
	"Copy Link":"Sao chép đường dẫn",

	//faq
	"Frequently Asked Questions":"Các câu hỏi thường gặp",

	//authenticator
	"mailbox":"thư",
	"Front photo":"ảnh chân dung",
	"Back photo":"Ảnh quay lại",
	"Handheld ID photo":"Giữ ảnh CMND",
	"define":"Chắc chắn",

	//legality
	"legalitylong1":"Coinbpos bao gồm nhiều công ty tiền điện tử cùng nhau cung cấp các công cụ giúp cộng đồng tiền điện tử tạo ra, phát triển và duy trì sự ổn định của cộng đồng, hỗ trợ hàng nghìn ứng dụng phi tập trung, thúc đẩy tương lai của tiền điện tử và thúc đẩy sự phát triển của tiền tệ truyền thống. sự phồn vinh.",
	"legalitylong2":"Mỗi công ty trong Coinbpos đóng vai trò là người kiểm soát dữ liệu để xử lý dữ liệu cá nhân liên quan đến các dịch vụ của mình, người kiểm soát dữ liệu cho mỗi dịch vụ là:",
	"legalitylong3":"1. Công nghệ chuỗi khối - chuỗi khối Ethereum.",
	"legalitylong4":"2. Ví phi tập trung-Ví Trust, ví MetaMask và tiền điện tử.",
	"legalitylong5":"3. Công nghệ giao dịch-Coinbase, Crypto, linch, Binance.",
	"legalitylong6":"Chính sách quyền riêng tư nêu chi tiết cách Coinbpos xử lý dữ liệu cá nhân theo nghĩa vụ của mình theo luật bảo vệ dữ liệu có liên quan, bao gồm Quy định bảo vệ dữ liệu chung của Liên minh Châu Âu (GDPR),",
	"legalitylong7":'Đạo luật về quyền riêng tư của người tiêu dùng California (CCPA) và các luật chung khác (gọi chung là "Luật bảo vệ dữ liệu"*).',

	//language
	"language":"ngôn ngữ",
	"Confirm":"xác nhận",

	"newtext1":"từ bỏ",
	"newtext2":"Hủy bỏ",
	"newtext3":"Bạn có chắc chắn muốn đăng xuất khỏi tài khoản này không?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"Đăng nhập",
	"newtext9":"tên tài khoản",
	"newtext10":"Số điện thoại",
	"newtext11":"Vui lòng nhập tên người dùng",
	"newtext12":"Vui lòng nhập số điện thoại",
	"newtext13":"mật khẩu",
	"newtext14":"Xin vui lòng nhập mật khẩu",
	"newtext15":"Chưa có tài khoản",
	"newtext16":"đăng ký",
	"newtext17":"Quốc gia",
	"newtext18":"Chọn một quốc gia",
	"newtext19":"Mã xác minh số điện thoại di động",
	"newtext20":"vui lòng nhập mã xác nhận",
	"newtext21":"địa chỉ email",
	"newtext22":"Vui lòng nhập địa chỉ email",
	"newtext23":"Xác nhận mật khẩu",
	"newtext24":"Vui lòng nhập mật khẩu xác nhận",
	"newtext25":"có một tài khoản",
	"newtext26":"Gửi mã xác minh",
	"newtext27":"Số điện thoại di động không chính xác",
	"newtext28":"Gửi thành công",
	"newtext29":"đăng ký thành công",

	"newtext30":'tiền tệ',
	"newtext31":'Ngoại hối',
	"newtext32":'kim loại quý',

	"newtext33":'Tài khoản',
	"newtext34":'Hoạt động trên nền tảng',
	"newtext35":'Dịch vụ khách hàng',
	"newtext36":'lời mời',
	"newtext37":'Xác minh KYC',
	"newtext38":'cài đặt',
	wallet_p:{
		text1: "Sao chép địa chỉ",
		text2: 'Xác nhận',
		text3: 'Chuyển đổi từ',
		text4: "Chuyển đổi sang",
		text5: "TẤT CẢ",
		text6: "Mời gọi",
		text7: 'Nhận phần thưởng giới thiệu',
		text8: "Khi bạn bè của bạn tham gia vào AI arbitrage và thuê máy khai thác, bạn có thể nhận phần thưởng giới thiệu",
		text9: "Sao chép",
		text10: 'Chia sẻ liên kết',
		text11: "Xác minh KYC",
		text12: "Quốc gia",
		text13: "Vui lòng chọn một quốc gia",
		text14: "Tên",
		text15: "Vui lòng nhập tên của bạn",
		text16: "Họ",
		text17: "Vui lòng nhập họ của bạn",
		text18: "Loại chứng chỉ",
		text19: "Vui lòng chọn loại chứng chỉ",
		text20: "Số chứng chỉ",
		text21: "Vui lòng nhập số chứng chỉ",
		text22: "Vui lòng tải lên ảnh mặt trước của thẻ căn cước của bạn",
		text23: "Vui lòng tải lên ảnh mặt sau của thẻ căn cước của bạn",
		text24: "Vui lòng tải lên ảnh mặt trước của hộ chiếu của bạn",
		text25: "Vui lòng tải lên ảnh mặt sau của hộ chiếu của bạn",
		text26: "Email",
		text27: "Vui lòng nhập email của bạn",
		text28: "gửi",
		text29: "Mã xác minh",
		text30: "Vui lòng nhập mã xác minh của bạn",
		text31: "Chứng minh nhân dân",
		text32: "hộ chiếu",
		text33: "hủy bỏ",
		text34: "Quốc gia/Khu vực",
		text35: "Vui lòng nhập thông tin chính xác để gửi để xác thực",
		text36: "Đã gửi thành công!",
		text37: "Định dạng email sai!",
		text38: "bằng lái xe",
		text39: "Vui lòng tải lên ảnh mặt trước bằng lái xe của bạn",
		text40: "Vui lòng tải lên ảnh mặt sau bằng lái xe của bạn",
		
		text41: "số CMND",
		text42: "Vui lòng nhập số ID của bạn",
		text43: "Số hộ chiếu",
		text44: "Vui lòng nhập số hộ chiếu",
		text45: "số giấy phép lái xe",
		text46: "Vui lòng nhập số giấy phép lái xe của bạn",
	},
	jiaoyi:{
		text1:'Hợp đồng thứ hai',
		text2:'Hợp đồng vĩnh viễn',
		text3:'Giao dịch giao ngay',
		text4:'Ủy quyền ngay lập tức',
		text5:'Loại tài khoản',
		text6:'thời gian giao hàng',
		text7:'Phạm vi giá',
		text8:'Giá mua',
		text9:'chi phí',
		text10:'Cân bằng',
		text11:'ít nhất',
		text12:'mong đợi',
		text13:'Số tiền mua',
		text14:'hướng',
		text15:'Giá mua',
		text16:'Giá theo thời gian thực',
		text17:'Đếm ngược',
		text18:'Hợp đồng của tôi',
		text19:'Lợi nhuận',
		text20:'Hợp đồng',
		text21:'giá giao hàng',
		text22:'thời gian giao hàng',
		text23:'Giá thị trường',
		text24:'tăng',
		text25:'rơi',
		text26:'Giá mua',
		text27:'tối đa',
		text28:'Lợi nhuận',
		text29:'phí xử lý',
		text30:'Mua',
		text31:'Bán',
		text32:'Số lượng mua',
		text33:'tài khoản thật',
		text34:'Tài khoản ảo',
		text35:'Chưa có dữ liệu',
		text36:'Vị trí hiện tại',
		text37:'Ủy ban lịch sử',
		text38:'Đóng vị trí',
		text39:'Lề',
		text40:'Lợi nhuận và thua lỗ',
		text41:'giữ',
		text42:'Hồ sơ mua hàng',
		text43:'Bán kỷ lục',
		text44:'Có sẵn',
		text45:'đã chuyển đổi',
		text46:'Xác thực thành công',
		text47:'Thời gian mua hàng',
		text48:'chưa đọc',
		text49:'Đọc',
		text50:'Thứ tự máy chủ',
		text51:'Tổng chênh lệch giá',
		text58:'Thu nhập hôm nay',
		text52:'Robot trí tuệ nhân tạo hoạt động như thế nào',
		text53:'sản phẩm chênh lệch giá',
		text54:'ngày',
		text55:'Số tiền',
		text56:'Thu nhập hàng ngày',
		text57:'Loại tiền tệ',
		text59:'Tham gia kinh doanh chênh lệch giá AI',
		text60:'Không rủi ro, lợi nhuận nhanh chóng',
		text61:'Kinh doanh chênh lệch giá AI',
		text62:'Thu nhập dự kiến',
		text63:'Loại tiền chênh lệch giá',
		text64:'Số tiền ký quỹ',
		text65:'Lưu trữ ngay bây giờ',
		text66:'Thu nhập hàng ngày được gửi tới ví USDT của bạn',
		text67:'Quỹ đầu tư không có rủi ro',
		text68:'Bạn có thể rút tiền bất cứ lúc nào',
		text69:'Trí tuệ nhân tạo hoạt động 24 giờ một ngày',
		text70:'Thu nhập tích lũy',
		text71:'Thời gian hết hạn',
		text72:'tất cả',
		text73:'Đang tiến hành',
		text74:'Hoàn thành',
		text75:'Chi tiết máy chủ',
		text76:'Chọn loại tiền tệ',
		text77:'Hỗ trợ kỹ thuật',
		text78:'Gửi email',
		text79:'Loại giao dịch',
	},
	
	swapadd:{
		text1:'Chính sách Cookie',
		text2:'Chúng tôi cam kết bảo vệ quyền riêng tư và bảo mật dữ liệu của bạn',
		text3:'Giới thiệu',
		text4:'Chính sách cookie này giải thích cách sàn giao dịch phi tập trung của chúng tôi sử dụng cookie và các công nghệ theo dõi tương tự. Bằng việc truy cập hoặc sử dụng Sàn giao dịch, bạn đồng ý với việc sử dụng Cookie như được mô tả trong Chính sách này. ',
		text5:'Cookie là gì? ',
		text6:'Cookie là các tệp văn bản nhỏ được đặt trên thiết bị của bạn (máy tính, điện thoại di động hoặc máy tính bảng) khi bạn truy cập một trang web. Chúng được sử dụng rộng rãi để nâng cao trải nghiệm duyệt web của bạn và cung cấp các tính năng cá nhân hóa trên các trang web. Cookie có thể là cookie phiên (tạm thời và bị xóa khi bạn đóng trình duyệt) hoặc cookie liên tục (lưu lại trên thiết bị của bạn trong thời gian dài hơn). ',
		text7:'Cách chúng tôi sử dụng Cookies',
		text8: 'Mục đích trao đổi sử dụng cookie bao gồm nhưng không giới hạn ở:',
		text9:'Kích hoạt các chức năng và tính năng cơ bản của sàn giao dịch',
		text10:'Phân tích và cải thiện hiệu suất cũng như chức năng của sàn giao dịch',
		text11:'Cá nhân hóa trải nghiệm của bạn và ghi nhớ sở thích của bạn',
		text12:'Theo dõi và hiểu cách sử dụng của bạn trên sàn giao dịch',
		text13:'Cung cấp thông tin quảng cáo và tiếp thị phù hợp',
		text14:'Các loại Cookie chúng tôi sử dụng',
		text15:'a) Cookie thiết yếu: Những cookie này cần thiết cho hoạt động trao đổi và cho phép bạn truy cập và sử dụng các tính năng của nó. Nếu không có những Cookie này, một số dịch vụ có thể không được cung cấp cho bạn. ',
		text16:'b) Cookie phân tích: Những cookie này thu thập thông tin về cách bạn sử dụng sàn giao dịch, chẳng hạn như các trang bạn truy cập và bất kỳ lỗi nào bạn gặp phải. Chúng tôi sử dụng thông tin này để phân tích và cải thiện hiệu suất cũng như chức năng của Exchange. ',
		text17:'c) Cookie chức năng: Những cookie này cho phép Exchange ghi nhớ các lựa chọn của bạn (chẳng hạn như tùy chọn ngôn ngữ) và cung cấp chức năng nâng cao, được cá nhân hóa hơn. ',
		text18:'d) Cookie quảng cáo: Những cookie này được sử dụng để cung cấp quảng cáo phù hợp với bạn và sở thích của bạn. Chúng cũng có thể được sử dụng để giới hạn số lần bạn xem quảng cáo và đo lường hiệu quả của chiến dịch quảng cáo. ',
		text19:'Cookie của bên thứ ba',
		text20:'Exchanges có thể cho phép các nhà cung cấp dịch vụ bên thứ ba đặt Cookies trên thiết bị của bạn cho nhiều mục đích khác nhau, bao gồm phân tích và quảng cáo. Các bên thứ ba này có chính sách bảo mật của riêng họ và có thể thu thập thông tin về hoạt động trực tuyến của bạn trên các trang web hoặc dịch vụ trực tuyến khác nhau. ',
		text21:'Quản lý cookie',
		text22:'Bạn có thể quản lý hoặc xóa Cookies bất kỳ lúc nào thông qua cài đặt trình duyệt của mình. Tuy nhiên, xin lưu ý rằng việc vô hiệu hóa hoặc xóa một số cookie nhất định có thể ảnh hưởng đến chức năng và hiệu suất của sàn giao dịch. ',
		text23:'Đồng ý',
		text24:'Bằng cách sử dụng Sàn giao dịch, bạn đồng ý với việc sử dụng Cookie như được mô tả trong chính sách này. Nếu bạn không đồng ý với việc sử dụng Cookies, bạn có thể vô hiệu hóa hoặc xóa chúng thông qua cài đặt trình duyệt của mình hoặc không sử dụng Exchange. ',
		text25:'Chính sách cập nhật',
		text26:'Chúng tôi có thể cập nhật định kỳ Chính sách cookie này để phản ánh những thay đổi trong thực tiễn của chúng tôi hoặc những thay đổi trong luật hiện hành. Chúng tôi khuyên bạn nên xem lại chính sách này thường xuyên để biết thông tin mới nhất. ',
		text27:'Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về cách chúng tôi sử dụng Cookie, vui lòng liên hệ với nhóm hỗ trợ khách hàng của chúng tôi. ',
		
		text28:'Nhận sự cố và trợ giúp',
		text29:'Bắt đầu',
		text30:'Trao đổi token',
		text31:'Xử lý sự cố',
		text32:'Sàn giao dịch phi tập trung là gì? ',
		text33: 'DEX là một sàn giao dịch tiền điện tử chạy trên mạng blockchain phi tập trung và thường được xây dựng trên các hợp đồng thông minh. Không giống như các sàn giao dịch tập trung truyền thống, CEX dựa vào một cơ quan trung tâm để tạo điều kiện thuận lợi cho các giao dịch và nắm giữ tiền của Người dùng, DEX có thể giao dịch trực tiếp với cả hai bên thông qua. hợp đồng thông minh. ',
		text34:'An ninh của các sàn giao dịch phi tập trung',
		text35: 'DEX hoạt động bằng cách sử dụng hợp đồng thông minh trên blockchain, cho phép giao dịch trực tiếp giữa người mua và người bán, loại bỏ sự cần thiết của các tổ chức bên thứ ba. DEX chạy trên một blockchain công khai, các giao dịch minh bạch và có thể kiểm tra được, đồng thời bất kỳ ai cũng có thể kiểm tra để xác minh tính công bằng và toàn vẹn của DEX. ',
		text36:'Ví phi tập trung là gì? ',
		text37: 'Ví phi tập trung là ví tiền điện tử bao gồm 12 khóa. Người dùng có thể sử dụng 12 khóa để đăng nhập vào ví bằng khóa trên cùng một blockchain để truy cập tài sản kỹ thuật số. Miễn là khóa không bị rò rỉ, không ai có thể lấy được tài sản kỹ thuật số trong ví phi tập trung',
		text38:'Địa chỉ ví là gì? ',
		text39:'Địa chỉ ví tiền điện tử là khóa chung được lấy từ khóa riêng thông qua thuật toán chữ ký đường cong elip. Khóa chung là địa chỉ ví có được thông qua một loạt chuyển đổi. Một hoạt động băm không thể đảo ngược được sử dụng trong quá trình chuyển đổi. địa chỉ chủ yếu được sử dụng để gửi và nhận tiền điện tử. ',
		text40:'An toàn khi sử dụng DEX để trao đổi tiền',
		text41:'Cơ chế hoạt động của DEX được xây dựng trên blockchain. Nó không thông qua bất kỳ tổ chức bên thứ ba nào và áp dụng hệ thống không giám sát để trao đổi trực tiếp. Tất cả các giao dịch đều được thực hiện bằng hợp đồng thông minh. blockchain, thực sự hiện thực hóa tài sản. Tính minh bạch, quyền riêng tư và bảo mật. ',
		text42: 'Tại sao có sự chênh lệch giá cho cùng một mã thông báo trên sàn giao dịch? ',
		text43: 'Cung và cầu thị trường: Sàn giao dịch cung cấp hoạt động mua và bán tài sản, nhưng do cung và cầu thị trường của các sàn giao dịch lớn khác nhau, giá do sàn giao dịch đặt ra để mua bán sẽ thay đổi và nhu cầu đối với một số mã thông báo nhất định cao hơn. Thông thường giá sẽ cao hơn giá thị trường và giá sẽ thấp hơn trên các sàn giao dịch có nhu cầu thấp hơn. ',
		text44: 'Khối lượng giao dịch: Lượng tiền điện tử được mua và bán trên một sàn giao dịch, có thể ảnh hưởng đến giá cả. Khối lượng giao dịch cao hơn thường cho thấy hoạt động thị trường nhiều hơn, điều này có thể thúc đẩy việc phát hiện giá chính xác hơn. Các sàn giao dịch có khối lượng giao dịch thấp có thể có chênh lệch giá chào bán rộng hơn và giá cả không chính xác hơn. ',
		text45: 'Tính thanh khoản: đề cập đến mức độ dễ dàng và khó khăn khi mua hoặc bán một tài sản mà không gây ra sự thay đổi đáng kể về giá. Tiền điện tử có tính thanh khoản cao hơn có xu hướng có giá nhất quán hơn trên các sàn giao dịch. Khi thanh khoản trên sàn giao dịch thấp có thể dẫn đến biến động giá và chênh lệch giá lớn. ',
		text46: 'Lựa chọn mã thông báo: Không phải tất cả các mã thông báo đều có tiềm năng lợi nhuận như nhau và các mã thông báo khác nhau có thể có mức độ thanh khoản, khối lượng giao dịch và biến động giá khác nhau.',
		text47:'Chênh lệch tỷ giá hối đoái là gì? ',
		text48: 'Chênh lệch tỷ giá hối đoái đề cập đến những thay đổi về giá tiền điện tử giữa các sàn giao dịch khác nhau. Bởi vì tiền điện tử được phân tán trên toàn cầu và được giao dịch trên nhiều nền tảng khác nhau nên động lực cung và cầu, tính thanh khoản, khối lượng giao dịch và điều kiện thị trường có thể khác nhau giữa các sàn giao dịch. ',
		text49:'Sàn giao dịch DEX là gì? ',
		text50:'Trao đổi DEX, còn được gọi là trao đổi mã thông báo hoặc trao đổi tài sản, đề cập đến quá trình trao đổi một loại tiền điện tử hoặc mã thông báo lấy một loại tiền điện tử hoặc mã thông báo khác thông qua thỏa thuận trao đổi trên DEX. Các giao dịch này thường được hỗ trợ bởi các hợp đồng thông minh trên blockchain, loại bỏ sự cần thiết của các bên trung gian. ',
		text51: 'Tại sao chênh lệch giá lại biến mất trên các sàn giao dịch lớn? ',
		text52:'Khi không có sự khác biệt về giá token trên các sàn giao dịch khác nhau, điều đó có nghĩa là thị trường tương đối ổn định. Lý do cho tình trạng này là do các nhà giao dịch trên thị trường giao dịch ngang bằng và tự động chênh lệch giá dựa trên các giao dịch không cân bằng và các đại gia thị trường tham gia. giao dịch nhằm khai thác số lượng lớn Tài trợ cho giao dịch chênh lệch giá, giữ giá token thị trường ổn định, khối lượng giao dịch và tính thanh khoản, khối lượng giao dịch cao hơn và đủ thanh khoản cũng như hợp nhất thị trường đều là những lý do khiến chênh lệch giá biến mất trên các sàn giao dịch',
		text53:'Địa chỉ được chỉ định là gì? ',
		text54: 'Địa chỉ được chỉ định là địa chỉ nhận tiền điện tử mà người dùng cần điền sau khi trao đổi thành công. Người dùng thực hiện trao đổi lần đầu tiên cần phải liên kết địa chỉ nhận chung',
		text55:'Trượt giá là gì? ',
		text56: 'Trượt giá, còn được gọi là trượt giá, là sự chênh lệch giữa giá dự kiến ​​của một giao dịch tiền điện tử và giá mà giao dịch thực sự được thực hiện. Trượt giá thường do biến động thị trường, tính thanh khoản, quy mô lệnh, loại lệnh, v.v. Trong một thị trường chuyển động nhanh và biến động như tiền điện tử, giá có thể thay đổi nhanh chóng. ',
		text57:'GAS trao đổi là gì? ',
		text58: 'GAS là phí GAS mà người dùng cần phải trả cho blockchain khi rút tiền điện tử sau khi trao đổi thành công. Phí GAS được sử dụng để xử lý các đơn đặt hàng của người dùng và đồng thời phát sóng trong blockchain để xác nhận sự thành công của giao dịch của người dùng. . Thông thường phí GAS để rút tài sản trao đổi là 0,1%. ',
		text59:'Tại sao lại có đơn hàng đang chờ xử lý? ',
		text60: 'Nghẽn mạng: Trong thời gian có nhu cầu cao hoặc hoạt động gia tăng, mạng blockchain có thể bị tắc nghẽn. Sự tắc nghẽn này có thể dẫn đến thời gian xử lý giao dịch chậm hơn và phí cao hơn. Biến động thị trường lớn hoặc hoạt động giao dịch tăng đột biến có thể gây tắc nghẽn mạng. ',
		text61: 'Vấn đề về khả năng mở rộng: Một số mạng blockchain phải đối mặt với những thách thức về khả năng mở rộng, điều đó có nghĩa là blockchain có thể không xử lý được khối lượng giao dịch lớn một cách nhanh chóng và hiệu quả. Điều này có thể dẫn đến sự chậm trễ và tốc độ giao dịch chậm hơn. ',
		text62: 'Hạn chế thanh khoản: Sự sẵn có của người mua và người bán trên thị trường. Ở một số thị trường tiền điện tử hoặc các loại tiền điện tử cụ thể, tính thanh khoản bị hạn chế. Việc thiếu thanh khoản này có thể khiến giao dịch bị chậm lại. Có thể có ít người tham gia mua hoặc bán ở một mức giá cụ thể, khiến chênh lệch giá chào bán tăng lên. ',
		text63: 'Quy mô lệnh thị trường: Kích thước của lệnh ảnh hưởng đến tốc độ giao dịch, đặc biệt khi đó là lệnh lớn so với thanh khoản sẵn có. Khi các lệnh tương tác với cung hoặc cầu sẵn có trên thị trường, việc đặt một số lượng lớn lệnh mua hoặc bán có thể dẫn đến việc khớp lệnh một phần hoặc thực hiện chậm hơn, dẫn đến lệnh chờ xử lý. ',
		text64:'Tài sản trong lệnh chờ xử lý có an toàn không? ',
		text65: 'Tiền trong các lệnh đang chờ xử lý sẽ không bị mất trong thị trường tiền điện tử. Khi lệnh giao dịch đang chờ xử lý, bạn cần đợi blockchain khớp lệnh. Sau khi lệnh được khớp, giao dịch sẽ được thực hiện ngay lập tức. ',
		text66:'Xử lý đơn hàng tăng tốc là gì? ',
		text67: 'Vì mối quan hệ cung và cầu không còn tồn tại khi nhà giao dịch bắt đầu đặt hàng, nên lệnh tương ứng không thể khớp trong blockchain. Người dùng có thể chọn tăng số tiền giao dịch để hoàn thành giao dịch đặt hàng đang chờ xử lý với mối quan hệ cung và cầu mới. ',
	},
	login: {
		Get_Started: 'Bắt đầu',
		Best_Swap: 'Nền tảng hoán đổi tốt nhất thế giới',
		Decentralized_cryptocurrency: 'Nền tảng trao đổi tiền điện tử phi tập trung, cho phép trao đổi nhanh chóng và liền mạch giữa các tài sản kỹ thuật số khác nhau!',
		Cookies_Privacy: 'Quyền riêng tư của Cookie',
		We_use_cookies: 'Chúng tôi sử dụng cookie để nâng cao trải nghiệm duyệt web của bạn, phân tích lưu lượng truy cập trang web và cung cấp nội dung được cá nhân hóa. Bằng cách tiếp tục sử dụng trang web này, bạn đồng ý với việc chúng tôi sử dụng cookie!',
		Accept: 'Chấp nhận',
		Sum: 'Tổng',
		Swap: 'Tráo đổi',
		Balance: 'Sự cân bằng',
		Name: 'Tên',
		Price_Change: 'Thay đổi giá',
		Sort_Type: 'Loại sắp xếp',
		Add_Asset: 'Thêm tài sản',
		Add_the_cryptocurrency: 'Thêm tài sản tiền điện tử bạn cần',
		Current_Market_Conditions: 'Điều kiện thị trường hiện tại',
		Market: 'Chợ',
		Overview: 'Tổng quan',
		Coin_Information: 'Thông tin tiền xu',
		Market_Value: 'Giá trị thị trường',
		Total_Supply: 'Tổng cung',
		In_Circulation: 'Đang lưu hành',
		Trading_Volume: 'Khối lượng giao dịch',
		Official_Website: 'Trang web chính thức',
	}
}
