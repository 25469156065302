import http from '@/utils/http'

export const financinlist = (data) => {
    return http({
        url: '/api/financinlist',
        method: 'get',
		params:data
    })
}
export const logall = (data) => {
    return http({
        url: '/Financing/logall',
        method: 'get',
		params:data
    })
}
export const financing = (param) => {
    return http({
        url: '/Mobile/Financing/index',
        method: 'post',
        data: param
    })
}
export const Financinglog = (data) => {
    return http({
        url: '/home/Financing/log',
        method: 'get',
		params:data
    })
}