export default {
	"830753-0": "Aufladen",
	"830753-1": "Schnelles Aufladen von Vermögenswerten",
	"830753-2": "Bitte den Beleg hochladen",
	"830753-3": "Aufladen bestätigen",
	"830753-4": "Fehlerhafte Serverantwort-Daten:",
	"830753-5": "Bild hochladen fehlgeschlagen:",
	account: {
		Start_making_money_plan: "Beginnen Sie mit der Gelderstellung",
		Market: "Markt",
		Migital_currency: "Migitale Währung",
		Foreign_exchange: "Austauschjahr",
		Noble_metal: "Edelmetall",
		Send_Cryop_Now: "Senden Sie jetzt Kryo",
		Send: "Schicken",
		Receive: "Erhalten",
		Buy: "Kaufen",
		Currency_account: "Währungskonto",
		Contract_account: "Vertragskonto",
		Transfer: "Überweisen",
		Select_a_wallet: "Wählen Sie eine Brieftasche aus",
		Select_Coin: "Münze auswählen",
		Confirm: "Bestätigen",
		Wallet: "Geldbörse",
		Availavle: "Verfügbar",
		Frozen: "Gefroren",
		Convert: "Konvertieren",
		Receiving_Address: "Empfangsadresse",
		Amount: "Menge",
		Max: "Max",
		Send_Now: "Schick jetzt",
		Please_check_if: "Bitte überprüfen Sie vor dem Versand, ob Ihre Lieferadresse korrekt ist, um Vermögensverluste zu vermeiden.",
		new1:'Kryptowährung',
		new2:'Index',
		new3:'Schüttgut',
		new4:'Forex',
		new5:'Gesamtsaldo',
	},
	"314962-0": "Google 2FA",
  "314962-1": "Laden Sie Google Authenticator zum Verbinden herunter",
  "314962-2": "Verifizierungscode kopieren",
  "314962-3": "Verifizierungscode eingeben",
  "314962-4": "Anfrage fehlgeschlagen:",
  "314962-5": "Verifizierungscode kopiert",
  "314962-6": "Text kann nicht kopiert werden:",
  "314962-7": "Kopieren fehlgeschlagen, bitte erneut versuchen",
  "199917-0": "Einstellungen",
  "199917-1": "Benachrichtigungen",
  "199917-2": "Google 2FA",
  "199917-3": "E-Mail",
  "199917-4": "Sprache",
  "199917-5": "Version",
  "199917-6": "Abmelden",
    home:{
        USDTAccount: 'USDT Account',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: 'Open',
        ETHTodayProfit: 'ETH Today\'s Profit',
        totalRevenue: 'Total Revenue',
        portfolio: 'Portfolio',
        reliableSecurity: 'Reliable Security Guarantee',
        stableReliable: 'Stable and Reliable Investment',
        convenienEasy: 'Convenien and Easy Operation',
        pleaseLookForward: 'Please look forward to...',
        rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
    },
	"ConnectW":"Connect Wallet",
	"dappbrowser":"This website only accepts access from decentralized wallet DAPP browsers.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "Earn Interest",
    "$": "$",
    "ETH Today's Profit": "ETH Today's Profit",
    "Wallet": "Wallet",
    "Record": "Record",
    "Support": "Support",
    "General": "General",
    "Notifications": "Notifications",
    "Invite Friends": "Invite Friends",
    "FAQ": "FAQ",
    "Privacy & Security": "Privacy & Security",
    "Legality": "Legality",
	"Authenticator":"Authenticator",
	"Settings":"Settings",
	"Change Language":"Change Language",
	"Daytime mode":"Daytime mode",
	"Black mode":"Black mode",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',





    //钱包
    TransactiónDetails:'Transactión Details',
	dealdetails:'deal details',
    ViewTransactionHistory:'View Transaction History',
    Deposit:'Deposit',
    Rechargeaddress:'Recharge address：',
    DepositAmount:'Deposit Amount：',
    EnterDepositAmount:'Enter Deposit Amount',
    EnterHash:'Enter Hash',
    Hash:'Hash：',
    Voucherimage:'Voucher image：',
    Continue:'Continue',
    Withdraw:'Withdraw',
    Withdrawal:'Withdrawal：',
    EnterAmounttoWithdraw:'Enter Amount to Withdraw',
    Available:"Available：",
    ReceiveAddress:'Receive Address：',
    EnterWalletAddress:'Enter Wallet Address',
    WithdrawalDesc:'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
    Exchange:'Exchange',
    Send:'Send:',
    Max:'Max:',
    rechargeAmount:'Please input a recharge amount',
    hashcode:'hash code and screenshot cannot be empty at the same time',
    correcthashcode:'please input a correct hash code',
    maximumwithdrawalamount:'The maximum withdrawal amount is',
    Incorrectamountformat:'Incorrect amount format',
	copy:'copy',


	//tab2
	"Total Profit":"Total Profit",
	"Today's Profit":"Today's Profit",
	"What is Automated Trading?":"What is Automated Trading?",
	"Learn about earning":"Learn about earning",
	"Staking Period":"Staking Period",
	"Staking Amount":"Staking Amount",
	"Yield":"Yield",
	"Limit":"Limit",
	"Subscribe":"Subscribe",
	"Record":"Record",

	//tab3
	"Proof of Stake":"Proof of Stake",
	"ETH Today is Price":"ETH Today is Price",
	"ETH Today's Increase":"ETH Today's Increase",
	"Joint Staking":"Joint Staking",
	"Individual Staking":"Individual Staking",
	"tab3long1":"Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2":"Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1":"Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2":"By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3":"Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4":"Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction":"Introduction",
	"webviewlong5":"Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6":'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process":"The Staking Process",
	"webviewlong7":"To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks":"Staking Rewards and Risks",
	"webviewlong8":"By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking":"Joint Staking and Individual Staking",
	"webviewlong9":"For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion":"Conclusion",
	"webviewlong10":"Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total":"Total",

	//record
	"Account":"Account",
	"interest-bearing":"interest-bearing",
	"POS Staking":"POS Staking",
	"convert":"convert",
	"transaction":"transaction",
	"pledge":"pledge",
	"recordno":"You don't have any news yet. The list is empty.",
	"Oops":"Oops",
	"recharge":"recharge",
	"payment":"payment",
	"staking":"staking",
	"income":"income",
	"Pledge quantity":"Pledge quantity",
	"cycle":"cycle",
	"Days Remaining":"Days Remaining",
	"cumulative gain":"cumulative gain",
	"Price increase":"Price increase",
	"Price decrease":"Price decrease",
	"Opening price":"Opening price",
	"Closing price":"Closing price",
	"loss":"loss",
	"pfofit":"pfofit",
	"amount":"amount",

	//notifiCations
	"notifiCationslong1":"Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2":"The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3":"Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4":"Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5":"VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6":"VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7":"VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8":"VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9":"VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10":"VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11":"VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12":"VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13":"VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn":"Refer and Earn",
	"Copy Link":"Copy Link",

	//faq
	"Frequently Asked Questions":"Frequently Asked Questions",

	//authenticator
	"mailbox":"mailbox",
	"Front photo":"Front photo",
	"Back photo":"Back photo",
	"Handheld ID photo":"Handheld ID photo",
	"define":"define",

	//legality
	"legalitylong1":"Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2":"Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3":"1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4":"2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5":"3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6":"Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7":'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language":"language",
	"Confirm":"Confirm",

	"newtext1":"Sign out",
	"newtext2":"Cancel",
	"newtext3":"Are you sure you want to log out of this account?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"Login",
	"newtext9":"username",
	"newtext10":"Mobile phone number",
	"newtext11":"Please enter user name",
	"newtext12":"Please enter your mobile phone number",
	"newtext13":"password",
	"newtext14":"Please enter password",
	"newtext15":"No account yet",
	"newtext16":"Register",
	"newtext17":"Country",
	"newtext18":"Select a country",
	"newtext19":"Mobile phone number verification code",
	"newtext20":"Please enter the verification code",
	"newtext21":"Email address",
	"newtext22":"Please enter your email address",
	"newtext23":"Confirm password",
	"newtext24":"Please enter the confirmation password",
	"newtext25":"Have an account",
	"newtext26":"Send verification code",
	"newtext27":"Mobile phone number is incorrect",
	"newtext28":"Sent successfully",
	"newtext29":"Registration successful",

	"newtext30":'currency',
	"newtext31":'Forex',
	"newtext32":'precious metals',

	"newtext33":'Konto',
	"newtext34":'Plattformaktivitäten',
	"newtext35":'Kundenservice',
	"newtext36":'Einladung',
	"newtext37":'KYC-Überprüfung',
	"newtext38":'setting',
	wallet_p:{
		text1: "Adresse kopieren",
		text2: 'Bestätigen',
		text3: 'Tauschen von',
		text4: "Tauschen zu",
		text5: "MAX",
		text6: "Einladen",
		text7: 'Erhalte Empfehlungsboni',
		text8: "Wenn Ihre Freunde an AI-Arbitrage und Vermietung von Mining-Maschinen teilnehmen, können Sie Empfehlungsboni erhalten",
		text9: "kopieren",
		text10: 'Link teilen',
		text11: "KYC-Verifizierung",
		text12: "Land",
		text13: "Bitte ein Land auswählen",
		text14: "Vorname",
		text15: "Bitte Ihren Vornamen eingeben",
		text16: "Nachname",
		text17: "Bitte Ihren Nachnamen eingeben",
		text18: "Ausweisart",
		text19: "Bitte eine Ausweisart auswählen",
		text20: "Ausweisnummer",
		text21: "Bitte Ihre Ausweisnummer eingeben",
		text22: "Bitte ein Foto der Vorderseite Ihres Personalausweises hochladen",
		text23: "Bitte ein Foto der Rückseite Ihres Personalausweises hochladen",
		text24: "Bitte ein Foto der Vorderseite Ihres Reisepasses hochladen",
		text25: "Bitte ein Foto der Rückseite Ihres Reisepasses hochladen",
		text26: "E-Mail",
		text27: "Bitte Ihre E-Mail-Adresse eingeben",
		text28: "senden",
		text29: "Bestätigungscode",
		text30: "Bitte Ihren Bestätigungscode eingeben",
		text31: "Personalausweis",
		text32: "Reisepass",
		text33: "abbrechen",
		text34: "Land/Region",
		text35: "Bitte die korrekte Information eingeben, um die Authentifizierung zu übermitteln",
		text36: "Erfolgreich versendet!",
		text37: "E-Mail-Formatfehler!",
		text38: "Führerschein",
		text39: "Bitte laden Sie ein Foto der Vorderseite Ihres Führerscheins hoch",
		text40: "Bitte laden Sie ein Foto der Rückseite Ihres Führerscheins hoch",
		
		text41: "ID-Nummer",
		text42: "Bitte geben Sie Ihre ID-Nummer ein",
		text43: "Passnummer",
		text44: "Bitte geben Sie die Passnummer ein",
		text45: "Führerscheinnummer",
		text46: "Bitte geben Sie Ihre Führerscheinnummer ein",
	},
	jiaoyi:{
		text1:'Zweiter Vertrag',
		text2:'Unbefristeter Vertrag',
		text3:'Kassatransaktion',
		text4:'Sofort delegieren',
		text5:'Kontotyp',
		text6:'Lieferzeit',
		text7:'Preisspanne',
		text8:'Kaufpreis',
		text9:'Kosten',
		text10:'Saldo',
		text11:'mindestens',
		text12:'erwartet',
		text13:'Kaufbetrag',
		text14:'Richtung',
		text15:'Kaufpreis',
		text16:'Echtzeitpreis',
		text17:'Countdown',
		text18:'Mein Vertrag',
		text19:'Gewinn',
		text20:'Vertrag',
		text21:'Lieferpreis',
		text22:'Lieferzeit',
		text23:'Marktpreis',
		text24:'aufsteigend',
		text25:'fallen',
		text26:'Kaufpreis',
		text27:'maximum',
		text28:'Gewinn',
		text29:'Bearbeitungsgebühr',
		text30:'Kaufen',
		text31:'Verkaufen',
		text32:'Einkaufsmenge',
		text33:'echtes Konto',
		text34:'Virtuelles Konto',
		text35:'Noch keine Daten',
		text36:'Aktuelle Position',
		text37:'Historische Kommission',
		text38:'Position schließen',
		text39:'Marge',
		text40:'Gewinn und Verlust',
		text41:'halten',
		text42:'Kaufdatensatz',
		text43:'Verkaufsdatensatz',
		text44:'Verfügbar',
		text45:'konvertiert',
		text46:'Authentifizierung erfolgreich',
		text47:'Kaufzeit',
		text48:'ungelesen',
		text49:'Lesen',
		text50:'Host-Reihenfolge',
		text51:'Gesamtarbitrage',
		text58:'Heutige Einnahmen',
		text52:'Wie Roboter mit künstlicher Intelligenz funktionieren',
		text53:'Arbitrageprodukt',
		text54:'Tag',
		text55:'Betrag',
		text56:'Tageseinkommen',
		text57:'Währungstyp',
		text59:'An der KI-Arbitrage teilnehmen',
		text60:'Kein Risiko, schnelle Rendite',
		text61:'KI-Arbitrage',
		text62:'Erwartete Einnahmen',
		text63:'Arbitrage-Münztyp',
		text64:'Treuhandbetrag',
		text65:'Jetzt hosten',
		text66:'Tägliches Einkommen, das an Ihr USDT-Wallet gesendet wird',
		text67:'Investmentfonds Null Risiko',
		text68:'Sie können Ihr Geld jederzeit abheben',
		text69:'Künstliche Intelligenz arbeitet 24 Stunden am Tag',
		text70:'Kumuliertes Einkommen',
		text71:'Ablaufzeit',
		text72:'alle',
		text73:'In Bearbeitung',
		text74:'Vollständig',
		text75:'Hosting-Details',
		text76:'Währung auswählen',
		text77:'Technische Unterstützung',
		text78:'E-Mail senden',
		text79:'Transaktionstyp',
	},
	
	swapadd:{
		text1:'Cookie-Richtlinie',
		text2: 'Wir verpflichten uns, Ihre Datensicherheit und Privatsphäre zu schützen',
		text3:'Einführung',
		text4: 'Diese Cookie-Richtlinie erläutert, wie unser dezentraler Austausch Cookies und ähnliche Tracking-Technologien verwendet.“ Durch den Zugriff auf oder die Nutzung der Börse stimmen Sie der Verwendung von Cookies wie in dieser Richtlinie beschrieben zu. ',
		text5:'Was sind Cookies? ',
		text6:'Cookies sind kleine Textdateien, die auf Ihrem Gerät (Computer, Mobiltelefon oder Tablet) abgelegt werden, wenn Sie eine Website besuchen. Sie werden häufig verwendet, um Ihr Surferlebnis zu verbessern und Personalisierungsfunktionen auf Websites bereitzustellen. Bei Cookies kann es sich um Sitzungscookies (die temporär sind und gelöscht werden, wenn Sie Ihren Browser schließen) oder um dauerhafte Cookies (die für einen längeren Zeitraum auf Ihrem Gerät verbleiben) handeln. ',
		text7:'Wie wir Cookies verwenden',
		text8: 'Zu den Zwecken, für die Börsen Cookies verwenden, gehören unter anderem:',
		text9:'Grundfunktionen und Features der Börse aktivieren',
		text10:'Analysieren und verbessern Sie die Leistung und Funktionalität des Austauschs',
		text11:'Personalisieren Sie Ihr Erlebnis und merken Sie sich Ihre Vorlieben',
		text12:'Verfolgen und verstehen Sie Ihre Nutzungsmuster auf der Börse',
		text13: 'Bereitstellung relevanter Werbe- und Marketingmitteilungen',
		text14:'Arten von Cookies, die wir verwenden',
		text15:'a) Unverzichtbare Cookies: Diese Cookies sind für den Betrieb der Börse notwendig und ermöglichen Ihnen den Zugriff und die Nutzung ihrer Funktionen. Ohne diese Cookies stehen Ihnen möglicherweise einige Dienste nicht zur Verfügung. ',
		text16:'b) Analyse-Cookies: Diese Cookies sammeln Informationen darüber, wie Sie die Börse nutzen, z. B. die von Ihnen besuchten Seiten und etwaige Fehler, auf die Sie stoßen. Wir verwenden diese Informationen, um die Leistung und Funktionalität der Börse zu analysieren und zu verbessern. ',
		text17:'c) Funktionalitäts-Cookies: Diese Cookies ermöglichen es der Börse, sich an Ihre Auswahl zu erinnern (z. B. Spracheinstellungen) und erweiterte, personalisiertere Funktionen bereitzustellen. ',
		text18:'d) Werbe-Cookies: Diese Cookies werden verwendet, um für Sie und Ihre Interessen relevante Werbung bereitzustellen. Sie können auch dazu verwendet werden, die Häufigkeit, mit der Sie eine Anzeige sehen, zu begrenzen und die Wirksamkeit von Werbekampagnen zu messen. ',
		text19:'Cookies von Drittanbietern',
		text20: 'Börsen gestatten möglicherweise Drittanbietern, Cookies für verschiedene Zwecke, einschließlich Analyse und Werbung, auf Ihrem Gerät zu platzieren.“ Diese Dritten haben ihre eigenen Datenschutzrichtlinien und können Informationen über Ihre Online-Aktivitäten auf verschiedenen Websites oder Online-Diensten sammeln. ',
		text21:'Cookie-Verwaltung',
		text22:'Sie können Cookies jederzeit über Ihre Browsereinstellungen verwalten oder löschen. Bitte beachten Sie jedoch, dass die Deaktivierung oder Löschung bestimmter Cookies die Funktionalität und Leistung des Austauschs beeinträchtigen kann. ',
		text23:'Zustimmen',
		text24:'Durch die Nutzung der Börse stimmen Sie der Verwendung von Cookies zu, wie in dieser Richtlinie beschrieben. Wenn Sie mit der Verwendung von Cookies nicht einverstanden sind, können Sie diese über Ihre Browsereinstellungen deaktivieren oder löschen oder den Exchange nicht nutzen. ',
		text25:'Richtlinie aktualisieren',
		text26: 'Wir können diese Cookie-Richtlinie regelmäßig aktualisieren, um Änderungen unserer Praktiken oder Änderungen des geltenden Rechts Rechnung zu tragen.“ Wir empfehlen Ihnen, diese Richtlinie regelmäßig zu überprüfen, um die neuesten Informationen zu erhalten. ',
		text27:'Wenn Sie Fragen oder Bedenken bezüglich der Art und Weise haben, wie wir Cookies verwenden, wenden Sie sich bitte an unser Kundensupport-Team. ',
		
		
		text28:'Fehlerbehebung und Hilfe erhalten',
		text29:'Erste Schritte',
		text30:'Token austauschen',
		text31:'Fehlerbehebung',
		text32:'Was ist eine dezentrale Börse? ',
		text33: 'DEX ist eine Kryptowährungsbörse, die auf einem dezentralen Blockchain-Netzwerk läuft und normalerweise auf intelligenten Verträgen basiert. Im Gegensatz zu herkömmlichen zentralisierten Börsen ist CEX auf eine zentrale Agentur angewiesen, um Transaktionen zu ermöglichen und Benutzergelder zu verwalten. DEX kann direkt mit beiden Parteien handeln.“ Intelligente Verträge. ',
		text34:'Sicherheit dezentraler Börsen',
		text35: 'DEX arbeitet mit intelligenten Verträgen auf der Blockchain und ermöglicht direkte Transaktionen zwischen Käufern und Verkäufern, sodass keine Drittinstitutionen erforderlich sind.“ DEX läuft auf einer öffentlichen Blockchain, Transaktionen sind transparent und überprüfbar und jeder kann die Fairness und Integrität des DEX überprüfen. ',
		text36:'Was ist ein dezentrales Wallet? ',
		text37: 'Eine dezentrale Wallet ist eine Kryptowährungs-Wallet, die aus 12 Schlüsseln besteht. Benutzer können sich mit 12 Schlüsseln bei der Wallet anmelden und den Schlüssel auf derselben Blockchain verwenden, um auf digitale Assets zuzugreifen. Solange der Schlüssel nicht durchgesickert ist, kann niemand an die digitalen Assets in der dezentralen Wallet gelangen',
		text38:'Was ist eine Wallet-Adresse? ',
		text39:'Die Kryptowährungs-Wallet-Adresse ist ein öffentlicher Schlüssel, der durch den Elliptic-Curve-Signatur-Algorithmus erhalten wird. Der öffentliche Schlüssel ist eine Wallet-Adresse, die während des Konvertierungsprozesses verwendet wird Die Adresse wird hauptsächlich zum Senden und Empfangen von Kryptowährungen verwendet. ',
		text40:'Sicherheit der Verwendung von DEX zum Umtausch von Geldern',
		text41: 'Der Betriebsmechanismus von DEX basiert auf der Blockchain und nutzt ein nicht verwahrtes System für den direkten Austausch.“ Alle Transaktionen können auf der Blockchain abgefragt werden Blockchain, die die Verwirklichung von Vermögenswerten wirklich realisiert. ',
		text42: 'Warum gibt es an der Börse einen Preisunterschied für denselben Token? ',
		text43: 'Marktangebot und -nachfrage: Die Börse bietet den Kauf und Verkauf von Vermögenswerten an, aber aufgrund des unterschiedlichen Marktangebots und der unterschiedlichen Marktnachfrage der großen Börsen ändern sich die von der Börse für Verkauf und Kauf festgelegten Preise und die Nachfrage nach bestimmten Token.“ Normalerweise ist der Preis höher als der Marktpreis, und an Börsen mit geringerer Nachfrage wird der Preis niedriger sein. ',
		text44: 'Handelsvolumen: Die Menge an Kryptowährung, die an einer Börse gekauft und verkauft wird und sich auf den Preis auswirken kann. Ein höheres Handelsvolumen weist im Allgemeinen auf eine stärkere Marktaktivität hin, was eine genauere Preisfindung begünstigen kann. Börsen mit geringem Handelsvolumen können größere Geld-Brief-Spannen und ungenauere Preise aufweisen. ',
		text45: 'Liquidität: bezieht sich auf die Leichtigkeit und Schwierigkeit, einen Vermögenswert zu kaufen oder zu verkaufen, ohne dass es zu einer wesentlichen Preisänderung kommt.“ Kryptowährungen mit höherer Liquidität weisen tendenziell einheitlichere Preise an allen Börsen auf. Wenn die Liquidität an einer Börse gering ist, kann es zu Preisschwankungen und großen Preisunterschieden kommen. ',
		text46: 'Token-Auswahl: Nicht alle Token haben das gleiche Gewinnpotenzial und verschiedene Token können unterschiedliche Grade an Liquidität, Handelsvolumen und Preisvolatilität aufweisen.',
		text47:'Was ist ein Wechselkurs-Spread? ',
		text48: 'Wechselkursunterschiede beziehen sich auf Änderungen der Kryptowährungspreise zwischen verschiedenen Börsen.“ Da Kryptowährungen weltweit verstreut sind und auf verschiedenen Plattformen gehandelt werden, können Angebots- und Nachfragedynamik, Liquidität, Handelsvolumen und Marktbedingungen zwischen den Börsen unterschiedlich sein. ',
		text49:'Was ist DEX-Austausch? ',
		text50: 'DEX-Austausch, auch bekannt als Token-Austausch oder Asset-Austausch, bezieht sich auf den Prozess des Austauschs einer Kryptowährung oder eines Tokens gegen eine andere Kryptowährung oder einen anderen Token durch eine Austauschvereinbarung auf DEX.“ Diese Transaktionen werden in der Regel durch intelligente Verträge auf der Blockchain erleichtert, sodass keine Zwischenhändler erforderlich sind. ',
		text51: 'Warum sind die Spreads an den großen Börsen verschwunden?“ ',
		text52: 'Wenn es an verschiedenen Börsen keinen Preisunterschied für Token gibt, bedeutet dies, dass der Markt relativ stabil ist. Der Grund für diese Situation ist, dass Markthändler auf der Grundlage unausgeglichener Transaktionen flach handeln und automatisch Arbitrage betreiben und Marktgiganten daran teilnehmen.“ „Transaktionen zur Ausnutzung einer großen Anzahl von Mitteln für Handelsarbitrage, die Beibehaltung von Markt-Token-Preisen, Handelsvolumen und Liquidität, höheres Handelsvolumen und ausreichende Liquidität sowie Marktkonsolidierung sind alles Gründe, die dazu führen, dass Spreads an Börsen verschwinden',
		text53:'Wie lautet die angegebene Adresse? ',
		text54: 'Die angegebene Adresse ist die Kryptowährungs-Empfangsadresse, die der Benutzer nach erfolgreichem Austausch eingeben muss. Benutzer, die den Austausch zum ersten Mal durchführen, müssen die gemeinsame Empfangsadresse binden.',
		text55:'Was ist ein Preisverfall? ',
		text56: 'Preis-Slippage, auch Slippage genannt, ist die Differenz zwischen dem erwarteten Preis eines Kryptowährungshandels und dem Preis, zu dem der Handel tatsächlich ausgeführt wird.“ Preisabweichungen werden in der Regel durch Marktvolatilität, Liquidität, Auftragsgröße, Auftragsart usw. verursacht. In einem schnelllebigen und volatilen Markt wie der Kryptowährung können sich die Preise schnell ändern. ',
		text57:'Was ist Austausch-GAS? ',
		text58: 'GAS ist die GAS-Gebühr, die Benutzer an die Blockchain zahlen müssen, wenn sie nach einem erfolgreichen Austausch Kryptowährungen abheben. Die GAS-Gebühr wird zur Verarbeitung von Benutzeraufträgen verwendet und gleichzeitig in der Blockchain gesendet, um den Erfolg der Transaktion des Benutzers zu bestätigen.“ . Normalerweise beträgt die GAS-Gebühr für die Abhebung von Börsenvermögen 0,1 %. ',
		text59:'Warum gibt es ausstehende Bestellungen? ',
		text60: 'Netzwerküberlastung: In Zeiten hoher Nachfrage oder erhöhter Aktivität kann es zu einer Überlastung der Blockchain-Netzwerke kommen. Diese Überlastung kann zu langsameren Transaktionsverarbeitungszeiten und höheren Gebühren führen. Große Marktschwankungen oder ein Anstieg der Handelsaktivität können zu einer Überlastung des Netzwerks führen. ',
		text61: 'Skalierbarkeitsprobleme: Einige Blockchain-Netzwerke stehen vor Skalierbarkeitsproblemen, was bedeutet, dass die Blockchain möglicherweise nicht in der Lage ist, große Transaktionsmengen schnell und effizient zu verarbeiten.“ Dies kann zu Verzögerungen und langsameren Transaktionsgeschwindigkeiten führen. ',
		text62: 'Liquiditätsbeschränkungen: Die Verfügbarkeit von Käufern und Verkäufern auf dem Markt. In einigen Kryptowährungsmärkten oder bestimmten Kryptowährungen ist die Liquidität begrenzt. Es kann sein, dass Transaktionen zu einem bestimmten Preis verlangsamt werden, wodurch sich die Geld-Brief-Spanne verlangsamt. ',
		text63: 'Größe der Marktorder: Die Größe der Order beeinflusst die Handelsgeschwindigkeit, insbesondere wenn es sich im Verhältnis zur verfügbaren Liquidität um eine große Order handelt.“ Wenn Aufträge mit dem verfügbaren Angebot oder der verfügbaren Nachfrage auf dem Markt interagieren, kann die Platzierung einer großen Anzahl von Kauf- oder Verkaufsaufträgen zu teilweisen Ausführungen oder einer langsameren Ausführung führen, was zu ausstehenden Aufträgen führt. ',
		text64:'Sind die Vermögenswerte in ausstehenden Aufträgen sicher? ',
		text65: 'Gelder in ausstehenden Aufträgen gehen auf dem Kryptomarkt nicht verloren. Wenn ein Transaktionsauftrag aussteht, müssen Sie warten, bis die Blockchain mit dem Auftrag übereinstimmt, wird die Transaktion sofort ausgeführt.“ ',
		text66:'Was ist eine beschleunigte Auftragsabwicklung? ',
		text67: 'Da die Angebots- und Nachfragebeziehung nicht mehr besteht, wenn der Händler die Bestellung initiiert, kann die entsprechende Bestellung nicht in der Blockchain abgeglichen werden. Benutzer können die Transaktionsbeträge erhöhen, um die ausstehende Bestelltransaktion mit der neuen Angebots- und Nachfragebeziehung abzuschließen.“ ',
		
		
	},
	login: {
		Get_Started: 'Erste Schritte',
		Best_Swap: 'Die weltbeste Tauschplattform',
		Decentralized_cryptocurrency: 'Dezentrale Kryptowährungs-Austauschplattform, die einen schnellen und nahtlosen Austausch zwischen verschiedenen digitalen Assets ermöglicht!',
		Cookies_Privacy: 'Cookies Datenschutz',
		We_use_cookies: 'Wir verwenden Cookies, um Ihr Surf-Erlebnis zu verbessern, den Websiteverkehr zu analysieren und personalisierte Inhalte bereitzustellen. Durch die weitere Nutzung dieser Website stimmen Sie der Verwendung von Cookies zu!',
		Accept: 'Akzeptieren',
		Sum: 'Summe',
		Swap: 'Tauschen',
		Balance: 'Gleichgewicht',
		Name: 'Name',
		Price_Change: 'Preisänderung',
		Sort_Type: 'Sortiertyp',
		Add_Asset: 'Asset hinzufügen',
		Add_the_cryptocurrency: 'Fügen Sie die Kryptowährungs-Assets hinzu, die Sie benötigen',
		Current_Market_Conditions: 'Aktuelle Marktbedingungen',
		Market: 'Markt',
		Overview: 'Überblick',
		Coin_Information: 'Münzinformationen',
		Market_Value: 'Marktwert',
		Total_Supply: 'Gesamtversorgung',
		In_Circulation: 'Im Umlauf',
		Trading_Volume: 'Handelsvolumen',
		Official_Website: 'Offizielle Website',
	}
}
