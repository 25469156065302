<template>
    <div class="app-main">
        <div class="app-content" :class="isBlack == 'false'?'white-theme-app-content':''">
            <div class="top-title">
                <div class="title-txt fl">{{$t('Proof of Stake')}}</div>
                <div class="title-right fr">
                    <van-icon class="the-up" v-if="Number(increase)>0" name="arrow-up" />
                    <van-icon class="the-up" style="color: #e3660a" v-if="Number(increase)<0" name="arrow-down" />
                    {{ increase }} %
                </div>
            </div>

            <section v-if="isBlack == 'true'">
                <!--Earn Interest-->
                <div class="Earn-Interest">
                    <span class="line-span"></span>
                    <div class="w50 fl">
                        <div class="title-tips">{{$t('ETH Today is Price')}}</div>
                        <div class="val-num">$ {{new_price}}</div>
                    </div>
                    <div class="w50 fl">
                        <div class="title-tips">{{$t("ETH Today's Increase")}}</div>
                        <div class="val-num"><img style="width: 20px; margin-right: 5px;" src="@/assets/images/icon/trend-up.png"/>{{ethYe}}</div>
                    </div>
                </div>
                <!--What is Automated Tr-->
                <div class="Automated">
                    <span class="line-span"></span>
                    <div class="fl" style=" line-height: 64px;margin-left: 15px;">
                        <img style="width: 21px;" src="../assets/images/icon/linear.png"/>
                    </div>
                    <div @click="totext" class="fl" style="right: 10px;  position: absolute; top: 14px;text-align: center;width: 85%;">
                        <p>{{$t('What is Automated Trading?')}}</p>
                        <p style=" color: #727272;">{{$t('Learn about earning')}}</p>
                    </div>
                </div>
            </section>

            <section v-if="isBlack == 'false'" class="white-theme-Earn-Interest-and-Automated">
                <!--Earn Interest-->
                <div class="Earn-Interest">
                    <span class="line-span"></span>
                    <div class="w50 fl">
                        <div class="title-tips">{{$t('ETH Today is Price')}}</div>
                        <div class="val-num">$ {{new_price}}</div>
                    </div>
                    <div class="w50 fl">
                        <div class="title-tips">{{$t("ETH Today's Increase")}}</div>
                        <div class="val-num"><img style="width: 20px; margin-right: 5px;" src="@/assets/images/icon/trend-up.png"/>{{ethYe}}</div>
                    </div>
                </div>
                <!--What is Automated Tr-->
                <div class="Automated">
                    <span class="line-span"></span>
                    <div class="fl" style=" line-height: 64px;margin-left: 15px;">
                        <img style="width: 21px;position: relative;left: -5px;" src="../assets/images/icon/linear_2.png"/>
                    </div>
                    <div @click="totext" class="fl" style="right: 10px;  position: absolute; top: 14px;">
                        <p>{{$t('What is Automated Trading?')}}</p>
                        <p style=" color: #727272;">{{$t('Learn about earning')}}</p>
                    </div>
                </div>
            </section>

            <div class="bg-img-box" v-if="isBlack == 'true'"></div>
            <div class="bg-img-box white-theme-imgBoxBg" v-if="isBlack == 'false'"></div>

            <section v-if="isBlack == 'true'">
                <!--animate__jackInTheBox  animate__rotateIn-->
                <div :class=" tabNavVal=='right'? 'staking-box tab-right animate__animated animate__fadeInRight':'staking-box animate__animated animate__fadeInLeft'" style=" margin-top:160px;">
                    <div class="tab-staking">
                        <!--个人质押   最低  32个起       联合质押   最低  1个起-->
                        <div :class=" tabNavVal=='left'? 'tab-item tab-item-act':'tab-item'"  style="border-top-left-radius: 8px" @click="tabNav('left')">{{$t('Joint Staking')}}</div>
                        <div :class=" tabNavVal=='right'? 'tab-item tab-item-act':'tab-item'" style="border-top-right-radius: 8px" @click="tabNav('right')">{{$t('Individual Staking')}}</div>
                    </div>

                    <!--备注：左右俩个都是一个页面-->
                    <!--                <section v-if="tabNavVal == 'left'">-->
                    <div v-if="tabNavVal=='left'" style="font-weight: bold;margin-top: 40px;font-size: 15px;">
                        {{$t('tab3long1')}}
                    </div>
                    <div v-else-if="tabNavVal=='right'" style="font-weight: bold;margin-top: 40px;font-size: 15px;">
                        {{$t('tab3long2')}}
                    </div>
                    <div class="label-self" style="border-bottom: 1px solid rgba(255,255,255,0.3)">
                        <div class="label-txt">{{$t('Yield')}}：</div>
                        <div class="label-val">{{nowDataInfo.rate_min}} - {{nowDataInfo.rate_max}}%</div>
                    </div>
                    <div class="label-s">{{$t('Staking Period')}}</div>

                    <!--适用于移动端的弹出面板-->
                    <!--                    <div class="select-input" @click="showPickerDay = true">-->
                    <!--                        <span>{{dayText}}</span>-->
                    <!--                        <van-icon name="arrow-down" style="position: absolute;right: 20px; top: 20px;" />-->
                    <!--                    </div>-->

                    <!--20230614 改成select 模式-->
                    <div class="select-input">
                        <el-select
                                v-model="dayVal"
                                no-data-text="no data"
                                placeholder="please chose"
                                style="width: 100%"
                                :popper-append-to-body="false"
                                :teleported="false"
                                @change="changeDayVal"
                        >
                            <el-option
                                    v-for="item of columns"
                                    :key="item.value"
                                    :label="item.text"
                                    :value="item.value"
                            />
                        </el-select>

                    </div>

                    <div class="self-input" style="margin: 20px 0;position: relative">
                        <img @click="jianClick" style=" position: absolute;  width: 20px; top: 14px;  left: 15px;z-index: 999" src="../assets/images/icon/jian.png"/>
                        <van-field v-model="numVal" type="digit" @blur="checkNumVal" :class="'input-'+tabNavVal"/>
                        <img @click="jiaClick" style=" position: absolute;  width: 20px; top: 14px;  right: 15px;" src="../assets/images/icon/add.png"/>
                    </div>
                    <div class="submit-btn" @click="submitBtn">
                        {{$t('Subscribe')}}<img style="width: 16px;  position: relative;  left: 5px;" src="../assets/images/icon/right.png">
                    </div>
                    <!--                </section>-->
                    <!--                <section v-else>-->
                    <!--                    <div style="font-weight: bold;margin-top: 40px">-->
                    <!--                        * Individual Staking... what??-->
                    <!--                    </div>-->

                    <!--                </section>-->

                </div>
            </section>

            <section v-if="isBlack == 'false'" class="white-theme">
                <!--animate__jackInTheBox  animate__rotateIn-->
                <div :class=" tabNavVal=='right'? 'staking-box tab-right animate__animated animate__fadeInRight':'staking-box animate__animated animate__fadeInLeft'" style=" margin-top:220px;">
                    <div class="tab-staking">
                        <!--个人质押   最低  32个起       联合质押   最低  1个起-->
                        <div :class=" tabNavVal=='left'? 'tab-item tab-item-act':'tab-item'"  style="border-top-left-radius: 8px;border-top-left-radius: 12px;position: relative; left:0.6px;" @click="tabNav('left')"><span class="sub-txt">{{$t('Joint Staking')}}</span></div>
                        <div :class=" tabNavVal=='right'? 'tab-item tab-item-act':'tab-item'" style="border-top-right-radius: 8px; border-top-right-radius: 12px;position: relative; left: -0.6px;" @click="tabNav('right')"><span class="sub-txt">{{$t('Individual Staking')}}</span></div>
                    </div>

                    <!--备注：左右俩个都是一个页面-->
                    <!--                <section v-if="tabNavVal == 'left'">-->
                    <div class="sub-txt" v-if="tabNavVal=='left'" style="font-weight: bold;margin-top: 40px;font-size: 15px;">
                        {{$t('tab3long1')}}
                    </div>
                    <div class="sub-txt" v-else-if="tabNavVal=='right'" style="font-weight: bold;margin-top: 40px;font-size: 15px;">
                        {{$t('tab3long2')}}
                    </div>
                    <div class="label-self" style="border-bottom: 1px solid rgba(255,255,255,0.3)">
                        <div class="label-txt">{{$t('Yield')}}：</div>
                        <div class="label-val">{{nowDataInfo.rate_min}} - {{nowDataInfo.rate_max}}%</div>
                    </div>
                    <div class="label-s">{{$t('Staking Period')}}</div>

                    <!--适用于移动端的弹出面板-->
                    <!--                    <div class="select-input" @click="showPickerDay = true">-->
                    <!--                        <span>{{dayText}}</span>-->
                    <!--                        <van-icon name="arrow-down" style="position: absolute;right: 20px; top: 20px;" />-->
                    <!--                    </div>-->

                    <!--20230614 改成select 模式-->
                    <div class="select-input">
                        <el-select
                                v-model="dayVal"
                                no-data-text="no data"
                                placeholder="please chose"
                                style="width: 100%"
                                :popper-append-to-body="false"
                                :teleported="false"
                                @change="changeDayVal"
                        >
                            <el-option
                                    v-for="item of columns"
                                    :key="item.value"
                                    :label="item.text"
                                    :value="item.value"
                            />
                        </el-select>

                    </div>

                    <div class="self-input" style="margin: 20px 0;position: relative">
                        <img @click="jianClick" style=" position: absolute;  width: 20px; top: 14px;  left: 15px;z-index: 99999999" src="../assets/images/icon/jian_black.png"/>
                        <van-field v-model="numVal" type="digit" @blur="checkNumVal" :class="'input-'+tabNavVal"/>
                        <img @click="jiaClick" style=" position: absolute;  width: 20px; top: 14px;  right: 15px;z-index: 99999999" src="../assets/images/icon/add_black.png"/>
                    </div>
                    <div class="submit-btn" @click="submitBtn">
                        {{$t('Subscribe')}}<img style="width: 16px;  position: relative;  left: 5px;" src="../assets/images/icon/right.png">
                    </div>
                    <!--                </section>-->
                    <!--                <section v-else>-->
                    <!--                    <div style="font-weight: bold;margin-top: 40px">-->
                    <!--                        * Individual Staking... what??-->
                    <!--                    </div>-->

                    <!--                </section>-->

                </div>
            </section>

        </div>


        <van-popup v-model:show="showPickerDay" position="bottom">
            <van-picker
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPickerDay = false"
            />
        </van-popup>



    </div>
</template>

<script>

    // 功能引入
    import {
        toRefs,
        reactive,
        onMounted,
    } from 'vue'


    //接口
    import { getFinancinList, submitFinancing,initIndex } from '../api/tab4'
    import { showFailToast,showSuccessToast } from 'vant';
    export default {
        components: {
            // Select
        },
        setup() {
            // 数据
            const State = reactive({
                homeData:'',
                increase:'',//涨幅
                new_price:'',//当前价
                ethYe:'',
                tabNavVal:'left',
                dayVal:'',
                dayText:'',
                showPickerDay:false,
                showPickerUsdt:false,
                usdtVal:'USDT',
                numVal:'1',//质押数
                allList:[],//所以质押列表
                nowDataInfo:{},//当前选择的天数，对应的值
                columns: [//天数值

                ],
                isBlack: 'true',//默认黑色（缓存内的值是字符串）
            });

            // 方法
            const Function = reactive({
                setTheme() {
                    //console.log('主题模式', localStorage.getItem('isBlack'))
                    State.isBlack = 'true';
                    if (localStorage.getItem('isBlack')) {
                        State.isBlack = localStorage.getItem('isBlack')//true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
                    }
                },

                tabNav(e){
                    State.tabNavVal = e;
                    //个人质押   最低  32个起       联合质押   最低  1个起
                    if (e == 'left'){
                        State.numVal = 1;
                    }else {
                        State.numVal = 32;
                    }
                },
                jianClick(){
                    //个人质押   最低  32个起       联合质押   最低  1个起
                    if (State.tabNavVal=='left'){
                        //console.log('联合质押')
                        if (Number(State.numVal) == 1){//最低1个
                            return false;
                        }
                        State.numVal--;
                    }else{
                        if (Number(State.numVal) == 32){//最低32个
                            return false;
                        }
                        State.numVal = Number(State.numVal)-32
                        //console.log('个人质押')
                    }
                    // console.log('State.numVal',State.numVal)
                    // if (State.numVal == State.nowDataInfo.stnum ){//最小输入
                    //     return false;
                    // }
                    // State.numVal--;
                },
                jiaClick(){
                    //个人质押   最低  32个起       联合质押   最低  1个起
                    if (State.tabNavVal=='left'){
                        //console.log('联合质押')
                        State.numVal++;
                    }else{
                        //console.log('个人质押')
                        State.numVal = Number(State.numVal)+32
                    }

                    // if (State.numVal == State.nowDataInfo.maxstrnum ) {//最大输入
                    //     return false;
                    // }
                    // State.numVal++;
                },
                checkNumVal(){
                    //console.log('输入框失去焦点--->',1111)
                    // if (Number(State.numVal) > Number(State.nowDataInfo.maxstrnum) ) {//最大输入
                    //     State.numVal = State.nowDataInfo.maxstrnum;
                    // }
                },
                doTest() {
                    // console.log('测试--->', 1111)
                },
                /**
                 *
                 * 去掉空的键直对
                 * @param obj
                 */
                remove_empty(obj) {
                    let v;
                    for (let k in obj) {
                        v = obj[k];
                        if (v === '' || v == 'null' || !v) delete obj[k];
                        else if (v.constructor == Object) this.remove_empty(v);
                    }
                },
                onConfirm(e){
                    //console.log('e--8888->',e)
                    //console.log('State.allList--->res--->',State.allList)

                    State.dayVal = e.selectedOptions[0]?.value;
                    State.dayText = e.selectedOptions[0]?.text;
                    Function.setNowData();
                    State.showPickerDay = false;
                },
                onConfirmUsdt(e){
                    //console.log('e-767676-->',e)
                    // State.usdtVal = e.selectedOptions[0]?.text;
                    // State.showPickerUsdt = false;
                },

                //0614改
                changeDayVal(){
                    Function.setNowData();
                },
                //点击了提交按钮
                submitBtn(){
                    //console.log('点击了提交--->',66666)
                    let p = {
                        id:State.dayVal,
                        price:State.numVal,
                        coin:'eth',
                        type:State.tabNavVal=='left'?'1':'0',//0-个人质押(右边菜单)  1-联合质押(左边菜单)
                        // 个人质押   最低  32个起       联合质押   最低  1个起
                    };
                    this.remove_empty(p)
                    Interface.apiSubmitFinancing(p)
                },
                setNowData(){
                    let data = State.allList;
                    for (let key in data) {
                        if (Object.hasOwnProperty.call(data, key)) {
                            let element = data[key];
                            if (element.id ==  State.dayVal){
                                State.nowDataInfo = element;
                            }
                        }
                    }
                    // State.numVal = State.nowDataInfo.stnum;//默认最小值
                    //console.log('State.nowDataInfo--->',State.nowDataInfo)
                },
            });
            // 接口
            const Interface = reactive({
                //获取首页数据
                apiGetHomeData() {
                    initIndex().then(res=>{

                        State.homeData = res.data.url
                        State.increase = Number(res.data.url.eth.change).toFixed(2)
                        State.new_price = Number(res.data.url.eth.new_price).toFixed(2)
                        //State.ethYe = Number(res.data.url.user.eth).toFixed(2)
						State.ethYe = Number(res.data.url.eth.change).toFixed(2)
                        //console.log('首页数据 State.homeData -->', State.homeData )
                    }).catch(err=>{
                        console.log('err-->',err)
                    })
                },
                //获取质押项目列表
                apiGetFinancinList(){
                    let p = {
                        coin:'eth',
                    }
                    Function.remove_empty(p)
                    getFinancinList(p).then(res=>{
						/*
                        const { data } = res;
                        for (const key in data) {
                            if (Object.hasOwnProperty.call(data, key)) {
                                const element = data[key];
                                State.columns.push({ 'text': `${element.tian} Days`, value: element.id})
                            }
                        }
						*/
					   const data = res.data.info;
					   for (const key in data) {
					   		const element = data[key];
					   		State.columns.push({'text': `${element.tian} Days`, value: element.id})
					   	}
                        State.dayVal = State.columns[0].value;
                        State.dayText = State.columns[0].text;
                        //State.allList = res.data;
						State.allList = res.data.info;
                        Function.setNowData()
                    }).catch(err=>{
                        console.log('err-->',err)
                    })
                },
                //提交
                apiSubmitFinancing(data){
                    submitFinancing(data).then(res=>{
                        if (!res.data.status){
                            showFailToast(res.data.info);
                            return false
                        }
                        showSuccessToast(res.data.info)
                        //console.log('提交响应res--->',res)
                    }).catch(err=>{
                        console.log('err-->',err)
                    })
                },
				totext() {
					this.$router.push({
					    path: "/web_view",
						query:{active:2}
					});
				}

            });

            onMounted(() => {
                Function.doTest()
                Interface.apiGetFinancinList()
                Interface.apiGetHomeData()
                Function.setTheme()
            });
            return {
                ...toRefs(State),
                ...toRefs(Function),
                ...toRefs(Interface),
            }
        },
    }
</script>

<style type="text/css" scoped>
    .app-main {
        width: 100%;
        height: 100%;
        position: fixed;
        background: #000000;
        background-size: 100% 100%;
        /*background: url("@/assets/images/background/bg_3.png");*/
        /*background-size: contain;*/
        color: white;
        overflow-y: auto;
    }

    .app-content {
        padding: 24px;
        overflow-x: hidden;
    }

    .top-title {
        height: 40px;
        color: white;
    }
    .title-txt{
        font-size: 20px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
    }
    .title-right{
        min-width: 84px;
        width: auto;
        padding-right: 10px;
        height: 32px;
        background: rgba(255,255,255,0.1);
        border-radius: 16px;
        text-align: center;
        line-height: 32px;
    }
    .the-up{
        margin: 0 4px;
        font-weight: 600;
        color: #5FC88F;
        position: relative;
        top: -1px;
    }
    .Earn-Interest{
        width: 100%;
        height: 55px;
        margin-top: 18px;
        position: relative;
    }
    .Earn-Interest .line-span{
        width: 1px;
        height: 10px;
        opacity: 0.2;
        position: absolute;
        left: 49%;
        top: 23px;
        background: white;
    }
    .Earn-Interest .w50{
        width: 50%;
        height: 50px;
        text-align: center;
    }
    .Earn-Interest .title-tips{
        font-size: 12px;
    }
    .Earn-Interest .val-num{
        font-size: 20px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        margin-top: 12px;
    }
    .Automated{
        width: calc(100% - 110px);
        width: 268px;
        margin: 24px auto;
        height: 64px;
        background: url("@/assets/images/background/amt_1.png");
        background-size: 100% 100%;
        position: relative;

        /*background: linear-gradient(134deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);*/
        /*border-radius: 10px;*/
        /*border: 1px solid #ffffff30;*/
        /*backdrop-filter: blur(15px);*/
        /*position: relative;*/
    }
    .Automated div{
        height: 100%;
    }
    .Automated .line-span{
        width: 1px;
        height: 10px;
        opacity: 0.2;
        position: absolute;
        left: 51px;
        top: 27px;
        background: white;
    }
    .ReliableBox{
        height: 194px;
        position: relative;
        margin-top: 140px;
    }
    .ReliableBox .fl{
        height: 100%;
        background: linear-gradient(134deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);
        border-radius: 5px;
        border: 1px solid;
        /*border-image: linear-gradient(134deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) 1 1;*/
        /*backdrop-filter: blur(0.5px);*/
        font-size: 12px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
    }
    .ReliableBox .item-box-one{
        height: 90px;
        width: 100%;
        background: linear-gradient(134deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);
        border-radius: 5px;
        border: 1px solid;
        /*border-image: linear-gradient(134deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) 1 1;*/
        /*backdrop-filter: blur(0.5px);*/
        font-size: 12px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
    }
    .ReliableBox .item-box-two{
        height: 95px;
        width: 48%;
        background: linear-gradient(134deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);
        border-radius: 5px;
        border: 1px solid;
        /*border-image: linear-gradient(134deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) 1 1;*/
        /*backdrop-filter: blur(0.5px);*/
        font-size: 12px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        display: inline-block;
        margin-top: 6px;
    }

    .ReliableBox .fl{
        height: 100%;
    }

    .bg-img-box{
        /*width: 100%;*/
        /*background: url("@/assets/images/background/bg_3.png");*/
        /*background-size: contain;*/
        /*height: 200px;*/
        width: 100%;
        height: 206px;
        background: url("@/assets/images/background/tab_3_1.png") no-repeat;
        background-size: 100% 100%;
        background-size: cover;
        position: absolute;
        top: 204px;
        left: 0;
    }

    .staking-box{
        position: relative;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 60px;
        height: 416px;

        /*background: url("@/assets/images/background/tab_11.png") no-repeat;*/
        /*background-size: 100% 100%;*/
        /*border-radius: 8px;*/

        /*border: 1px solid #ffffff30;*/
        /*background: linear-gradient(134deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);*/
        /*backdrop-filter: blur(125.5px);*/

    }

    .staking-box::after {
        content: "";
        width: 100%;
        height: 100%;
        background: url("@/assets/images/background/tab_11.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 8px;
        pointer-events: none;
    }
    .tab-right::after{
        transform: rotateY(180deg) !important;   /* 水平镜像翻转 */
    }
    .tab-right{
        height: 445px;
    }

    .tab-staking{
        height: 44px;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        font-size: 14px;

    }
    .tab-item{
        width: 50%;
        display: inline-block;
        height: 100%;
        line-height: 40px;
        text-align: center;
        color: #d1d1d1;
        background: #3737377a;
    }
    .tab-item-act{
        font-weight: bold;
        color: white;
    }
    .select-input{
        height: 50px;
        background: rgba(255,255,255,0.1);
        border-radius: 4px;
        padding: 0 20px;
        line-height: 50px;
        /*position: relative;*/
		display: flex;
		align-items: center;
		justify-content: space-between;
    }
    .staking-box .label-s{
        margin: 10px 0;
    }
    .select-input{
		/*width: 100%;*/
        height: 50px;
    }
    /deep/.van-field {
        flex-wrap: wrap;
        height: 50px;
        /*background: none;*/
        background: rgba(255,255,255,0.1);
        border-radius: 4px;
        /*border: 1px solid #FFFFFF;*/
        color: white;
    }
    /*.select-input .van-cell .van-cell__value .van-field__body /deep/.van-field__control {*/
    /*    color: white !important;*/
    /*}*/
    /deep/ .van-field__control{
        color: white !important;
        text-align: center;
    }
    .label-self{
        height: 50px;
        line-height: 50px;
        margin: 10px 0;
    }
    .label-txt{
        width: 20%;
        float: left;
        height: 50px;
    }
    .label-val{
        width:80%;
        text-align: right;
        float: left;
        height: 50px;
    }
    .submit-btn{
        height: 50px;
        background: #222222;
        border-radius: 4px;
        text-align: center;
        line-height: 50px;
    }
    .fl {
        float: left;
    }
    .fr {
        float: right;
    }
    .van-cell:after{
        border: unset !important;
    }

    /*0614改 覆盖select样式*/
    .select-input{
        height: 50px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 0 20px;
        line-height: 50px;
        position: relative;
        margin-bottom: 20px;

        padding: 0;
    }
    /deep/ .el-input__wrapper{
        background: none !important;
        color: white !important;;
        border: unset !important;;
        box-shadow: unset !important;;
        transform: unset !important;;
    }
     .select-input /deep/ .el-select .el-input.is-focus .el-input__wrapper{
        background: none !important;
        color: white !important;;
        border: unset !important;;
        box-shadow: unset !important;;
        transform: unset !important;;
    }
     .select-input /deep/ .is-focus{
        background: none !important;
        color: white !important;;
        border: unset !important;;
        box-shadow: unset !important;;
        transform: unset !important;;
    }
    /deep/ .el-select .el-input__wrapper.is-focus  {
        background: none !important;
        color: white !important;;
        border: unset !important;;
        box-shadow: unset !important;;
        transform: unset !important;;
    }
    /deep/ .el-input__inner{
        color: white;
    }
    /*0614改 覆盖select样式完*/

    /*个人质押不能输入。只能点击加减*/
    .input-right /deep/ .van-cell__value{
       pointer-events: none;
    }




    /*0708改白色主题*/
    .white-theme-app-content{
        background: #F5F5F5 !important;
    }
    .white-theme-app-content /deep/ .top-title{
        color: #333333 !important;
    }
    .white-theme-app-content /deep/ .title-right{
        background: rgba(51,51,51,0.1) !important;
    }
    .white-theme-Earn-Interest-and-Automated{
        height: 165px;
        height: 150px;
        background: #FFFFFF;
        border-radius: 20px;
        padding-top: 12px;
        padding-bottom: 15px;
        z-index: 1;
        position: relative;
        color: #333333;
    }
    .white-theme-Earn-Interest-and-Automated /deep/ .Automated{
        margin: 6px auto;
    }
    /deep/ .white-theme-imgBoxBg{
        /*width: 100%;*/
        /*background: url("@/assets/images/background/bg_3.png");*/
        /*background-size: contain;*/
        /*height: 200px;*/
        /*width: 100%;*/
        height: 181px;
        /*background: url("@/assets/images/background/tab3_white_bg.png") no-repeat;*/
		background: url("@/assets/images/background/tab3_white_bg.jpg") no-repeat;
        background-size: 100% 100%;
        background-size: cover;
        position: absolute;
        top: 204px;
        width: calc(100% - 48px);
        left: 24px;
        border-radius: 20px;
        margin-top: 56px;
    }
    .white-theme{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #333333 !important;
        background: #FFFFFF !important;
    }
    .white-theme .sub-txt{
        z-index: 9999;
        position: relative;
    }
    .white-theme /deep/ .tab-item-act{
        color: #333333 !important;
    }
    .white-theme /deep/ .staking-box::after {
        content: "";
        width: 100%;
        height: 100%;
        background: url("@/assets/images/background/tab_11_white.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 8px;
        pointer-events: none;
    }
    .white-theme /deep/ .submit-btn{
        color: #ffffff !important;
        z-index: 9999;
        position: relative;
    }
    .white-theme /deep/ .wrapper{
        background: white !important;
        z-index: 9999;
        position: relative;
        border-radius: 20px !important;
    }
    .white-theme /deep/ .label-s{
        color: #333333 !important;
        z-index: 9999;
        position: relative;
    }
    .white-theme /deep/ .label-txt{
        color: #333333 !important;
        z-index: 9999;
        position: relative;
    }
    .white-theme /deep/ .label-val{
        color: #333333 !important;
        z-index: 9999;
        position: relative;
    }
    .white-theme /deep/ .select-input {
        background: rgba(51,51,51,0.1);
        z-index: 9999;
    }
    .white-theme /deep/ .el-select .el-input__inner{
        color: #333333 !important;
        z-index: 9999;
        position: relative;
    }
    .white-theme /deep/ .van-field{
        background: rgba(51,51,51,0.1) !important;
        z-index: 9999;
        position: relative;
    }
    .white-theme /deep/ .van-field__control{
        color: #333333 !important;
        z-index: 9999;
        position: relative;
    }
    .white-theme /deep/ .label-self:nth-child(odd){
        border-bottom: 1px solid rgba(51,51,51,0.1) !important;
        z-index: 9999;
        position: relative;
    }

    /*白色主题完*/
</style>
