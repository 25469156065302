<template>
  <van-popup
      v-model:show="showPop"
      position="bottom"
      style="height: 85%"
  >
    <div class="popup_container">
      <div class="popup_content">
        <div class="popup_header">
          <div class="header_top">
            <div> {{ itemObj[0] }}</div>
            <img @click="closePop" src="../../static/login/icon_close.svg" alt="" style="width: 24px;height: 24px"
                 class="close">
          </div>
          <div class="header_content">
            <div class="intro"> {{ itemObj[0] }}{{ $t('login.Current_Market_Conditions') }}</div>
          </div>
          <div class="header_footer">
            <div class="switch_aontent">
              <div class="switch_item" :class="isMarket? 'active': ''" @click="isMarket=true"> {{ $t('login.Market') }}</div>
              <div class="switch_item" :class="!isMarket? 'active': ''" @click="isMarket=false"> {{ $t('login.Overview') }}</div>
            </div>
          </div>
        </div>
        <div class="popup_body">
          <div class="body_content">
            <div v-show="isMarket" class="price_container">
              <div class="section_top">
                <div class="left_content">
                  <div class="coin_icon">
                    <img :src="'https://vanke-admin.h98.net' + item[10]" style="width: 23px;height: 23px" alt="">
                  </div>
                  <span>{{ itemObj[0] }}</span></div>
                <div class="right_content"><span>${{ item[2] }}</span><img
                    src="../../static/login/icon_global.svg" style="width: 23px;height: 23px" alt=""></div>
              </div>
              <div class="section_main">
                <div class="time_switch_container">
                  <div class="switch_content">
                    <div class="switch_item"
                         :class="echarsIndex === index ? 'active':''"
                         v-for="(item, index) in echarsList"
                         :key="item"
                         @click="changeCharts(index)"
                    >
                      {{item}}
                    </div>
                  </div>
                </div>
                <div class="kline_container">
                  <div class="kline_bg">
                    <div class="row_bg_con">
                      <div class="row_bg"></div>
                      <div class="row_bg"></div>
                      <div class="row_bg"></div>
                      <div class="row_bg"></div>
                      <div class="row_bg"></div>
                    </div>
                    <div class="col_bg_con">
                      <div class="col_bg"></div>
                      <div class="col_bg"></div>
                      <div class="col_bg"></div>
                      <div class="col_bg"></div>
                      <div class="col_bg"></div>
                      <div class="col_bg"></div>
                      <div class="col_bg"></div>
                      <div class="col_bg"></div>
                    </div>
                  </div>
                  <div id="kline_content" :style="{ width: '100%', height: '100%' }"></div>
                </div>
                <div class="info_container">
                  <div class="info_content">
                    <div class="info_title">{{ $t('login.Coin_Information') }}</div>
                    <div class="info_list">
                      <div class="info_item">
                        <div class="name">{{ $t('login.Market_Value') }}</div>
                        <div class="value">${{ itemObj[2] }}</div>
                      </div>
                      <div class="info_item">
                        <div class="name">{{ $t('login.Total_Supply') }}</div>
                        <div class="value"> 0.0 BTC</div>
                      </div>
                      <div class="info_item">
                        <div class="name">{{ $t('login.In_Circulation') }}</div>
                        <div class="value"> 0.0 BTC</div>
                      </div>
                      <div class="info_item">
                        <div class="name">{{ $t('login.Trading_Volume') }}</div>
                        <div class="value">${{ itemObj[6] }}</div>
                      </div>
                      <div class="info_item">
                        <div class="name">{{ $t('login.Official_Website') }}</div>
                        <div class="value color">https://bitcoin.org</div>
                      </div>
                    </div>
                  </div>
                </div>
				<div style="height: 50px;"></div>
              </div>
            </div>
            <div v-show="!isMarket" class="market_container">
              <div class="section_top">
                <div class="left_content">
                  <img @click="isSortDown = !isSortDown" :class="isSortDown? '' : 'rotate'" src="../../static/login/icon_sort.svg" style="width: 16px;height: 16px" alt="">
                </div>
                <div class="right_content">
                  <div class="action_btn">
                    <div>USD</div>
                    <div class="dots">
                      <div class="dot white"></div>
                      <div class="dot yellow"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section_main">
                <div class="pro_item" v-for="(item, index) in coinList" :key="index">
                  <div class="item_header">
                    <div class="info">
                      <img :src="'https://vanke-admin.h98.net' + item[10]" style="width: 23px;height: 23px" alt="">
                      <div class="info_content">
                        <div class="coin">{{ item[0] }}/USDT</div>
                        <div class="change"> {{ item[1] }}</div>
                      </div>
                    </div>
                    <div class="price">
                      <div class="value">{{ item[2] }} USDT</div>
                      <div class="change"><span >{{ $t('login.Trading_Volume') }}</span><span
                          class="amount">{{ item[6] }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
			  <div style="height: 50px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import {ref, defineProps, defineEmits, watch, onMounted} from "vue";
import {kline, kxianbData} from "@/api/tab1";
import * as echarts from 'echarts'

const props = defineProps({
  show: Boolean,
  item: Object,
});
const showPop = ref(props.show)
const itemObj = ref(props.item)
const isMarket = ref(true)
const isSortDown = ref(true)
const echarsList = ref(['15m', '30m', '1h', '6h', '1d'])
const echarsIndex = ref(0)
const coinList = ref([])
const myChart = ref(null)
const djstime = ref(true)
const klinedata = ref([])

watch(() => props.show, (newValue) => {
  showPop.value = newValue;
  if (showPop.value && props.item){
    getKlineData()
	djstime.value = true
  } else {
	djstime.value = false
  }
  console.log(111111, newValue)
});
watch(() => props.item, (newValue) => {
  itemObj.value = newValue;
  console.log(111111, newValue)
});

const emit = defineEmits(['onClose']);

const closePop = () => {
  // 修改对象后发送给父组件
  emit('onClose');
};
const getKlineData = () => {
  let data = {
    name: itemObj.value[0],
    time: echarsList.value[echarsIndex.value]
  }
  kxianbData(data).then(res => {
    console.log(res)
	klinedata.value = res.data.info
    setEcharts(res.data.info)
  })
}

const changeCharts = (index) => {
  echarsIndex.value = index
  klinedata.value = []
  getKlineData()
}

const setEcharts = (info) => {
  if (info &&info.length>0){
    const date=[]
    const data=[]
    info.forEach(item => {
      date.push(item.time)
      data.push(item.value)
    })
    let chartDom = document.getElementById("kline_content")
    if (!chartDom) return
    myChart.value = echarts.init(chartDom);
    let option = {
      grid: [
        {
          left: '0',
          right: '0',
          bottom: '0',
          top: '5',
          height: '100%',
        }
      ],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: date
      },
      yAxis: {
        type: 'value',
		scale: true,
        boundaryGap: false,
        splitLine: false
      },
      series: [
        {
          name: 'Fake Data',
          type: 'line',
          symbol: 'none',
          smooth: 0.5,
          itemStyle: {
            color: 'rgb(252, 210, 63)'
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(252, 210, 63, .3)'
              },
              {
                offset: 1,
                color: 'rgba(252, 210, 63, 0)'
              }
            ])
          },
          data: data
        }
      ]
    }
    myChart.value.setOption(option,true);
	if(djstime.value){
		setTimeout(() => {
			console.log('timeoutlk')
			daojishisetEcharts()
		}, 2000)
	}
  }
}
const daojishisetEcharts = () => {
	let minindex = Number(klinedata.value.length) > 300 ? 200 : 0
	let maxindex = Number(klinedata.value.length) - 1
	let randindex = Math.floor(Math.random() * (maxindex - minindex) + minindex);
	let randdata = klinedata.value[randindex]
	klinedata.value.push(randdata)
	setEcharts(klinedata.value)
}
const getList = () =>{
  kline({id: 1, type: 0, p: 1}).then(res => {
    const {
      data
    } = res;
      coinList.value = data.url
  })
}
onMounted(()=>{
  getList()
})

</script>

<style scoped lang="scss">
.popup_container {
  width: 100%;
  color: #fff;
  font-size: 14px;

  .popup_content {
    width: 100%;
    height: 100%;
    background: #151515;
    padding: 23.0769px 15.3846px;
    border-radius: 15.3846px 15.3846px 0 0;
    box-sizing: border-box;

    .popup_header {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      //padding: 23.0769px 15.3846px;
      border-radius: 15.3846px 15.3846px 0 0;
      background: #151515;

      .header_top {
        padding: 23.0769px 15.3846px 0 15.3846px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        font-size: 30px;
      }

      .header_content {
        padding: 0 15.3846px;
        margin-top: 7.69231px;

        .intro {
          color: #8a8a8a;
          font-weight: 400;
          font-size: 12px;
        }
      }

      .header_footer {
        margin-top: 7.69231px;

        .switch_aontent {
          padding: 0 23.0769px;
          display: flex;
          align-items: center;
          color: #8a8a8a;
          font-size: 14px;
          font-weight: 500;
          border-bottom: 1px solid hsla(0, 0%, 100%, .1);
          box-sizing: border-box;

          .switch_item {
            height: 46.1518px;
            line-height: 46.1518px;
            min-width: 96.1538px;
            text-align: center;
          }

          .active:after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: #fcd23f;
            border-radius: 1px;
          }

          .active {
            position: relative;
            color: #fff;
          }
        }
      }
    }

    .popup_body {
      position: absolute;
      top: 138.462px;
      left: 0;
      bottom: 0;
      width: 100%;
      overflow: auto;
      background: #151515;

      .body_content {
        padding-bottom: 23.0769px;

        .price_container {
          .section_top {
            height: 46.1518px;
            padding: 0 15.3846px;
            border-bottom: 1px solid hsla(0, 0%, 100%, .1);
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 400;
            font-size: 16px;

            .left_content {
              display: flex;
              align-items: center;
              color: #8a8a8a;

              .coin_icon {
                margin-right: 15.3846px;
                width: 23.0714px;
                height: 23.0714px;
                border-radius: 50%;
                overflow: hidden;
              }
            }

            .right_content {
              display: flex;
              align-items: center;
              font-weight: 700;
              color: #fff;

              img {
                margin-left: 15.3846px;
                border-radius: 50%;
              }
            }
          }

          .section_main {
            .time_switch_container {
              padding: 15.3846px;
              overflow: auto;

              .switch_content {
                display: flex;
                justify-content: space-between;

                .switch_item {
                  margin-right: 11.5385px;
                  width: 63.4554px;
                  height: 26.9196px;
                  line-height: 26.9196px;
                  text-align: center;
                  font-weight: 500;
                  font-size: 14px;
                  color: #8a8a8a;
                  background: hsla(0, 0%, 54.1%, .1);
                  border-radius: 19.2308px;
                }

                .active {
                  background: #fcd23f;
                  color: #0b0b0b;
                }
              }

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .kline_container {
              position: relative;
              padding: 15.3846px 0;
              height: 161.536px;

              .kline_bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .row_bg_con {
                  .row_bg {
                    margin-bottom: 48.0769px;
                    width: 100%;
                    height: 1px;
                    background: hsla(0, 0%, 100%, .04);
                  }
                }

                .col_bg_con {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;

                  .col_bg {
                    margin-right: 48.0769px;
                    width: 1px;
                    height: 100%;
                    background: hsla(0, 0%, 100%, .04);
                  }
                }
              }

              .kline_content {
                .kline_content {
                  position: relative;
                  height: 100%;
                }
              }
            }

            .info_container {
              margin-top: 19.2308px;
              padding: 0 15.3846px;

              .info_content {
                padding: 15.3846px 7.69231px;
                background: rgba(0, 0, 0, .5);
                border-radius: 15.3846px;

                .info_title {
                  padding-left: 7.69231px;
                  font-weight: 500;
                  font-size: 16px;
                  color: #fff;
                }

                .info_list {
                  margin-top: 9.61539px;
                  padding: 0 15.3846px;
                  border: 1px solid hsla(0, 0%, 100%, .1);
                  border-radius: 15.3846px;

                  .info_item {
                    height: 42.3036px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid hsla(0, 0%, 100%, .1);
                    font-weight: 400;
                    font-size: 14px;

                    .name {
                      color: #fff;
                    }

                    .value {
                      color: #8a8a8a;
                    }

                    .color {
                      color: #fcd23f;
                    }
                  }
                }
              }
            }
          }
        }
        .market_container{
          .section_top {
            height: 46.1518px;
            padding: 0 15.3846px;
            border-bottom: 1px solid hsla(0, 0%, 100%, .1);
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            .left_content {
              width: 26.9196px;
              height: 26.9196px;
              border-radius: 50%;
              background: hsla(0, 0%, 54.1%, .1);
              display: flex;
              align-items: center;
              justify-content: center;
              .rotate {
                transform: rotateX(180deg);
              }
            }
            .right_content{
              .action_btn {
                padding: 0 11.5385px;
                height: 26.9196px;
                background: hsla(0, 0%, 54.1%, .1);
                border-radius: 19.2308px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #8a8a8a;
                font-size: 14px;
                .dots {
                  margin-left: 7.69231px;
                  .dot {
                    width: 3.83929px;
                    height: 3.83929px;
                    border-radius: 50%;
                  }
                  .white {
                    background: #fff;
                  }
                  .yellow {
                    margin-top: 3.84615px;
                    background: #fcd23f;
                  }
                }
              }
            }
          }
          .section_main{
            .pro_item {
              border-top: 1px solid hsla(0, 0%, 100%, .1);
              padding: 11.5385px 15.3846px;
              background: #151515;
              .item_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .info {
                  display: flex;
                  align-items: center;
                  img {
                    width: 23.0714px;
                    height: 23.0714px;
                    border-radius: 3.84615px;
                  }
                  .info_content {
                    margin-left: 15.3846px;
                    .coin {
                      font-weight: 600;
                      font-size: 16px;
                    }
                    .change {
                      margin-top: 3.84615px;
                      color: #8a8a8a;
                      font-weight: 500;
                      font-size: 12px;
                    }
                  }
                }
                .price {
                  font-weight: 400;
                  font-size: 16px;
                  text-align: right;
                  .change {
                    margin-top: 3.84615px;
                    font-size: 14px;
                    color: #fcd23f;
                    .amount {
                      margin-left: 4px;
                      color: #8a8a8a;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>