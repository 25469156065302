<template>
  <van-tabbar  :class="isBlack != 'true'?'tabbar-white':'tabbar'" v-model="active" route>
    <van-tabbar-item replace to="/homeTab">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="~assets/home/icon_menu1_active.svg" />
        <img class="image" v-else src="~assets/home/icon_menu1.svg">
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
		  <img class="image" v-if="props.active" src="~assets/home/icon_home_selected_white.png" />
		  <img class="image" v-else src="~assets/home/icon_home_white.png">
	  </template>
    </van-tabbar-item>
<!--    <van-tabbar-item replace to="/tab1">-->
<!--      <template v-if="isBlack == 'true'" #icon="props">-->
<!--        <img class="image" v-if="props.active" src="~assets/home/icon_stock_selected.png" />-->
<!--        <img class="image" v-else src="~assets/home/icon_stock.png">-->
<!--      </template>-->
<!--	  <template v-else-if="isBlack == 'false'" #icon="props">-->
<!--		  <img class="image" v-if="props.active" src="~assets/home/icon_stock_selected_white.png" />-->
<!--		  <img class="image" v-else src="~assets/home/icon_stock_white.png">-->
<!--	  </template>-->
<!--    </van-tabbar-item>-->
    <van-tabbar-item to="/swap">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="~assets/home/icon_menu2_active.svg" />
        <img class="image" v-else src="~assets/home/icon_menu2.svg">
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
	  	<img class="image" v-if="props.active" src="~assets/home/icon_coin_selected_white.png" />
	  	<img class="image" v-else src="~assets/home/icon_coin_white.png">	  
	  </template>
    </van-tabbar-item>
    <van-tabbar-item to="/tab4">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="~assets/home/icon_menu3_active.svg" />
        <img class="image" v-else src="~assets/home/icon_menu3.svg">
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
	  	<img class="image" v-if="props.active" src="~assets/home/icon_profile_selected_white.png" />
	  	<img class="image" v-else src="~assets/home/icon_profile_white.png">	  
	  </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script setup>
import {ref} from 'vue'

const active = ref(0);
const isBlack = localStorage.getItem('isBlack') ? localStorage.getItem('isBlack') : 'true'
//console.log(localStorage.getItem('isBlack'))
</script>

<style scoped lang="scss">
.tabbar{
  background: #202222;
  .image{
    width: 25px;
    height: 25px;
  }
  .van-tabbar-item--active{
    background-color: #202222;
  }
}

.tabbar-white{
	background: #ffffff;
}
</style>
