import http from '@/utils/http'

//质押列表
export const getFinancinList = (data) => {
    return http({
        url: `/api/financinlist`,
        method: 'get',
        params:{...data}
    })
};

//质押交易提交
export const submitFinancing = (param) => {
    return http({
        url: '/Mobile/Financing/index',
        method: 'post',
        data: param
    })
};

//首页数据
export const initIndex = (data) => {
    return http({
        url: '/index/index',
        method: 'get',
        params:{...data}
    })
};

//获取客服发过来的消息状态
export const getmasgstatus = (data) => {
    return http({
        url: '/api/getmsg',
        method: 'get',
        params:{...data}
    })
};

//更改消息状态
export const upmsgstatus = (param) => {
    return http({
        url: '/api/upmsg',
        method: 'post',
        data: param
    })
};

//退出
export const loginout = (data) => {
    return http({
        url: `/login/loginout`,
        method: 'get',
        params:{...data}
    })
};