export default {
	"830753-0": "充值",
	"830753-1": "快速進行資產充值",
	"830753-2": "請上傳憑證",
	"830753-3": "確認充值",
	"830753-4": "伺服器響應數據不正確:",
	"830753-5": "圖片上傳失敗:",
	account: {
		Start_making_money_plan: "開始賺錢計劃",
		Market: "市場",
		Migital_currency: "數位貨幣",
		Foreign_exchange: "外匯",
		Noble_metal: "貴金屬",
		Send_Cryop_Now: "立即發送凍結",
		Send: "傳送",
		Receive: "收到",
		Buy: "買",
		Currency_account: "貨幣帳戶",
		Contract_account: "合約帳戶",
		Transfer: "轉移",
		Select_a_wallet: "選擇錢包",
		Select_Coin: "選擇硬幣",
		Confirm: "確認",
		Wallet: "錢包",
		Availavle: "可用的",
		Frozen: "凍結",
		Convert: "轉變",
		Receiving_Address: "收貨地址",
		Amount: "數量",
		Max: "最大限度",
		Send_Now: "現在發送",
		Please_check_if: "寄送前請檢查您的收貨地址是否正確，以免造成資產損失",
		new1: '加密貨幣',
		new2: '指數',
		new3: '大宗',
		new4: '外匯',
		new5: '總資產',
	},
	"314962-0": "Google 2FA",
	"314962-1": "下載 Google Authenticator 進行綁定",
	"314962-2": "複製驗證碼",
	"314962-3": "輸入驗證碼",
	"314962-4": "請求失敗:",
	"314962-5": "驗證碼已複製",
	"314962-6": "無法複製文字:",
	"314962-7": "複製失敗，請重試",
	"199917-0": "設定",
	"199917-1": "通知",
	"199917-2": "Google 2FA",
	"199917-3": "電子郵件",
	"199917-4": "語言",
	"199917-5": "版本",
	"199917-6": "登出",
	home: {
		USDTAccount: 'USDT 帳戶',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: '授權',
		ETHTodayProfit: 'ETH今日利潤',
		totalRevenue: '總收入',
		portfolio: '投資',
		reliableSecurity: '可靠的安全保障',
		stableReliable: '投資穩定可靠',
		convenienEasy: '操作方便簡單',
		pleaseLookForward: '敬請期待...',
		rateOfReturn: '回報率',
		walletnot: '請安裝 MetaMask 或任何以太坊擴充錢包',
		wallettip: '請綁定您的地址，以免影響體驗',
	},
	"516529-0": "賬戶設置",
	"516529-1": "了解你的賬戶信息",
	"516529-2": "在線支持",
	"516529-3": "分享應用",
	"516529-4": "基礎設置",
	"516529-5": "郵箱設置",
	"516529-6": "更換語言",
	"516529-7": "價差提醒",
	"516529-8": "法律&amp;信息",
	"516529-9": "服務條款",
	"516529-10": "防洗錢政策",
	"516529-11": "Cookie 政策",
	"516529-12": "獲取疑難問題解答和幫助",
	"516529-13": "入門",
	"516529-14": "交換代幣",
	"516529-15": "故障排除",
	"516529-16": "推薦給朋友",
	"516529-17": "與朋友分享並獲得推薦佣金",
	"516529-18": "拷貝您的鏈接",
	"516529-19": "設置您的郵箱，接收市場動態",
	"516529-20": "立即驗證",
	"516529-21": "輸入郵箱...",
	"516529-22": "輸入驗證碼...",
	"516529-23": "您的訂閱郵箱將僅用於接收與服務相關的有用信息或者提醒，不會被濫用！",
	"516529-24": "保存",
	"516529-25": "選擇您需要的網站語言",
	"516529-26": "我們致力於保護您的數據安全和隱私",
	"516529-27": "中文繁體",
	"516529-28": "日本語",
	"516529-29": "發送驗證碼",
	"516529-30": "复制成功!",
	"516529-31": "重新发送",
	"ConnectW": "連接錢包",
	"dappbrowser": "本網站僅接受去中心化錢包DAPP瀏覽器的訪問。",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "賺取利息",
	"$": "$",
	"ETH Today's Profit": "ETH今日利潤",
	"Wallet": "錢包",
	"Record": "訂單",
	"Support": "客服",
	"General": "綜合",
	"Notifications": "通知",
	"Invite Friends": "邀請朋友",
	"FAQ": "常見問題",
	"Privacy & Security": "隱私與安全",
	"Legality": "隱私權政策",
	"Authenticator": "實名認證",
	"Settings": "設定",
	"Change Language": "改變語言",
	"Daytime mode": "日間模式",
	"Black mode": "夜間模式",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

	// marketInfo.vue
	'Open': '開盤',
	'High': '最高',
	'Low': '最低',
	'Close': '閉盤',
	'Up': '買漲',
	'Down': '買跌',
	'Time': '時間',
	'TransactionFee': '手續費',
	'AvailableBalance': '可用餘額',
	'submit': '訂閱',
	'Direction': '方向',
	'Profit': '利潤',
	'Quantity': '數量',
	'Price': '價格',
	'different': '點擊不同比例可顯示最低限制',
	'lessthan': '至少不能小於',
	'least': '最少：',
	'Transactionhistory': '交易記錄',
	'Transactionrecords': '交易記錄',
	'Number': '數量',
	'Profitloss': '利潤損失',
	'Ror': '回報',
	'operate': '操作',





	//钱包
	TransactiónDetails: '交易明細',
	dealdetails: '交易詳情',
	ViewTransactionHistory: '查看交易記錄',
	Deposit: '儲值',
	Rechargeaddress: '儲值地址：',
	DepositAmount: '存款金額：',
	EnterDepositAmount: '輸入存款金額',
	EnterHash: '輸入哈希值',
	Hash: '哈希值：',
	Voucherimage: '憑證影像：',
	Continue: '繼續',
	Withdraw: '提現',
	Withdrawal: '取回：',
	EnterAmounttoWithdraw: '輸入提款金額',
	Available: "可用的：",
	ReceiveAddress: '接收地址：',
	EnterWalletAddress: '輸入錢包位址',
	WithdrawalDesc: '以英文提現需要1%的手續費，需要網路節點確認後才能到帳，請勿將加密貨幣轉給陌生人',
	Exchange: '兌換',
	Send: '傳送:',
	Max: '最大限度:',
	rechargeAmount: '請輸入儲值金額',
	hashcode: '哈希值和截圖不能同時為空',
	correcthashcode: '請輸入正確的哈希值',
	maximumwithdrawalamount: '最大提款金額為',
	Incorrectamountformat: '金額格式不正確',
	copy: '複製',


	//tab2
	"Total Profit": "利潤總額",
	"Today's Profit": "今日利潤",
	"What is Automated Trading?": "什麼是自動交易？",
	"Learn about earning": "了解收入",
	"Staking Period": "質押期",
	"Staking Amount": "質押數量",
	"Yield": "效益",
	"Limit": "限額",
	"Subscribe": "訂閱",

	//tab3
	"Proof of Stake": "權益證明",
	"ETH Today is Price": "ETH 今日價格",
	"ETH Today's Increase": "ETH今日漲幅",
	"Joint Staking": "聯合質押",
	"Individual Staking": "個人質押",
	"tab3long1": "透過智能合約配對參與者，實現聯合下注，總下注金額達到32 ETH。",
	"tab3long2": "獨立下注不需要透過智能合約來配對參與者。 達到32 ETH的下注金額，即可完成個人下注",


	//web_view
	"webviewlong1": "加密貨幣空投有助於新興的基於區塊鏈的項目的發展。 他們的根源透過獎勵和共同目標將社區成員聯繫在一起。 成功空投的結果是更強大的社群、更好的專案以及對最終用戶的獎勵。 多年來，我們看到許多空投是在工作量證明（POW）區塊鏈上進行的。 股權證明（POS）以太坊鏈現在支援數十個網路中的數百個項目加密貨幣空投。 隨著 PoS 成為主導共識範式，以太坊網路上的空投數量並沒有顯示出放緩的跡象。",
	"webviewlong2": "透過質押您的加密貨幣，您有資格根據您質押的加密貨幣數量解鎖有價值的空投獎勵。",
	"webviewlong3": "獎勵股權只提供給權益持有者，它來自數百個不同的專案空投，其收益率遠高於任何一個獨立的空投。",
	"webviewlong4": "對於在交易所存入的加密貨幣所有者來說，空投獎勵通常很困難，而且有些託管人根本不支援空投。 這就是為什麼管理自己的加密貨幣很重要。 以太坊是一個非託管實體，可以在所有主要網路上進行質押",
	"Introduction": "介紹",
	"webviewlong5": "領先的區塊鏈平台以太坊正在透過以太坊2.0升級從工作量證明（PoW）共識機制過渡到權益證明（PoS）機制。 PoS 質押是這項轉變的重要組成部分，為用戶提供支援網路安全和營運的機會，同時有可能獲得獎勵。 本文概述了以太坊 PoS 質押及其好處。",
	"Ethereum 2.0 and Proof of Stake (PoS)": "以太坊 2.0 和權益證明 (PoS)",
	"webviewlong6": '以太坊 2.0 是一項重大網路升級，旨在提高可擴展性、安全性和永續性。 關鍵的變化之一是從能源密集的 PoW 共識機制轉向更環保的 PoS 機制。 在 PoS 中，驗證者被選擇創建新區塊並根據他們持有的加密貨幣數量並願意「抵押」作為抵押品來確認交易。',
	"The Staking Process": "質押過程",
	"webviewlong7": "要參與以太坊 PoS 質押，用戶必須透過將其存入以太坊 2.0 存款合約來質押至少 32 ETH。 一旦質押，ETH 就會被鎖定一段時間，並作為確保網路安全的抵押品。 驗證者是根據抵押的 ETH 數量及其線上活動來選擇的。 他們負責提議和驗證新區塊，以及確認網路上的交易。",
	"Staking Rewards and Risks": "質押獎勵和風險",
	"webviewlong8": "透過參與 PoS 質押，用戶可以獲得新鑄造的 ETH 和交易費用形式的獎勵。 獎勵基於質押的 ETH 數量和整體網路活動。 然而，質押也伴隨著風險，例如，如果驗證者行為惡意或未能持續保持在線，則可能會受到處罰。 此外，質押的 ETH 會被鎖定一段時間，使其缺乏流動性並容易受到價格波動的影響。",
	"Joint Staking and Individual Staking": "聯合質押與個人質押",
	"webviewlong9": "對於沒有足夠 32 ETH 單獨質押的用戶來說，聯合質押提供了另一種選擇。 在聯合質押中，多個用戶將他們的 ETH 集中在一起以達到所需的質押金。 這種池化通常透過智能合約或質押服務來促進，允許用戶以較小數量的 ETH 參與以太坊 PoS 質押。",
	"Conclusion": "結論",
	"webviewlong10": "以太坊 PoS 質押是以太坊 2.0 升級的一個重要方面，為用戶提供支援網路安全、改善去中心化和賺取獎勵的機會。 透過了解質押過程、其回報和風險，用戶可以就參與以太坊網路的這一關鍵演變做出明智的決定。",


	//mywallet
	"Total": "總額",

	//record
	"Account": "帳戶",
	"interest-bearing": "利息",
	"POS Staking": "POS 質押",
	"convert": "兌換",
	"transaction": "交易",
	"pledge": "抵押",
	"recordno": "你還沒有任何消息。 該列表為空。",
	"Oops": "哎呀",
	"recharge": "儲值",
	"payment": "支付",
	"staking": "質押",
	"income": "收益",
	"Pledge quantity": "質押數量",
	"cycle": "週期",
	"Days Remaining": "剩餘天數",
	"cumulative gain": "累積增益",
	"Price increase": "價格上漲",
	"Price decrease": "價格下降",
	"Opening price": "開盤價",
	"Closing price": "收盤價",
	"loss": "損失",
	"pfofit": "利潤",
	"amount": "數量",

	//notifiCations
	"notifiCationslong1": "註：VIP註冊是根據對應整體帳戶的儲值金額來決定您的等級。 此區間需扣除現有帳戶提領後的總儲值金額來決定會員等級。",
	"notifiCationslong2": "會員期限也根據不同等級進行劃分。 為維持您的會員資格，您需要在規定時間內每次充值至少5,000 USDT才能享有會員權益。 每次儲值會員資格後，週期重新計算並可累積。",
	"notifiCationslong3": "客戶服務依會員等級進行劃分。 官方客服由APP工作人員提供，為廣大會員提供統一服務。 專屬秘書數量有限，保證更好的服務。 專屬私人秘書為使用者提供專屬接待服務和理財建議。",
	"notifiCationslong4": "提前聯絡客服預約儲值。 在規定時間內完成儲值，可依對應會員等級額外獲得預約儲值獎勵。",
	"notifiCationslong5": "VIP1：基於UID帳戶，累計儲值10,000 USD，可獲得177 USDT獎勵。 達到VIP等級並獲得普通會員勳章後，積分將空投至帳戶。",
	"notifiCationslong6": "VIP2：基於UID帳戶，累計儲值達30,000 USD，送777 USDT。 獎金金額將在達到VIP等級並獲得銅牌會員勳章後立即存入帳戶。",
	"notifiCationslong7": "VIP3：根據UID帳戶，累計儲值7萬美元，即可獲贈1777 USDT。 達到VIP等級並獲得銀卡會員勳章後，積分將空投至帳戶。 此外，您將在下次預訂儲值金額的基礎上獲得額外1.5%的獎勵。",
	"notifiCationslong8": "VIP4：根據UID帳戶，累計儲值15萬美元，獲贈2,777 USDT。 達到VIP等級並獲得金牌會員勳章後，積分將空投至帳戶。 此外，您將在下次預訂儲值金額上獲得額外 2% 的獎勵。",
	"notifiCationslong9": "VIP5：基於UID帳戶，累計儲值達300,000 USD，贈送4777 USDT。 達到VIP等級並獲得鑽石VIP徽章後，積分將空投至帳戶。 此外，下次預訂儲值金額還將額外獲得2.5%的獎勵。",
	"notifiCationslong10": "VIP6：基於UID帳戶，累計儲值達800,000 USD，贈送16,888 USDT。 獎金金額將在達到VIP等級並獲得黑鑽會員徽章後立即空投至帳戶，並額外獲得下次預登記充值金額的3%。 您將有一名私人專屬秘書來提供任何財務建議或處理稅務問題。",
	"notifiCationslong11": "VIP7：基於UID帳戶，累計儲值金額達300萬美元，贈送15天歐洲豪華之旅（有折扣）。 授予CoinBpos全球終身榮譽VIP，並獲得皇冠會員勳章。 下次預約儲值將可額外獲得3.5%的獎勵。",
	"notifiCationslong12": "VIP8：根據UID帳戶，累計儲值達800萬美元，可獲得CoinBpos摩洛哥年宴禮券一張。 獎勵：1 BTC，並獲得CoinBpos全球終身榮譽VIP。 下次預約儲值可額外獲得4%獎勵。",
	"notifiCationslong13": "VIP9：基於UID帳戶，累計入3000萬美元，即可獲得CoinBpos 3%的股份。 並享有每年3%的利潤分紅。 免費贈送 Cronix 純金紀念徽章。",

	//invite
	"Refer and Earn": "推薦並賺取",
	"Copy Link": "複製連結",

	//faq
	"Frequently Asked Questions": "經常問的問題",

	//authenticator
	"mailbox": "信箱",
	"Front photo": "正面照片",
	"Back photo": "背面照片",
	"Handheld ID photo": "手持證件照",
	"define": "確定",

	//legality
	"legalitylong1": "Coinbpos由多家加密貨幣公司組成，他們共同提供工具來幫助加密社群創建、發展、維護社群穩定，為數以千計的去中心化應用程式提供動力，推動加密貨幣的未來以及推動傳統貨幣的數位化和促進全球經濟繁榮。",
	"legalitylong2": "Coinbpos 內的每家公司均充當處理與其服務相關的個人資料的資料控制者，每項服務的資料控制者是：",
	"legalitylong3": "1.區塊鏈技術－以太坊區塊鏈。",
	"legalitylong4": "2.去中心化錢包-Trust錢包、MetaMask和加密錢包。",
	"legalitylong5": "3. 交易技術-Coinbase、Crypto、linch、Binance。",
	"legalitylong6": "隱私政策詳細說明了Coinbpos如何根據其在相關數據保護法律下的義務處理個人數據，包括歐盟的《通用數據保護條例》（GDPR），",
	"legalitylong7": '《加州消費者隱私法》（CCPA）和其他一般法律（統稱為「資料保護法」*）。',

	//language
	"language": "語言",
	"Confirm": "確認",

	"newtext1": "退出",
	"newtext2": "取消",
	"newtext3": "確定退出此帳號嗎？",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "登錄",
	"newtext9": "用戶名",
	"newtext10": "手機號",
	"newtext11": "請輸入用戶名",
	"newtext12": "請輸入手機號",
	"newtext13": "密碼",
	"newtext14": "請輸入密碼",
	"newtext15": "還沒有帳戶",
	"newtext16": "注冊",
	"newtext17": "所在國家",
	"newtext18": "選擇國家",
	"newtext19": "手機號驗證碼",
	"newtext20": "請輸入驗證碼",
	"newtext21": "郵箱地址",
	"newtext22": "請輸入郵箱地址",
	"newtext23": "確認密碼",
	"newtext24": "請輸入確認密碼",
	"newtext25": "擁有一個帳戶",
	"newtext26": "發送驗證碼",
	"newtext27": "手機號不正確",
	"newtext28": "發送成功",
	"newtext29": "注冊成功",

	"newtext30": '貨幣',
	"newtext31": '外匯',
	"newtext32": '貴金屬',

	"newtext33": '賬戶',
	"newtext34": '平臺活動',
	"newtext35": '在线客服',
	"newtext36": '邀請',
	"newtext37": 'KYC 驗證',
	"newtext38": '設置',
	wallet_p: {
		text1: "複製位址",
		text2: '確認',
		text3: '從...交換',
		text4: "交換至",
		text5: "最大",
		text6: "邀請",
		text7: '獲得推薦獎勵',
		text8: "當你的朋友參與AI套利並租用礦機時，你可以獲得推薦獎勵",
		text9: "複製",
		text10: '分享連結',
		text11: "KYC驗證",
		text12: "國家",
		text13: "請選擇一個國家",
		text14: "名字",
		text15: "請輸入您的名字",
		text16: "姓氏",
		text17: "請輸入您的姓氏",
		text18: "證書類型",
		text19: "請選擇證書類型",
		text20: "證書號碼",
		text21: "請輸入證書號碼",
		text22: "請上傳您身份證的正面照片",
		text23: "請上傳您身份證的反面照片",
		text24: "請上傳您護照的正面照片",
		text25: "請上傳您護照的反面照片",
		text26: "電子郵件",
		text27: "請輸入您的電子郵件",
		text28: "發送",
		text29: "驗證碼",
		text30: "請輸入您的驗證碼",
		text31: "身份證",
		text32: "護照",
		text33: "取消",
		text34: "國家/地區",
		text35: "請輸入正確的信息以進行驗證提交",
		text36: "成功發送！",
		text37: "電子郵件格式錯誤！",
		text38: "駕照",
		text39: "請上傳您駕照的正面照片",
		text40: "請上傳您駕照的反面照片",

		text41: "身份證號碼",
		text42: "請輸入身份證號碼",
		text43: "護照號碼",
		text44: "請輸入護照號碼",
		text45: "駕照號碼",
		text46: "請輸入駕照號碼",
	},
	jiaoyi: {
		text1: '秒合約',
		text2: '永續合約',
		text3: '現貨交易',
		text4: '立即委託',
		text5: '帳戶類型',
		text6: '交貨時間',
		text7: '價格區間',
		text8: '購買價格',
		text9: '費用',
		text10: '余额',
		text11: '至少',
		text12: '預期',
		text13: '購買金額',
		text14: '方向',
		text15: '購買價格',
		text16: '即時價格',
		text17: '倒數計時',
		text18: '我的合約',
		text19: '利潤',
		text20: '合約',
		text21: '交貨價格',
		text22: '交貨時間',
		text23: '市價',
		text24: '涨',
		text25: '跌',
		text26: '購買價格',
		text27: '最大',
		text28: '利潤',
		text29: '手續費',
		text30: '買入',
		text31: '賣出',
		text32: '購買數量',
		text33: '真實帳戶',
		text34: '虛擬帳戶',
		text35: '暫無數據',
		text36: '當前持倉',
		text37: '歷史委託',
		text38: '平倉',
		text39: '保證金',
		text40: '盈虧',
		text41: '持有',
		text42: '買進紀錄',
		text43: '賣出記錄',
		text44: '可用',
		text45: '折合',
		text46: '認證成功',
		text47: '購買時間',
		text48: '未讀',
		text49: '已讀',
		text50: '托管訂單',
		text51: '總套利',
		text58: '今日收益',
		text52: '人工智慧機器人是如何工作的',
		text53: '套利產品',
		text54: '天',
		text55: '金額',
		text56: '每日收入',
		text57: '貨幣類型',
		text59: '加入AI套利',
		text60: '零風險，快速回報',
		text61: 'AI套利',
		text62: '預期收益',
		text63: '套利硬幣類型',
		text64: '托管金額',
		text65: '立即托管',
		text66: '每日收入發送到您的USDT錢包',
		text67: '投資基金零風險',
		text68: '您可以隨時取回資金',
		text69: '人工智能每天24小時工作',
		text70: '累計收益',
		text71: '到期時間',
		text72: '全部',
		text73: '進行中',
		text74: '已完成',
		text75: '託管詳情',
		text76: '選擇幣種',
		text77: '技術支持',
		text78: '發送郵件',
		text79: '交易類型',

		text80: '載入中...',
		text81: '沒有更多了',
		text82: '白皮書',
	},
	
	swapadd:{
			text1:'Cookies 政策',
			text2:'我們致力於保護您的數據安全和隱私',
			text3:'引言',
			text4:'本Cookies政策解釋了我們的去中心化交易所如何使用Cookies和類似的跟踪技術。通過訪問或使用本交易所，您同意按照本政策中所述使用Cookies。',
			text5:'什麼是Cookies？',
			text6:'Cookies是在您訪問網站時放置在您設備（計算機、手機或平板電腦）上的小型文本文件。它們被廣泛用於增強您的瀏覽體驗，並在網站上提供個性化功能。 Cookies可以是會話Cookies（臨時的，在關閉瀏覽器時被刪除）或持久Cookies（在您的設備上保存更長時間）。',
			text7:'我們如何使用Cookies',
			text8:'交易所使用Cookies的目的包括但不限於：',
			text9:'啟用交易所的基本功能和特性',
			text10:'分析和改進交易所的性能和功能',
			text11:'個性化您的體驗，並記住您的偏好設置',
			text12:'跟踪和了解您在交易所上的使用模式',
			text13:'提供相關的廣告和營銷通訊',
			text14:'我們使用的Cookies類型',
			text15:'a) 基本Cookies：這些Cookies對於交易所的運作是必要的，使您能夠訪問和使用其特性。如果沒有這些Cookies，某些服務可能對您不可用。',
			text16:'b) 分析Cookies：這些Cookies收集關於您使用交易所的方式的信息，例如您訪問的頁面和遇到的任何錯誤。我們使用這些信息來分析和改進交易所的性能和功能。',
			text17:'c) 功能Cookies：這些Cookies允許交易所記住您的選擇（例如語言偏好）並提供增強的、更個性化的功能。',
			text18:'d) 廣告Cookies：這些Cookies用於提供與您和您的興趣相關的廣告。它們還可能用於限制您看到廣告的次數，並衡量廣告活動的有效性。',
			text19:'第三方Cookies',
			text20:'交易所可能允許第三方服務提供商在您的設備上放置Cookies，用於各種目的，包括分析和廣告。這些第三方擁有自己的隱私政策，並可能收集有關您在不同網站或在線服務上的在線活動的信息。',
			text21:'Cookies管理',
			text22:'您可以隨時通過瀏覽器設置管理或刪除Cookies。然而，請注意，禁用或刪除某些Cookies可能會影響交易所的功能和性能。',
			text23:'同意',
			text24:'通過使用交易所，您同意按照本政策所述使用Cookies。如果您不同意使用Cookies，您可以通過瀏覽器設置禁用或刪除它們，或者不使用交易所。',
			text25:'更新政策',
			text26:'我們可能會定期更新本Cookies政策以反映我們的做法變化或適用法律的變化。我們建議您定期查看本政策以獲取最新信息。',
			text27:'如果您對我們使用Cookies的方式有任何疑問或關注，請聯繫我們的客戶支持團隊。',
			
			text28:'獲取疑難問題解答和幫助',
			text29:'入門',
			text30:'交換代幣',
			text31:'故障排除',
			text32:'什麼是去中心化交易所？',
			text33:'DEX是一種加密貨幣交易所，在去中心化的區塊鍊網絡上運行，通常建立在智能合約上,與傳統的中心化交易所不同，CEX依靠中央機構來促進交易並持有用戶資金，DEX可以通過智能合約直接與交易雙方進行交易。',
			text34:'去中心化交易所安全性',
			text35:'DEX使用區塊鏈上的智能合約運作，允許買家和賣家之間直接交易，消除了三方機構的需要。 DEX在公共區塊鏈上運行，交易是透明和可審計的，任何人都可以檢查，以驗證DEX的公平性和完整性。',
			text36:'什麼是去中心化錢包？',
			text37:'去中心化錢包是一個由12個密鑰組成的加密貨幣錢包。用戶可以通過12個密鑰在任何同一個區塊鏈上使用密鑰登錄錢包來訪問數字資產，只要密鑰不洩露，任何人都無法獲取去中心化錢包中的數字資產',
			text38:'什麼是錢包地址？',
			text39:'加密貨幣錢包地址是通過橢圓曲線簽名算法由私鑰獲得公鑰，公鑰通過一系列的轉換得到的錢包地址，轉換過程中採用不可逆的哈希運算，錢包地址主要用於發送和接收加密貨幣。',
			text40:'使用DEX兌換資金的安全性',
			text41:'DEX的運行機制建立在區塊鏈上，不經過任何三方機構，採用非託管制度直接兌換，所有交易都採用智能合約運行，所有交易都可以在區塊鏈上查詢，真正實現了資產透明、隱私和安全。',
			text42:'在交易所中相同代幣為什麼會存在差價？',
			text43:'市場供需：交易所提供資產的購買和出售，但是由於各大交易所市場供應和需求不同，導致該交易所設定出售和購買的價格會發生變化，對於某種代幣需求較高的情況下通常價格會高於市場價，需求量較低的交易所則價格會低。',
			text44:'交易量：在交易所買入和賣出的加密貨幣的數量，可以影響價格。較高的交易量通常表明有更多的市場活動，可以促進更準確的價格發現。交易量低的交易所可能有更大的買賣價差和更不准確的定價。',
			text45:'流動性：指的是在不引起重大價格變動的情況下購買或出售資產的簡單和困難出售程度。流動性較高的加密貨幣，其價格在不同交易所之間往往更加一致。當某一交易所的流動性低時，會導致價格波動和價格差異較大。',
			text46:'代幣選擇：不是所有的代幣都有相同的獲利潛力,不同的代幣可能有不同程度的流動性、交易量和價格波動性.',
			text47:'什麼是匯率差價？',
			text48:'匯率差異是指不同交易所之間加密貨幣價格的變化。由於加密貨幣在全球範圍內分散並在各種平台上交易，因此交易所之間的供需動態、流動性、交易量和市場條件可能存在差異。',
			text49:'什麼是DEX兌換？',
			text50:'DEX兌換也稱為代幣兌換或資產兌換，是指通過DEX上的兌換協議，將一種加密貨幣或代幣兌換成另一種加密貨幣或代幣的過程。 這些交易通常由區塊鏈上的智能合約促成，無需中介。',
			text51:'為什麼在各大交易所中差價消失了？',
			text52:'當不同交易所的代幣價格沒有差異，代表市場是相對穩定，導致這種情況出現的原因是，市場交易員根據不平衡交易進行交易持平，自動套利，市場巨鱷參與交易利用大量資金進行交易套利，使市場代幣價格保持持平，交易量和流動性，較高的交易量和足夠的流動性，和市場整合，這些都是導致在交易所中的差價消失的原因',
			text53:'什麼是指定地址？',
			text54:'指定地址是用戶在執行兌換成功以後，需要填寫加密貨幣接收地址，首次進行兌換的用戶需要綁定常用接收地址',
			text55:'什麼是價格滑點？',
			text56:'價格滑點，也被稱為滑點，在加密貨幣交易的預期價格和交易實際執行的價格之間的差異。導致價格滑點通常是因為市場波動，流動性，訂單大小，訂單類型等原因，在加密貨幣這樣快速流動和波動的市場，價格可能迅速變化。',
			text57:'什麼是交換GAS？',
			text58:'GAS是用戶在兌換成功後提取加密貨幣時需要向區塊鏈支付GAS費用，支付GAS費用是用於處理用戶訂單，同時在區塊鏈中廣播確認用戶交易成功。通常提取兌換資產GAS費用為0.1%。',
			text59:'為什麼會有待處理訂單？',
			text60:'網絡擁堵： 在高需求或活動增加的時期，區塊鍊網絡會變得擁擠。這種擁堵會導致交易處理時間變慢，費用增加。在市場大幅波動或交易活動激增時會導致網絡擁堵。',
			text61:'可擴展性問題： 部分區塊鍊網絡面臨可擴展性挑戰，這意味著區塊鏈可能無法快速有效地處理大量的交易。這可能導致延遲和較慢的交易速度。',
			text62:'流動性的限制： 市場上買家和賣家的可用性。在部分加密貨幣市場或特定加密貨幣，流動性是有限的，這種流動性的缺乏可能會導致交易速度減慢，以特定價格買入或賣出的參與者可能較少，導致買賣價差擴大，訂單執行速度減慢。',
			text63:'市場訂單大小： 訂單的大小會影響交易速度，特別是當它是相對於可用流動性的大訂單時。當訂單與市場上的可用供應或需求相互作用時，發出大量的買入或賣出訂單可能會導致部分成交或執行速度減慢，導致訂單待處理。',
			text64:'待處理訂單中資產是否安全？',
			text65:'待處理訂單中資金不會在加密市場丟失，當交易訂單待處理時，需要等待區塊鏈匹配訂單，一旦訂單匹配交易會立刻執行。',
			text66:'加速處理訂單是什麼？',
			text67:'由於在交易方發起訂單時，已經不存在供需關係，導致在區塊鏈中無法匹配相應訂單，用戶可以選擇增加交易資金獲取以新的供需關係完成待處理訂單交易。',
		},
	login: {
		Get_Started: '開始使用',
		Best_Swap: '世界上最好的互換平台',
		Decentralized_cryptocurrency: '去中心化的加密貨幣交易平台，實現不同數位資產之間的快速無縫交換！',
		Cookies_Privacy: 'Cookie 隱私',
		We_use_cookies: '我們使用 cookie 來增強您的瀏覽體驗、分析網站流量並提供個人化內容。繼續使用本網站即表示您同意我們使用 cookie！',
		Accept: '接受',
		Sum: '相信',
		Swap: '交換',
		Balance: '平衡',
		Name: '名字',
		Price_Change: '價格變化',
		Sort_Type: '排序類型',
		Add_Asset: '新增資產',
		Add_the_cryptocurrency: '新增您需要的加密貨幣資產',
		Current_Market_Conditions: '目前的市場狀況',
		Market: '市場',
		Overview: '概述',
		Coin_Information: '硬幣資訊',
		Market_Value: '市場價值',
		Total_Supply: '總供應量',
		In_Circulation: '流通中',
		Trading_Volume: '交易量',
		Official_Website: '官方網站',
	},
	swap: {
		title1: "交易記錄",
		title2: "查看您的歷史操作記錄",
		title3: "轉賬",
		title4: "批准",
		title5: "所有幣種",
		title6: "哎呀！",
		title7: "您沒有記錄。",
		title8: "列表是空的。",
		title9: '選擇幣種',
		title10: "快速選擇加密貨幣類型",
		title11: "全部",
		title12: "所有幣種",
	},
	policy:{
		text1:"反洗錢政策",
		text2: "我們致力於保護您的數據安全和隱私。",
		text3: "我們的去中心化交易所嚴格遵守適用的反洗錢（AML）和打擊恐怖主義融資（CFT）法律、法規和指導方針。我們承諾採取一切合理的措施，防止交易所被用於洗錢、恐怖主義融資或任何其他非法活動。",
		text4: "客戶身份驗證",
		text5: "為了確保交易所的安全性和合規性，我們要求所有用戶進行身份驗證。用戶必須提供準確、完整和最新的個人身份證明信息並提交支持文件作為證明。我們保留根據需要請求額外信息或文件的權利。",
		text6: "交易活動監控",
		text7: "交易所將監控和分析用戶的交易活動，以識別任何可疑或異常活動。如果我們發現任何洗錢、恐怖主義融資或其他非法行為的跡象，我們將採取適當行動，並可能向相關當局報告。",
		text8: "交易限制和報告要求",
		text9: "為了遵守反洗錢和打擊恐怖主義融資法律的要求，我們可能會為用戶設置交易限制，並要求對特定類型或金額的交易提供額外信息或報告。用戶必須配合並及時提供所需的信息。",
		text10: "合作與報告",
		text11:"如果交易所識別到任何可疑活動或有理由相信用戶涉及洗錢、恐怖主義融資或其他非法活動，我們將積極與執法機構和相關當局合作，並根據適用法律的要求進行報告。",
		text12: "員工培訓和合規控制",
		text13: "我們定期對員工進行反洗錢和打擊恐怖主義融資的培訓，以確保他們了解相關法律、法規和內部合規控制。我們還建立和維護內部程序和控制措施，以確保我們的運作符合適用的反洗錢和打擊恐怖主義融資要求。",
		text14: "用戶合規責任",
		text15: "作為用戶，您有責任遵守適用的反洗錢和打擊恐怖主義融資法律和法規。您必須確保您的資金來自合法來源，並避免從事任何洗錢、恐怖主義融資或其他非法活動。如果您發現任何可疑活動或有任何與洗錢相關的疑慮，應立即向交易所報告。",
		text16: "請注意，交易所保留在適用法律和法規的規定下隨時修改或更新反洗錢政策的權利。",
		text17: "如果您有任何問題或需要進一步澄清，請聯繫我們的客戶支持團隊。"
	},
	Service: {
		text1: "服務條款",
		text2:"我們致力於保護您的數據安全和隱私。",
		text3: "在使用交易所之前，請仔細閱讀並理解以下服務條款。通過訪問或使用交易所，您確認已閱讀、理解和同意遵守這些條款。如果您不同意這些條款的任何部分，請勿使用交易所。",
		text4: "接受條款",
		text5: "通過使用交易所，您確認並同意這些服務條款構成了您與交易所之間的法律協議，並管理您的行為。如果您不同意這些條款，請勿使用交易所。",
		text6: "服務描述",
		text7: "交易所是一個去中心化平台，旨在提供數字資產的交易和交換服務。交易所僅作為交易場所，不直接參與用戶之間的交易。交易所不提供法律、財務或投資建議，也不對您的交易活動負責。",
		text8: "用戶資格",
		text9: "您必須年滿18歲（或法定成年年齡）並具有完全法律行為能力才能使用交易所。如果您代表實體或組織使用交易所，您保證有權代表該實體或組織接受這些條款。",
		text10: "註冊和賬戶安全",
		text11: "您需要註冊一個賬戶才能使用交易所。您必須提供準確、完整和最新的個人信息進行註冊，並負責保護賬戶信息的安全。您對通過您的賬戶進行的所有活動負全責。",
		text12: "使用規則",
		text13: "通過使用交易所，您同意遵守所有適用的法律、法規和規則，並遵循以下指南：",
		text14: "(a) 不違反任何適用的法律、法規或規則。",
		text15: "(b) 不從事任何非法、欺詐、誤導或誹謗活動。",
		text16: "(c) 不干擾或破壞交易所的正常運作。",
		text17: "(d) 不侵犯任何第三方的權利或利益。",
		text18: "(e) 不操縱市場或濫用交易所的服務。",
		text19: "(f) 不使用任何自動工具、腳本或其他手段干擾交易所的正常運作。",
		text20: "(g) 不傳播任何垃圾郵件、垃圾信息或未經請求的消息。",
		text21: "風險披露",
		text22: "數字資產交易涉及風險，包括但不限於市場風險、技術風險和安全風險。您必須仔細評估自己的風險承受能力，並決定是否參與交易。交易所對您遭受的任何投資損失不承擔責任。",
		text23: "費用和收費",
		text24: "交易所可能會對您使用交易所服務收取費用和收費。您同意支付適用的費用和收費，並理解這些費用和收費可能會隨時變更。",
		text25: "隱私政策",
		text26: "交易所將根據適用的隱私法律保護您的個人資訊。請審閱我們的隱私政策，以了解我們如何收集、使用和保護您的個人資訊。",
		text27: "知識產權",
		text28: "交易所及其相關內容，包括但不限於商標、標誌、圖形、文本和軟件，均為交易所的財產，並受適用的知識產權法律保護。未經交易所書面許可，您不得使用、複製、修改、分發或展示交易所的任何內容。",
		text29: "免責聲明",
		text30: "交易所對因使用交易所而產生的任何直接或間接損失不承擔責任，包括但不限於交易損失、數據損失、業務中斷或其他經濟損失。",
		text31: "終止",
		text32: "交易所保留隨時終止、暫停或限制您訪問交易所的權利。交易所還保留隨時修改或更新這些服務條款的權利。您應定期審閱這些服務條款，以獲取最新信息。",
		text33: "其他",
		text34: "這些服務條款構成了您與交易所之間關於交易所的全部協議，並取代您與交易所之間的所有先前口頭或書面協議。如果這些服務條款的任何條款被認為無效或不可執行，其餘條款仍有效。",
		text35: "如果您有任何問題或疑慮，請聯繫我們的客戶支持團隊。"
	},
	swap_change: {
		text1: "交換",
		text2: "實現各種資產的快速無縫交換。",
		text3: "您支付",
		text4: "餘額",
		text5: '概覽',
		text6: "滑點影響率 網絡費",
		text7: "確認交易",
		text8: "市場供給和需求",
		text9: '您獲得',
		text10: "快速完成數字資產轉移。",
		text11: "您發送",
		text12: "輸入接收地址...",
		text13: "輸入發送金額...",
		text15: "發送",
		text14: '發送前，請確認接收地址正確。輸入錯誤的地址可能會導致永久損失。',
		text16:'選擇貨幣',
		text17:'同種幣種不能兌換',
	}
}
