export default {
	account: {
		Start_making_money_plan: "Inizia a fare soldi pianificando",
		Market: "Mercato",
		Migital_currency: "Valuta Migitale",
		Foreign_exchange: "Cambio valuta",
		Noble_metal: "Metallo nobile",
		Send_Cryop_Now: "Invia criopreservazione ora",
		Send: "Inviare",
		Receive: "Ricevere",
		Buy: "Acquistare",
		Currency_account: "Conto valutario",
		Contract_account: "Conto contrattuale",
		Transfer: "Trasferimento",
		Select_a_wallet: "Seleziona un portafoglio",
		Select_Coin: "Seleziona moneta",
		Confirm: "Confermare",
		Wallet: "Portafoglio",
		Availavle: "Disponibile",
		Frozen: "Congelato",
		Convert: "Convertire",
		Receiving_Address: "Indirizzo di ricezione",
		Amount: "Quantità",
		Max: "Massimo",
		Send_Now: "Spedisci ora",
		Please_check_if: "Si prega di verificare se l'indirizzo di consegna è corretto prima dell'invio per evitare perdite di beni.",
		new1:'criptovaluta',
		new2:'indice',
		new3:'Massa',
		new4:'Forex',
		new5:'saldo totale',
	},
	"314962-0": "Google 2FA",
	"314962-1": "Scarica Google Authenticator per collegare",
	"314962-2": "Copia il codice di verifica",
	"314962-3": "Inserisci il codice di verifica",
	"314962-4": "Richiesta fallita:",
	"314962-5": "Codice di verifica copiato",
	"314962-6": "Impossibile copiare il testo:",
	"314962-7": "Copia fallita, riprova",
	"199917-0": "Impostazioni",
	"199917-1": "Notifiche",
	"199917-2": "Google 2FA",
	"199917-3": "Email",
	"199917-4": "Lingua",
	"199917-5": "Versione",
	"199917-6": "Esci",
    home:{
        USDTAccount: 'USDT Account',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: 'Open',
        ETHTodayProfit: 'ETH Today\'s Profit',
        totalRevenue: 'Total Revenue',
        portfolio: 'Portfolio',
        reliableSecurity: 'Reliable Security Guarantee',
        stableReliable: 'Stable and Reliable Investment',
        convenienEasy: 'Convenien and Easy Operation',
        pleaseLookForward: 'Please look forward to...',
        rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
    },
	"ConnectW":"Connect Wallet",
	"dappbrowser":"This website only accepts access from decentralized wallet DAPP browsers.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "Earn Interest",
    "$": "$",
    "ETH Today's Profit": "ETH Today's Profit",
    "Wallet": "Wallet",
    "Record": "Record",
    "Support": "Support",
    "General": "General",
    "Notifications": "Notifications",
    "Invite Friends": "Invite Friends",
    "FAQ": "FAQ",
    "Privacy & Security": "Privacy & Security",
    "Legality": "Legality",
	"Authenticator":"Authenticator",
	"Settings":"Settings",
	"Change Language":"Change Language",
	"Daytime mode":"Daytime mode",
	"Black mode":"Black mode",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',





    //钱包
    TransactiónDetails:'Transactión Details',
	dealdetails:'deal details',
    ViewTransactionHistory:'View Transaction History',
    Deposit:'Deposit',
    Rechargeaddress:'Recharge address：',
    DepositAmount:'Deposit Amount：',
    EnterDepositAmount:'Enter Deposit Amount',
    EnterHash:'Enter Hash',
    Hash:'Hash：',
    Voucherimage:'Voucher image：',
    Continue:'Continue',
    Withdraw:'Withdraw',
    Withdrawal:'Withdrawal：',
    EnterAmounttoWithdraw:'Enter Amount to Withdraw',
    Available:"Available：",
    ReceiveAddress:'Receive Address：',
    EnterWalletAddress:'Enter Wallet Address',
    WithdrawalDesc:'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
    Exchange:'Exchange',
    Send:'Send:',
    Max:'Max:',
    rechargeAmount:'Please input a recharge amount',
    hashcode:'hash code and screenshot cannot be empty at the same time',
    correcthashcode:'please input a correct hash code',
    maximumwithdrawalamount:'The maximum withdrawal amount is',
    Incorrectamountformat:'Incorrect amount format',
	copy:'copy',


	//tab2
	"Total Profit":"Total Profit",
	"Today's Profit":"Today's Profit",
	"What is Automated Trading?":"What is Automated Trading?",
	"Learn about earning":"Learn about earning",
	"Staking Period":"Staking Period",
	"Staking Amount":"Staking Amount",
	"Yield":"Yield",
	"Limit":"Limit",
	"Subscribe":"Subscribe",
	"Record":"Record",

	//tab3
	"Proof of Stake":"Proof of Stake",
	"ETH Today is Price":"ETH Today is Price",
	"ETH Today's Increase":"ETH Today's Increase",
	"Joint Staking":"Joint Staking",
	"Individual Staking":"Individual Staking",
	"tab3long1":"Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2":"Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1":"Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2":"By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3":"Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4":"Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction":"Introduction",
	"webviewlong5":"Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6":'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process":"The Staking Process",
	"webviewlong7":"To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks":"Staking Rewards and Risks",
	"webviewlong8":"By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking":"Joint Staking and Individual Staking",
	"webviewlong9":"For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion":"Conclusion",
	"webviewlong10":"Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total":"Total",

	//record
	"Account":"Account",
	"interest-bearing":"interest-bearing",
	"POS Staking":"POS Staking",
	"convert":"convert",
	"transaction":"transaction",
	"pledge":"pledge",
	"recordno":"You don't have any news yet. The list is empty.",
	"Oops":"Oops",
	"recharge":"recharge",
	"payment":"payment",
	"staking":"staking",
	"income":"income",
	"Pledge quantity":"Pledge quantity",
	"cycle":"cycle",
	"Days Remaining":"Days Remaining",
	"cumulative gain":"cumulative gain",
	"Price increase":"Price increase",
	"Price decrease":"Price decrease",
	"Opening price":"Opening price",
	"Closing price":"Closing price",
	"loss":"loss",
	"pfofit":"pfofit",
	"amount":"amount",

	//notifiCations
	"notifiCationslong1":"Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2":"The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3":"Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4":"Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5":"VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6":"VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7":"VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8":"VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9":"VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10":"VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11":"VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12":"VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13":"VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn":"Refer and Earn",
	"Copy Link":"Copy Link",

	//faq
	"Frequently Asked Questions":"Frequently Asked Questions",

	//authenticator
	"mailbox":"mailbox",
	"Front photo":"Front photo",
	"Back photo":"Back photo",
	"Handheld ID photo":"Handheld ID photo",
	"define":"define",

	//legality
	"legalitylong1":"Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2":"Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3":"1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4":"2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5":"3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6":"Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7":'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language":"language",
	"Confirm":"Confirm",

	"newtext1":"Sign out",
	"newtext2":"Cancel",
	"newtext3":"Are you sure you want to log out of this account?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"Login",
	"newtext9":"username",
	"newtext10":"Mobile phone number",
	"newtext11":"Please enter user name",
	"newtext12":"Please enter your mobile phone number",
	"newtext13":"password",
	"newtext14":"Please enter password",
	"newtext15":"No account yet",
	"newtext16":"Register",
	"newtext17":"Country",
	"newtext18":"Select a country",
	"newtext19":"Mobile phone number verification code",
	"newtext20":"Please enter the verification code",
	"newtext21":"Email address",
	"newtext22":"Please enter your email address",
	"newtext23":"Confirm password",
	"newtext24":"Please enter the confirmation password",
	"newtext25":"Have an account",
	"newtext26":"Send verification code",
	"newtext27":"Mobile phone number is incorrect",
	"newtext28":"Sent successfully",
	"newtext29":"Registration successful",

	"newtext30":'currency',
	"newtext31":'Forex',
	"newtext32":'precious metals',

	"newtext33":'Conto',
	"newtext34":'Attività della piattaforma',
	"newtext35":'Servizio clienti',
	"newtext36":'invito',
	"newtext37": 'Verifica KYC',
	"newtext38":'impostazione',
	wallet_p:{
		text1: "Copia Indirizzo",
		text2: 'Conferma',
		text3: 'Scambia da',
		text4: "Scambia a",
		text5: "MAX",
		text6: "Invita",
		text7: 'Ottieni ricompense per referenze',
		text8: "Quando i tuoi amici partecipano all'arbitraggio AI e noleggiano macchine per il mining, puoi ottenere ricompense per referenze",
		text9: "copia",
		text10: 'Condividi Link',
		text11: "Verifica KYC",
		text12: "Paese",
		text13: "Seleziona un paese",
		text14: "Nome",
		text15: "Inserisci il tuo nome",
		text16: "Cognome",
		text17: "Inserisci il tuo cognome",
		text18: "Tipo di Certificato",
		text19: "Seleziona il Tipo di Certificato",
		text20: "Numero di Certificato",
		text21: "Inserisci il numero di certificato",
		text22: "Carica una foto della parte anteriore della tua carta d'identità",
		text23: "Carica una foto della parte posteriore della tua carta d'identità",
		text24: "Carica una foto della parte anteriore del tuo passaporto",
		text25: "Carica una foto della parte posteriore del tuo passaporto",
		text26: "Email",
		text27: "Inserisci la tua email",
		text28: "invia",
		text29: "Codice di verifica",
		text30: "Inserisci il tuo codice di verifica",
		text31: "ID",
		text32: "passaporto",
		text33: "annulla",
		text34: "Paese/Regione",
		text35: "Inserisci le informazioni corrette per l'autenticazione",
		text36: "Inviato correttamente!",
		text37: "Errore nel formato dell'email!",
		text38: "patente di guida",
		text39: "Carica una foto della parte anteriore della patente di guida",
		text40: "Carica una foto del retro della patente di guida",
		
		text41: "Numero di identificazione",
		text42: "Inserisci il tuo numero identificativo",
		text43: "Numero di passaporto",
		text44: "Inserisci il numero del passaporto",
		text45: "numero della patente di guida",
		text46: "Inserisci il numero della tua patente di guida",
	},
	jiaoyi:{
		text1:'Secondo contratto',
		text2:'Contratto perpetuo',
		text3:'Transazione a pronti',
		text4:'Delega immediatamente',
		text5:'Tipo di conto',
		text6:'tempi di consegna',
		text7:'Fascia di prezzo',
		text8:'Prezzo di acquisto',
		text9:'costo',
		text10:'Saldo',
		text11:'almeno',
		text12:'previsto',
		text13:'Importo di acquisto',
		text14:'direzione',
		text15:'Prezzo di acquisto',
		text16:'Prezzo in tempo reale',
		text17:'Conto alla rovescia',
		text18:'Il mio contratto',
		text19:'Profitto',
		text20:'Contratto',
		text21:'prezzo di spedizione',
		text22:'tempi di consegna',
		text23:'Prezzo di mercato',
		text24:'in aumento',
		text25:'autunno',
		text26:'Prezzo di acquisto',
		text27:'massimo',
		text28:'Profitto',
		text29:'commissione di gestione',
		text30:'Acquista',
		text31:'Vendi',
		text32:'Quantità acquistabile',
		text33:'conto reale',
		text34:'Conto virtuale',
		text35:'Ancora nessun dato',
		text36:'Posizione attuale',
		text37:'Commissione storica',
		text38:'Chiudi posizione',
		text39:'Margine',
		text40:'Profitti e perdite',
		text41:'tieni premuto',
		text42:'Record di acquisto',
		text43:'Record di vendita',
		text44:'Disponibile',
		text45:'convertito',
		text46:'Autenticazione riuscita',
		text47:'Tempo di acquisto',
		text48:'non letto',
		text49:'Leggere',
		text50:'Ordine dell\'host',
		text51:'Arbitraggio totale',
		text58:'Guadagni di oggi',
		text52:'Come funzionano i robot di intelligenza artificiale',
		text53:'prodotto di arbitraggio',
		text54:'giorno',
		text55:'Importo',
		text56:'Reddito giornaliero',
		text57:'Tipo di valuta',
		text59:'Unisciti all\'arbitraggio AI',
		text60:'Rischio zero, rendimenti rapidi',
		text61:'Arbitraggio AI',
		text62:'Guadagni attesi',
		text63:'Tipo di moneta di arbitraggio',
		text64:'Importo del deposito in garanzia',
		text65:'Ospita ora',
		text66:'Reddito giornaliero inviato al tuo portafoglio USDT',
		text67:'Fondo di investimento a rischio zero',
		text68:'Puoi ritirare i tuoi fondi in qualsiasi momento',
		text69:'L\'intelligenza artificiale funziona 24 ore al giorno',
		text70:'Reddito cumulativo',
		text71:'Tempo di scadenza',
		text72:'tutto',
		text73:'In corso',
		text74:'Completato',
		text75:'Dettagli dell\'hosting',
		text76:'Seleziona valuta',
		text77:'Supporto tecnico',
		text78:'Invia e-mail',
		text79:'Tipo di transazione',
	},
	
	swapadd:{
		text1:'Politica sui cookie',
		text2:'Ci impegniamo a proteggere la sicurezza e la privacy dei tuoi dati',
		text3:'Introduzione',
		text4:'La presente Politica sui cookie spiega come il nostro scambio decentralizzato utilizza i cookie e tecnologie di tracciamento simili. Accedendo o utilizzando Exchange, accetti l\'uso dei cookie come descritto nella presente Informativa. ',
		text5:'Cosa sono i cookie? ',
		text6:'I cookie sono piccoli file di testo che vengono posizionati sul tuo dispositivo (computer, cellulare o tablet) quando visiti un sito web. Sono ampiamente utilizzati per migliorare la tua esperienza di navigazione e per fornire funzionalità di personalizzazione sui siti web. I cookie possono essere cookie di sessione (che sono temporanei e vengono cancellati quando chiudi il browser) o cookie persistenti (che rimangono sul tuo dispositivo per un periodo di tempo più lungo). ',
		text7:'Come utilizziamo i cookie',
		text8: 'Gli scopi per i quali gli scambi utilizzano i cookie includono ma non sono limitati a:',
		text9:'Abilita funzioni e caratteristiche di base dell\'exchange',
		text10:'Analizzare e migliorare le prestazioni e la funzionalità dello scambio',
		text11:'Personalizza la tua esperienza e ricorda le tue preferenze',
		text12:'Traccia e comprendi i tuoi modelli di utilizzo sull\'exchange',
		text13:'Fornire comunicazioni pubblicitarie e di marketing pertinenti',
		text14:'Tipi di cookie che utilizziamo',
		text15:'a) Cookie essenziali: questi cookie sono necessari per il funzionamento dello scambio e ti consentono di accedere e utilizzare le sue funzionalità. Senza questi cookie, alcuni servizi potrebbero non essere disponibili. ',
		text16:'b) Cookie analitici: questi cookie raccolgono informazioni su come utilizzi lo scambio, come le pagine che visiti e gli eventuali errori che riscontri. Utilizziamo queste informazioni per analizzare e migliorare le prestazioni e la funzionalità di Exchange. ',
		text17:'c) Cookie di funzionalità: questi cookie consentono a Exchange di ricordare le tue scelte (come le preferenze della lingua) e fornire funzionalità avanzate e più personalizzate. ',
		text18:'d) Cookie pubblicitari: questi cookie vengono utilizzati per fornire pubblicità pertinente a te e ai tuoi interessi. Possono essere utilizzati anche per limitare il numero di volte in cui viene visualizzato un annuncio e per misurare l\'efficacia delle campagne pubblicitarie. ',
		text19:'Cookie di terze parti',
		text20:'Exchange può consentire ai fornitori di servizi di terze parti di inserire cookie sul tuo dispositivo per una serie di scopi, tra cui analisi e pubblicità. Queste terze parti hanno le proprie politiche sulla privacy e possono raccogliere informazioni sulle tue attività online su diversi siti Web o servizi online. ',
		text21:'Gestione dei cookie',
		text22:'Puoi gestire o eliminare i cookie in qualsiasi momento attraverso le impostazioni del tuo browser. Tuttavia, tieni presente che la disabilitazione o l\'eliminazione di determinati cookie può influire sulla funzionalità e sulle prestazioni dello scambio. ',
		text23:'Accetto',
		text24:'Utilizzando Exchange, accetti l\'uso dei cookie come descritto in questa policy. Se non acconsenti all\'utilizzo dei cookie, puoi disattivarli o eliminarli tramite le impostazioni del browser o non utilizzare Exchange. ',
		text25:'Aggiorna politica',
		text26:'Potremmo aggiornare periodicamente la presente Politica sui cookie per riflettere i cambiamenti nelle nostre pratiche o i cambiamenti nella legge applicabile. Ti consigliamo di rivedere regolarmente questa politica per le informazioni più recenti. ',
		text27:'Se hai domande o dubbi sul modo in cui utilizziamo i cookie, contatta il nostro team di assistenza clienti. ',
		
		text28:'Ricevi assistenza e risoluzione dei problemi',
		text29:'Per iniziare',
		text30:'Scambia token',
		text31:'Risoluzione dei problemi',
		text32:'Cos\'è uno scambio decentralizzato? ',
		text33: 'DEX è uno scambio di criptovaluta che funziona su una rete blockchain decentralizzata e di solito è costruito su contratti intelligenti A differenza dei tradizionali scambi centralizzati, CEX si affida a un\'agenzia centrale per facilitare le transazioni e trattenere i fondi degli utenti, DEX può commerciare direttamente con entrambe le parti. contratti intelligenti. ',
		text34:'Sicurezza degli scambi decentralizzati',
		text35: 'DEX opera utilizzando contratti intelligenti sulla blockchain, consentendo transazioni dirette tra acquirenti e venditori, eliminando la necessità di istituzioni terze. DEX funziona su una blockchain pubblica, le transazioni sono trasparenti e verificabili e chiunque può verificare l\'equità e l\'integrità del DEX. ',
		text36:'Cos\'è un portafoglio decentralizzato? ',
		text37: 'Un portafoglio decentralizzato è un portafoglio di criptovaluta composto da 12 chiavi. Gli utenti possono utilizzare 12 chiavi per accedere al portafoglio utilizzando la chiave su qualsiasi stessa blockchain per accedere alle risorse digitali. Finché la chiave non viene divulgata, nessuno può ottenere le risorse digitali nel portafoglio decentralizzato.',
		text38:'Cos\'è l\'indirizzo di un portafoglio? ',
		text39:'L\'indirizzo del portafoglio di criptovaluta è una chiave pubblica ottenuta da una chiave privata attraverso l\'algoritmo di firma della curva ellittica. La chiave pubblica è un indirizzo del portafoglio ottenuto attraverso una serie di conversioni. Durante il processo di conversione viene utilizzata un\'operazione di hash irreversibile l\'indirizzo viene utilizzato principalmente per l\'invio e la ricezione di criptovaluta. ',
		text40:'Sicurezza nell\'uso di DEX per scambiare fondi',
		text41:'Il meccanismo operativo di DEX è basato sulla blockchain. Non passa attraverso istituzioni terze e adotta un sistema non custodito per lo scambio diretto. Tutte le transazioni vengono eseguite utilizzando contratti intelligenti blockchain, realizzando realmente la realizzazione di asset Trasparenza, privacy e sicurezza. ',
		text42: 'Perché c\'è una differenza di prezzo per lo stesso token sull\'exchange? ',
		text43: 'Domanda e offerta di mercato: l\'exchange fornisce l\'acquisto e la vendita di asset, ma a causa della diversa domanda e offerta di mercato delle principali borse, i prezzi fissati dall\'exchange per la vendita e l\'acquisto cambieranno e la domanda di determinati token è più alto. Di solito il prezzo sarà superiore al prezzo di mercato e il prezzo sarà inferiore nelle borse con domanda inferiore. ',
		text44: 'Volume degli scambi: la quantità di criptovaluta acquistata e venduta in una borsa, che può influenzare il prezzo. Un volume di scambi più elevato indica generalmente una maggiore attività di mercato, che può promuovere una scoperta dei prezzi più accurata. Gli scambi con volumi di scambio bassi possono avere spread bid-ask più ampi e prezzi più imprecisi. ',
		text45: 'Liquidità: si riferisce alla facilità e alla difficoltà di acquistare o vendere un bene senza causare una variazione significativa del prezzo. Le criptovalute con maggiore liquidità tendono ad avere prezzi più coerenti tra gli scambi. Quando la liquidità in borsa è bassa, ciò può portare a fluttuazioni dei prezzi e grandi differenze di prezzo. ',
		text46: 'Selezione dei token: non tutti i token hanno lo stesso potenziale di profitto e token diversi possono avere diversi gradi di liquidità, volume di scambi e volatilità dei prezzi.',
		text47:'Cos\'è lo spread del tasso di cambio? ',
		text48: 'I differenziali del tasso di cambio si riferiscono alle variazioni dei prezzi delle criptovalute tra diversi scambi. Poiché le criptovalute sono disperse a livello globale e scambiate su una varietà di piattaforme, le dinamiche della domanda e dell\'offerta, la liquidità, i volumi degli scambi e le condizioni di mercato possono differire tra gli scambi. ',
		text49:'Cos\'è lo scambio DEX? ',
		text50:'Lo scambio DEX, noto anche come scambio di token o scambio di risorse, si riferisce al processo di scambio di una criptovaluta o token con un\'altra criptovaluta o token attraverso un accordo di scambio su DEX. Queste transazioni sono generalmente agevolate da contratti intelligenti sulla blockchain, eliminando la necessità di intermediari. ',
		text51: 'Perché gli spread sono scomparsi sulle principali borse valori? ',
		text52:'Quando non c\'è differenza nel prezzo dei token su diversi scambi, significa che il mercato è relativamente stabile. La ragione di questa situazione è che i trader del mercato commerciano in modo piatto e arbitrano automaticamente sulla base di transazioni sbilanciate, e i giganti del mercato vi partecipano. transazioni per sfruttare un gran numero di finanziamenti per l\'arbitraggio commerciale, mantenere stabili i prezzi dei token di mercato, il volume degli scambi e la liquidità, un volume di scambi più elevato e una liquidità sufficiente e il consolidamento del mercato sono tutti motivi che causano la scomparsa degli spread sugli scambi',
		text53:'Qual è l\'indirizzo designato? ',
		text54: 'L\'indirizzo specificato è l\'indirizzo di ricezione della criptovaluta che l\'utente deve compilare dopo che lo scambio ha avuto esito positivo. Gli utenti che effettuano lo scambio per la prima volta devono associare l\'indirizzo di ricezione comune',
		text55:'Cos\'è lo slittamento del prezzo? ',
		text56: 'Lo slippage del prezzo, noto anche come slippage, è la differenza tra il prezzo previsto di un\'operazione di criptovaluta e il prezzo al quale l\'operazione viene effettivamente eseguita. Lo slittamento dei prezzi è solitamente causato dalla volatilità del mercato, dalla liquidità, dalla dimensione dell\'ordine, dal tipo di ordine, ecc. In un mercato volatile e in rapido movimento come quello delle criptovalute, i prezzi possono cambiare rapidamente. ',
		text57:'Cos\'è lo scambio GAS? ',
		text58: 'GAS è la commissione GAS che gli utenti devono pagare alla blockchain quando ritirano la criptovaluta dopo uno scambio riuscito. La commissione GAS viene utilizzata per elaborare gli ordini degli utenti e allo stesso tempo trasmessa nella blockchain per confermare il successo della transazione dell\'utente. . Di solito la commissione GAS per il ritiro di attività in cambio è dello 0,1%. ',
		text59:'Perché ci sono ordini pendenti? ',
		text60: 'Congestione della rete: durante i periodi di domanda elevata o di maggiore attività, le reti blockchain possono diventare congestionate. Questa congestione può portare a tempi di elaborazione delle transazioni più lenti e a commissioni più elevate. Grandi fluttuazioni del mercato o picchi nell’attività di trading possono causare congestione della rete. ',
		text61: 'Problemi di scalabilità: alcune reti blockchain devono affrontare sfide di scalabilità, il che significa che la blockchain potrebbe non essere in grado di gestire grandi volumi di transazioni in modo rapido ed efficiente. Ciò può portare a ritardi e velocità di transazione più lente. ',
		text62: 'Vincoli di liquidità: la disponibilità di acquirenti e venditori sul mercato. In alcuni mercati di criptovalute o criptovalute specifiche, la mancanza di liquidità può causare un rallentamento delle transazioni. Potrebbero esserci meno partecipanti che acquistano o vendono a un prezzo specifico, causando un ampliamento dello spread denaro-lettera. ',
		text63: 'Dimensione dell\'ordine di mercato: la dimensione dell\'ordine influisce sulla velocità del trading, soprattutto quando si tratta di un ordine di grandi dimensioni rispetto alla liquidità disponibile. Quando gli ordini interagiscono con la domanda o l\'offerta disponibile sul mercato, l\'immissione di un numero elevato di ordini di acquisto o di vendita può comportare esecuzioni parziali o un\'esecuzione più lenta, con conseguenti ordini in sospeso. ',
		text64:'Le risorse negli ordini pendenti sono al sicuro? ',
		text65: 'I fondi negli ordini pendenti non andranno persi nel mercato delle criptovalute. Quando un ordine di transazione è in sospeso, devi attendere che la blockchain corrisponda all\'ordine. Una volta abbinato l\'ordine, la transazione verrà eseguita immediatamente. ',
		text66:'Cos\'è l\'elaborazione accelerata degli ordini? ',
		text67: 'Poiché il rapporto di domanda e offerta non esiste più quando il trader avvia l\'ordine, l\'ordine corrispondente non può essere abbinato nella blockchain. Gli utenti possono scegliere di aumentare i fondi di transazione per completare la transazione dell\'ordine in sospeso con il nuovo rapporto di domanda e offerta. ',
	},
	login: {
		Get_Started: 'Get Started',
		Best_Swap: 'World\'s Best Swap Platform',
		Decentralized_cryptocurrency: 'Decentralized cryptocurrency exchange platform, enabling fast and seamless exchange between different digital assets!',
		Cookies_Privacy: 'Cookies Privacy',
		We_use_cookies: 'We use cookies to enhance your browsing experience, analyze website traffic, and provide personalized content. By continuing to use this website, you agree to our use of cookies!',
		Accept: 'Accept',
		Sum: 'Sum',
		Swap: 'Swap',
		Balance: 'Balance',
		Name: 'Name',
		Price_Change: 'Price Change',
		Sort_Type: 'Sort Type',
		Add_Asset: 'Add Asset',
		Add_the_cryptocurrency: 'Add the cryptocurrency assets you need',
		Current_Market_Conditions: 'Current Market Conditions',
		Market: 'Market',
		Overview: 'Overview',
		Coin_Information: 'Coin Information',
		Market_Value: 'Market Value',
		Total_Supply: 'Total Supply',
		In_Circulation: 'In Circulation',
		Trading_Volume: 'Trading Volume',
		Official_Website: 'Official Website',
	}
}
