export default {
	"830753-0": "Yükleme",
	"830753-1": "Varlıkları hızlı bir şekilde yükle",
	"830753-2": "Lütfen kanıt belgesini yükleyin",
	"830753-3": "Yükleme işlemini onayla",
	"830753-4": "Sunucu yanıt verisi doğru değil:",
	"830753-5": "Resim yükleme başarısız:",
	account: {
		Start_making_money_plan: "Para kazanmaya başla planı",
		Market: "Pazar",
		Migital_currency: "Migital para birimi",
		Foreign_exchange: "Döviz",
		Noble_metal: "soy metal",
		Send_Cryop_Now: "Şimdi Cryop'u Gönder",
		Send: "Göndermek",
		Receive: "Almak",
		Buy: "Satın almak",
		Currency_account: "Döviz Hesabı",
		Contract_account: "Sözleşme hesabı",
		Transfer: "Aktar",
		Select_a_wallet: "Bir cüzdan seçin",
		Select_Coin: "Madeni Para Seçin",
		Confirm: "Onaylamak",
		Wallet: "Cüzdan",
		Availavle: "Mevcut",
		Frozen: "Dondurulmuş",
		Convert: "Dönüştürmek",
		Receiving_Address: "Alıcı Adresi",
		Amount: "Miktar",
		Max: "Maksimum",
		Send_Now: "Şimdi gönder",
		Please_check_if: "Varlık kaybını önlemek için göndermeden önce lütfen teslimat adresinizin doğru olup olmadığını kontrol edin.",
		new1:'kripto para birimi',
		new2:'indeks',
		new3:'Toplu',
		new4:'Forex',
		new5:'toplam varlıklar',
	},
	"314962-0": "Google 2FA",
  "314962-1": "Google Authenticator'ı indir ve bağla",
  "314962-2": "Doğrulama kodunu kopyala",
  "314962-3": "Doğrulama kodunu gir",
  "314962-4": "İstek başarısız:",
  "314962-5": "Doğrulama kodu kopyalandı",
  "314962-6": "Metni kopyalayamıyorum:",
  "314962-7": "Kopyalama başarısız, lütfen tekrar deneyin",
  "199917-0": "Ayarlar",
  "199917-1": "Bildirimler",
  "199917-2": "Google 2FA",
  "199917-3": "E-posta",
  "199917-4": "Dil",
  "199917-5": "Sürüm",
  "199917-6": "Çıkış",
    home:{
        USDTAccount: 'USDT Account',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: 'Open',
        ETHTodayProfit: 'ETH Today\'s Profit',
        totalRevenue: 'Total Revenue',
        portfolio: 'Portfolio',
        reliableSecurity: 'Reliable Security Guarantee',
        stableReliable: 'Stable and Reliable Investment',
        convenienEasy: 'Convenien and Easy Operation',
        pleaseLookForward: 'Please look forward to...',
        rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
    },
	"ConnectW":"Connect Wallet",
	"dappbrowser":"This website only accepts access from decentralized wallet DAPP browsers.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "Earn Interest",
    "$": "$",
    "ETH Today's Profit": "ETH Today's Profit",
    "Wallet": "Wallet",
    "Record": "Record",
    "Support": "Support",
    "General": "General",
    "Notifications": "Notifications",
    "Invite Friends": "Invite Friends",
    "FAQ": "FAQ",
    "Privacy & Security": "Privacy & Security",
    "Legality": "Legality",
	"Authenticator":"Authenticator",
	"Settings":"Settings",
	"Change Language":"Change Language",
	"Daytime mode":"Daytime mode",
	"Black mode":"Black mode",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',





    //钱包
    TransactiónDetails:'Transactión Details',
	dealdetails:'deal details',
    ViewTransactionHistory:'View Transaction History',
    Deposit:'Deposit',
    Rechargeaddress:'Recharge address：',
    DepositAmount:'Deposit Amount：',
    EnterDepositAmount:'Enter Deposit Amount',
    EnterHash:'Enter Hash',
    Hash:'Hash：',
    Voucherimage:'Voucher image：',
    Continue:'Continue',
    Withdraw:'Withdraw',
    Withdrawal:'Withdrawal：',
    EnterAmounttoWithdraw:'Enter Amount to Withdraw',
    Available:"Available：",
    ReceiveAddress:'Receive Address：',
    EnterWalletAddress:'Enter Wallet Address',
    WithdrawalDesc:'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
    Exchange:'Exchange',
    Send:'Send:',
    Max:'Max:',
    rechargeAmount:'Please input a recharge amount',
    hashcode:'hash code and screenshot cannot be empty at the same time',
    correcthashcode:'please input a correct hash code',
    maximumwithdrawalamount:'The maximum withdrawal amount is',
    Incorrectamountformat:'Incorrect amount format',
	copy:'copy',


	//tab2
	"Total Profit":"Total Profit",
	"Today's Profit":"Today's Profit",
	"What is Automated Trading?":"What is Automated Trading?",
	"Learn about earning":"Learn about earning",
	"Staking Period":"Staking Period",
	"Staking Amount":"Staking Amount",
	"Yield":"Yield",
	"Limit":"Limit",
	"Subscribe":"Subscribe",
	"Record":"Record",

	//tab3
	"Proof of Stake":"Proof of Stake",
	"ETH Today is Price":"ETH Today is Price",
	"ETH Today's Increase":"ETH Today's Increase",
	"Joint Staking":"Joint Staking",
	"Individual Staking":"Individual Staking",
	"tab3long1":"Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2":"Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1":"Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2":"By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3":"Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4":"Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction":"Introduction",
	"webviewlong5":"Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6":'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process":"The Staking Process",
	"webviewlong7":"To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks":"Staking Rewards and Risks",
	"webviewlong8":"By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking":"Joint Staking and Individual Staking",
	"webviewlong9":"For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion":"Conclusion",
	"webviewlong10":"Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total":"Total",

	//record
	"Account":"Account",
	"interest-bearing":"interest-bearing",
	"POS Staking":"POS Staking",
	"convert":"convert",
	"transaction":"transaction",
	"pledge":"pledge",
	"recordno":"You don't have any news yet. The list is empty.",
	"Oops":"Oops",
	"recharge":"recharge",
	"payment":"payment",
	"staking":"staking",
	"income":"income",
	"Pledge quantity":"Pledge quantity",
	"cycle":"cycle",
	"Days Remaining":"Days Remaining",
	"cumulative gain":"cumulative gain",
	"Price increase":"Price increase",
	"Price decrease":"Price decrease",
	"Opening price":"Opening price",
	"Closing price":"Closing price",
	"loss":"loss",
	"pfofit":"pfofit",
	"amount":"amount",

	//notifiCations
	"notifiCationslong1":"Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2":"The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3":"Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4":"Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5":"VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6":"VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7":"VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8":"VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9":"VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10":"VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11":"VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12":"VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13":"VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn":"Refer and Earn",
	"Copy Link":"Copy Link",

	//faq
	"Frequently Asked Questions":"Frequently Asked Questions",

	//authenticator
	"mailbox":"mailbox",
	"Front photo":"Front photo",
	"Back photo":"Back photo",
	"Handheld ID photo":"Handheld ID photo",
	"define":"define",

	//legality
	"legalitylong1":"Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2":"Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3":"1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4":"2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5":"3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6":"Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7":'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language":"language",
	"Confirm":"Confirm",

	"newtext1":"Sign out",
	"newtext2":"Cancel",
	"newtext3":"Are you sure you want to log out of this account?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"Login",
	"newtext9":"username",
	"newtext10":"Mobile phone number",
	"newtext11":"Please enter user name",
	"newtext12":"Please enter your mobile phone number",
	"newtext13":"password",
	"newtext14":"Please enter password",
	"newtext15":"No account yet",
	"newtext16":"Register",
	"newtext17":"Country",
	"newtext18":"Select a country",
	"newtext19":"Mobile phone number verification code",
	"newtext20":"Please enter the verification code",
	"newtext21":"Email address",
	"newtext22":"Please enter your email address",
	"newtext23":"Confirm password",
	"newtext24":"Please enter the confirmation password",
	"newtext25":"Have an account",
	"newtext26":"Send verification code",
	"newtext27":"Mobile phone number is incorrect",
	"newtext28":"Sent successfully",
	"newtext29":"Registration successful",

	"newtext30":'currency',
	"newtext31":'Forex',
	"newtext32":'precious metals',

	"newtext33":'Hesap',
	"newtext34":'Platform etkinlikleri',
	"newtext35":'Müşteri Hizmetleri',
	"newtext36":'davetiye',
	"newtext37":'KYC doğrulaması',
	"newtext38":'ayar',
	wallet_p:{
		text1: "Adres Kopyala",
		text2: 'Onayla',
		text3: 'Buradan değiştir',
		text4: "Şuraya değiştir",
		text5: "MAX",
		text6: "Davet et",
		text7: 'Tavsiye ödülleri alın',
		text8: "Arkadaşlarınız AI arbitraj ve madencilik makinelerini kiralamakta yer aldığında, tavsiye ödülleri alabilirsiniz",
		text9: "kopyala",
		text10: 'Bağlantiyi Paylaş',
		text11: "KYC Doğrulama",
		text12: "Ülke",
		text13: "Lütfen bir ülke seçin",
		text14: "Ad",
		text15: "Lütfen adınızı girin",
		text16: "Soyad",
		text17: "Lütfen soyadınızı girin",
		text18: "Belge Türü",
		text19: "Lütfen Belge Türünü seçin",
		text20: "Belge Numarası",
		text21: "Lütfen belge numarasını girin",
		text22: "Lütfen kimlik kartınızın ön tarafının fotoğrafını yükleyin",
		text23: "Lütfen kimlik kartınızın arka tarafının fotoğrafını yükleyin",
		text24: "Lütfen pasaportunuzun ön tarafının fotoğrafını yükleyin",
		text25: "Lütfen pasaportunuzun arka tarafının fotoğrafını yükleyin",
		text26: "E-posta",
		text27: "Lütfen e-postanızı girin",
		text28: "gönder",
		text29: "Doğrulama kodu",
		text30: "Lütfen doğrulama kodunuzu girin",
		text31: "Kimlik kart",
		text32: "pasaport",
		text33: "iptal",
		text34: "Ülke/Bölge",
		text35: "Doğrulama için doğru bilgiler girin",
		text36: "Başarıyla gönderildi!",
		text37: "E-posta biçimi hatası!",
		text38: "Ehliyet",
		text39: "Lütfen ehliyetinizin ön yüzünün fotoğrafını yükleyin",
		text40: "Lütfen ehliyetinizin arka yüzünün fotoğrafını yükleyin",
		
		text41: "kimlik numarası",
		text42: "Lütfen kimlik numaranızı giriniz",
		text43: "Pasaport numarası",
		text44: "Lütfen pasaport numarasını girin",
		text45: "sürücü belgesi numarası",
		text46: "Lütfen ehliyet numaranızı giriniz",
	},
	jiaoyi:{
		text1:'İkinci Sözleşme',
		text2:'Sürekli Sözleşme',
		text3:'Spot işlem',
		text4:'Derhal yetki verin',
		text5:'Hesap türü',
		text6:'teslimat süresi',
		text7:'Fiyat aralığı',
		text8:'Satın alma fiyatı',
		text9:'maliyet',
		text10:'Denge',
		text11:'en azından',
		text12:'beklenen',
		text13:'Satın alma tutarı',
		text14:'yön',
		text15:'Satın alma fiyatı',
		text16:'Gerçek zamanlı fiyat',
		text17:'Geri sayım',
		text18:'Sözleşmem',
		text19:'Kar',
		text20:'Sözleşme',
		text21:'teslimat fiyatı',
		text22:'teslimat süresi',
		text23:'Piyasa fiyatı',
		text24:'yükseliyor',
		text25:'düşüş',
		text26:'Satın alma fiyatı',
		text27:'maksimum',
		text28:'Kar',
		text29:'işlem ücreti',
		text30:'Satın Al',
		text31:'Sat',
		text32:'Satın alma miktarı',
		text33:'gerçek hesap',
		text34:'Sanal Hesap',
		text35:'Henüz veri yok',
		text36:'Geçerli konum',
		text37:'Tarih komisyonu',
		text38:'Konumu kapat',
		text39:'Kenar',
		text40:'Kar ve zarar',
		text41:'bekle',
		text42:'Satın alma kaydı',
		text43:'Satış rekoru',
		text44:'Mevcut',
		text45:'dönüştürüldü',
		text46:'Kimlik doğrulama başarılı',
		text47:'Satın alma zamanı',
		text48:'okunmamış',
		text49:'Okumak',
		text50:'Ana bilgisayar siparişi',
		text51:'Toplam arbitraj',
		text58:'Bugünkü kazançlar',
		text52:'Yapay zeka robotları nasıl çalışır',
		text53:'arbitraj ürünü',
		text54:'gün',
		text55:'Tutar',
		text56:'Günlük gelir',
		text57:'Para birimi türü',
		text59:'Yapay zeka arbitrajına katılın',
		text60:'Sıfır risk, hızlı getiri',
		text61:'Yapay zeka arbitrajı',
		text62:'Beklenen kazançlar',
		text63:'Arbitraj para türü',
		text64:'Emanet tutarı',
		text65:'Şimdi barındırın',
		text66:'USDT cüzdanınıza gönderilen günlük gelir',
		text67:'Yatırım Fonu Sıfır Riski',
		text68:'Paranızı istediğiniz zaman çekebilirsiniz',
		text69:'Yapay zeka günün 24 saati çalışır',
		text70:'Kümülatif gelir',
		text71:'Son kullanma süresi',
		text72:'tümü',
		text73:'Devam ediyor',
		text74:'Tamamlandı',
		text75:'Barındırma ayrıntıları',
		text76:'Para birimini seçin',
		text77:'Teknik destek',
		text78:'E-posta gönder',
		text79:'İşlem Türü',
	},
	
	swapadd:{
		text1:'Çerezler Politikası',
		text2:'Veri güvenliğinizi ve gizliliğinizi korumaya kararlıyız',
		text3:'Giriş',
		text4:'Bu Çerez Politikası, merkezi olmayan borsamızın çerezleri ve benzer izleme teknolojilerini nasıl kullandığını açıklamaktadır. Exchange\'e erişerek veya Exchange\'i kullanarak, Çerezlerin bu Politikada açıklandığı şekilde kullanılmasını kabul etmiş olursunuz. ',
		text5:'Çerezler nedir? ',
		text6:'Çerezler, bir web sitesini ziyaret ettiğinizde cihazınıza (bilgisayar, cep telefonu veya tablet) yerleştirilen küçük metin dosyalarıdır. Tarama deneyiminizi geliştirmek ve web sitelerinde kişiselleştirme özellikleri sağlamak için yaygın olarak kullanılırlar. Çerezler, oturum çerezleri (geçici olan ve tarayıcınızı kapattığınızda silinen) veya kalıcı çerezler (cihazınızda daha uzun süre kalan) olabilir. ',
		text7:'Çerezler\'i nasıl kullanıyoruz',
		text8: 'Borsaların çerezleri kullanma amaçları aşağıdakileri içerir ancak bunlarla sınırlı değildir:',
		text9:'Değişimin temel işlevlerini ve özelliklerini etkinleştirin',
		text10:'Değişimin performansını ve işlevselliğini analiz edin ve iyileştirin',
		text11:'Deneyiminizi kişiselleştirin ve tercihlerinizi hatırlayın',
		text12:'Borsada kullanım kalıplarınızı takip edin ve anlayın',
		text13:'İlgili reklam ve pazarlama iletişimlerini sağlayın',
		text14:'Kullandığımız Çerez Türleri',
		text15:'a) Temel Çerezler: Bu çerezler borsanın işleyişi için gereklidir ve borsanın özelliklerine erişmenizi ve bunları kullanmanızı sağlar. Bu Çerezler olmadan bazı hizmetleri kullanamayabilirsiniz. ',
		text16:'b) Analiz Çerezleri: Bu çerezler, ziyaret ettiğiniz sayfalar ve karşılaştığınız hatalar gibi, borsayı nasıl kullandığınıza ilişkin bilgiler toplar. Bu bilgileri Exchange\'in performansını ve işlevselliğini analiz etmek ve geliştirmek için kullanırız. ',
		text17:'c) İşlevsellik Çerezleri: Bu çerezler, Exchange\'in seçimlerinizi (dil tercihleri ​​gibi) hatırlamasına ve gelişmiş, daha kişiselleştirilmiş işlevsellik sağlamasına olanak tanır. ',
		text18:'d) Reklam Çerezleri: Bu çerezler size ve ilgi alanlarınıza uygun reklamlar sunmak için kullanılır. Ayrıca bir reklamı görme sayınızı sınırlamak ve reklam kampanyalarının etkinliğini ölçmek için de kullanılabilirler. ',
		text19:'Üçüncü Taraf Çerezleri',
		text20:'Exchange\'ler, üçüncü taraf hizmet sağlayıcılarının analiz ve reklam dahil olmak üzere çeşitli amaçlarla cihazınıza Çerez yerleştirmesine izin verebilir. Bu üçüncü tarafların kendi gizlilik politikaları vardır ve farklı web siteleri veya çevrimiçi hizmetler üzerindeki çevrimiçi etkinlikleriniz hakkında bilgi toplayabilirler. ',
		text21:'Çerezler Yönetimi',
		text22:'Tarayıcınızın ayarlarından Çerezleri istediğiniz zaman yönetebilir veya silebilirsiniz. Ancak belirli çerezlerin devre dışı bırakılmasının veya silinmesinin borsanın işlevselliğini ve performansını etkileyebileceğini lütfen unutmayın. ',
		text23:'Kabul ediyorum',
		text24:'Exchange\'i kullanarak, Çerezlerin bu politikada açıklandığı şekilde kullanılmasını kabul etmiş olursunuz. Çerezlerin kullanımını kabul etmiyorsanız tarayıcınızın ayarlarını kullanarak çerezleri devre dışı bırakabilir, silebilir veya Exchange\'i kullanmayabilirsiniz. ',
		text25:'Politikayı güncelle',
		text26:'Uygulamalarımızdaki değişiklikleri veya yürürlükteki kanunlardaki değişiklikleri yansıtacak şekilde bu Çerez Politikasını periyodik olarak güncelleyebiliriz. En son bilgiler için bu politikayı düzenli olarak incelemenizi öneririz. ',
		text27:'Çerezleri kullanma şeklimizle ilgili herhangi bir sorunuz veya endişeniz varsa, lütfen müşteri destek ekibimizle iletişime geçin. ',
		
		text28:'Sorun giderme ve yardım alın',
		text29:'Başlarken',
		text30:'Değişim jetonları',
		text31:'Sorun giderme',
		text32:'Merkezi olmayan borsa nedir? ',
		text33: 'DEX, merkezi olmayan bir blockchain ağı üzerinde çalışan ve genellikle akıllı sözleşmeler üzerine kurulu bir kripto para borsasıdır. Geleneksel merkezi borsalardan farklı olarak CEX, işlemleri kolaylaştırmak ve Kullanıcı fonlarını tutmak için merkezi bir kuruma dayanır, DEX her iki tarafla da doğrudan ticaret yapabilir. akıllı sözleşmeler. ',
		text34:'Merkezi olmayan borsaların güvenliği',
		text35: 'DEX, blok zincirindeki akıllı sözleşmeleri kullanarak çalışır, alıcılar ve satıcılar arasında doğrudan işlemlere izin vererek üçüncü taraf kurumlara olan ihtiyacı ortadan kaldırır. DEX halka açık bir blockchain üzerinde çalışır, işlemler şeffaf ve denetlenebilirdir ve herkes DEX\'in adilliğini ve bütünlüğünü doğrulamak için kontrol edebilir. ',
		text36:'Merkezi olmayan cüzdan nedir? ',
		text37: 'Merkezi olmayan bir cüzdan, 12 anahtardan oluşan bir kripto para cüzdanıdır. Kullanıcılar, dijital varlıklara erişmek için aynı blok zincirindeki anahtarı kullanarak cüzdanda oturum açmak için 12 anahtar kullanabilir. Anahtar sızdırılmadığı sürece, hiç kimse merkezi olmayan cüzdandaki dijital varlıkları elde edemez.',
		text38:'Cüzdan adresi nedir? ',
		text39:'Kripto para birimi cüzdan adresi, eliptik eğri imza algoritması aracılığıyla özel bir anahtardan elde edilen bir genel anahtardır. Genel anahtar, bir dizi dönüşüm yoluyla elde edilen bir cüzdan adresidir. Dönüştürme işlemi sırasında geri dönüşü olmayan bir karma işlemi kullanılır. Adres esas olarak Kripto para göndermek ve almak için kullanılır. ',
		text40:'Para alışverişinde DEX kullanmanın güvenliği',
		text41:'DEX\'in çalışma mekanizması blockchain üzerine kuruludur ve herhangi bir üçüncü taraf kurumdan geçmez ve doğrudan takas için saklama dışı bir sistemi benimser. Tüm işlemler akıllı sözleşmeler kullanılarak yürütülür. Varlıkların şeffaflığını, gizliliğini ve güvenliğini gerçek anlamda gerçekleştiren blockchain. ',
		text42: 'Borsadaki aynı token için neden fiyat farkı var? ',
		text43: 'Piyasa arz ve talebi: Borsa varlıkların alım ve satımını sağlar, ancak büyük borsaların farklı piyasa arz ve talepleri nedeniyle borsanın satış ve alım için belirlediği fiyatlar değişecek ve belirli tokenlara olan talep değişecektir Genellikle fiyat piyasa fiyatından yüksek olur ve talebin düşük olduğu borsalarda fiyat daha düşük olur. ',
		text44: 'İşlem hacmi: Bir borsada satın alınan ve satılan, fiyatı etkileyebilecek kripto para miktarı. Daha yüksek işlem hacmi genellikle daha fazla piyasa faaliyetine işaret eder ve bu da daha doğru fiyat keşfini teşvik edebilir. Düşük işlem hacmine sahip borsalar, daha geniş alış-satış spreadlerine ve daha hatalı fiyatlandırmaya sahip olabilir. ',
		text45: 'Likidite: önemli bir fiyat değişikliğine neden olmadan bir varlığı satın almanın veya satmanın kolaylığını ve zorluğunu ifade eder. Daha yüksek likiditeye sahip kripto para birimleri, borsalar arasında daha tutarlı fiyatlara sahip olma eğilimindedir. Bir borsadaki likiditenin düşük olması fiyat dalgalanmalarına ve büyük fiyat farklılıklarına yol açabilir. ',
		text46: 'Token Seçimi: Tüm tokenlar aynı kar potansiyeline sahip değildir ve farklı tokenlar farklı derecelerde likiditeye, işlem hacmine ve fiyat oynaklığına sahip olabilir.',
		text47:'Döviz kuru farkı nedir? ',
		text48: 'Döviz kuru farklılıkları, farklı borsalar arasındaki kripto para birimi fiyatlarındaki değişiklikleri ifade eder. Kripto para birimleri küresel olarak dağıldığından ve çeşitli platformlarda alınıp satıldığından, arz ve talep dinamikleri, likidite, işlem hacimleri ve piyasa koşulları borsalar arasında farklılık gösterebilir. ',
		text49:'DEX değişimi nedir? ',
		text50:'DEX değişimi, aynı zamanda belirteç değişimi veya varlık değişimi olarak da bilinir, DEX üzerinde bir değişim anlaşması yoluyla bir kripto para biriminin veya belirtecin başka bir kripto para birimi veya belirteçle değiştirilmesi sürecini ifade eder. Bu işlemler genellikle blockchain üzerindeki akıllı sözleşmelerle kolaylaştırılarak aracılara duyulan ihtiyaç ortadan kaldırılır. ',
		text51: 'Büyük borsalardaki spreadler neden kayboldu? ',
		text52:'Farklı borsalarda token fiyatları arasında fark olmaması, piyasanın nispeten istikrarlı olduğu anlamına gelir. Bu durumun nedeni, piyasadaki tüccarların dengesiz işlemlere dayalı olarak sabit ticaret yapması ve otomatik olarak arbitraj yapması ve piyasa devlerinin katılmasıdır. Ticaret arbitrajı için çok sayıda Fonlamadan yararlanmaya yönelik işlemler, piyasa token fiyatlarının sabit tutulması, işlem hacmi ve likidite, daha yüksek işlem hacmi ve yeterli likidite ve piyasa konsolidasyonu, borsalarda spreadlerin ortadan kalkmasına neden olan nedenlerdir',
		text53:'Belirlenen adres nedir? ',
		text54: 'Belirtilen adres, takas başarılı olduktan sonra kullanıcının doldurması gereken kripto para alım adresidir. İlk defa takas yapacak kullanıcıların ortak alım adresini bağlamaları gerekmektedir',
		text55:'Fiyat kayması nedir? ',
		text56: 'Kayma olarak da bilinen fiyat kayması, bir kripto para ticaretinin beklenen fiyatı ile ticaretin fiilen gerçekleştirildiği fiyat arasındaki farktır. Fiyat kayması genellikle piyasa oynaklığı, likidite, emir büyüklüğü, emir türü vb. nedenlerden kaynaklanır. Kripto para birimi gibi hızlı hareket eden ve değişken bir piyasada fiyatlar hızla değişebilir. ',
		text57:'Değişim GAZ\'ı nedir? ',
		text58: 'GAS, başarılı bir takasın ardından kripto para birimini çekerken kullanıcıların blockchain\'e ödemesi gereken GAS ücretidir. GAS ücreti, kullanıcı siparişlerini işlemek için kullanılır ve aynı zamanda kullanıcının işleminin başarısını onaylamak için blockchain\'de yayınlanır. . Genellikle döviz varlıklarının çekilmesine ilişkin GAS ücreti %0,1\'dir. ',
		text59:'Neden bekleyen emirler var? ',
		text60: 'Ağ tıkanıklığı: Yüksek talep veya artan aktivite dönemlerinde blockchain ağları tıkanabilir. Bu tıkanıklık, daha yavaş işlem işlem sürelerine ve daha yüksek ücretlere yol açabilir. Büyük piyasa dalgalanmaları veya ticaret faaliyetlerindeki dalgalanmalar ağ tıkanıklığına neden olabilir. ',
		text61: 'Ölçeklenebilirlik sorunları: Bazı blockchain ağları ölçeklenebilirlik zorluklarıyla karşı karşıyadır, bu da blockchain\'in büyük hacimli işlemleri hızlı ve verimli bir şekilde gerçekleştiremeyebileceği anlamına gelir. Bu, gecikmelere ve işlem hızlarının yavaşlamasına neden olabilir. ',
		text62: 'Likidite kısıtlamaları: Piyasada alıcı ve satıcıların mevcudiyeti. Bazı kripto para piyasalarında veya belirli kripto para birimlerinde likidite sınırlıdır. Bu likidite eksikliği, işlemlerin yavaşlamasına neden olabilir. Belirli bir fiyattan alım veya satım yapan katılımcı sayısının azalması, alış-satış emirlerinin yürütülmesinin yavaşlamasına neden olabilir. ',
		text63: 'Piyasa emri büyüklüğü: Emrin büyüklüğü, özellikle mevcut likiditeye göre büyük bir emir olduğunda, ticaretin hızını etkiler. Emirler piyasadaki mevcut arz veya taleple etkileşime girdiğinde, çok sayıda alım veya satım emri verilmesi, kısmi gerçekleşmelere veya daha yavaş yürütmeye neden olarak emirlerin beklemesine neden olabilir. ',
		text64:'Bekleyen emirlerdeki varlıklar güvende mi? ',
		text65: 'Bekleyen emirlerdeki fonlar kripto pazarında kaybolmayacaktır. Bir işlem emri beklemedeyken, emir eşleştiğinde blok zincirinin emri karşılamasını beklemeniz gerekir. ',
		text66:'Hızlandırılmış sipariş işleme nedir? ',
		text67: 'Tüccar emri başlattığında arz ve talep ilişkisi artık mevcut olmadığından, ilgili emir blockchain\'de eşleştirilemez. Kullanıcılar, bekleyen emir işlemini yeni arz ve talep ilişkisiyle tamamlamak için işlem fonlarını artırmayı seçebilir. ',
	},
	login: {
		Get_Started: 'Başlayın',
		Best_Swap: 'Dünyanın En İyi Takas Platformu',
		Decentralized_cryptocurrency: 'Farklı dijital varlıklar arasında hızlı ve sorunsuz değişime olanak tanıyan merkezi olmayan kripto para borsası platformu!',
		Cookies_Privacy: 'Çerezler Gizlilik',
		We_use_cookies: 'Tarama deneyiminizi geliştirmek, web sitesi trafiğini analiz etmek ve kişiselleştirilmiş içerik sağlamak için çerezler kullanıyoruz. Bu web sitesini kullanmaya devam ederek çerez kullanımımızı kabul etmiş olursunuz!',
		Accept: 'Kabul etmek',
		Sum: 'Toplam',
		Swap: 'Takas',
		Balance: 'Denge',
		Name: 'İsim',
		Price_Change: 'Fiyat Değişimi',
		Sort_Type: 'Sıralama Türü',
		Add_Asset: 'Varlık Ekle',
		Add_the_cryptocurrency: 'İhtiyacınız olan kripto para varlıklarını ekleyin',
		Current_Market_Conditions: 'Mevcut Piyasa Koşulları',
		Market: 'Pazar',
		Overview: 'Genel bakış',
		Coin_Information: 'Madeni Para Bilgileri',
		Market_Value: 'Piyasa Değeri',
		Total_Supply: 'Toplam Arz',
		In_Circulation: 'Dolaşımda',
		Trading_Volume: 'Ticaret Hacmi',
		Official_Website: 'Resmi Web Sitesi',
	}
}
