<template>
  <div class="main" style="">
    <div class="layout">
      <div class="set_container">
        <div class="popup_container">
          <div class="popup_content">
            <div class="popup_header">
              <div class="header_top">
                <div class="accountColor">{{ $t('516529-0') }}</div>
                <img
                  src="../../static/img/icon_close.0f8cb496.svg"
                  alt=""
                  class="close"
                />
              </div>
              <div class="header_content">
                <div class="intro">{{ $t('516529-1') }}</div>
              </div>
            </div>
            <div class="popup_body">
              <div class="body_content">
                <div class="section">
                  <div class="s_title">Dapp</div>
                  <div class="s_content">
                    <div class="menu_item" @click="toFaq">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set1.4dd2e5ae.svg"
                          alt=""
                        /><span>FAQ</span>
                      </div>
                      <img
                        src="../../static/img/icon_arrow_menu.6174654e.svg"
                        alt=""
                        class="arrow"
                      />
                    </div>
                    <div class="menu_item">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set9.1cc5bffa.svg"
                          alt=""
                        /><span>{{ $t('516529-2') }}</span>
                      </div>
                      <img
                        src="../../static/img/icon_arrow_menu.6174654e.svg"
                        alt=""
                        class="arrow"
                      />
                    </div>
                    <div class="menu_item" @click="clickToOpen('isInvited')">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set2.47f71aab.svg"
                          alt=""
                        /><span>{{ $t('516529-3') }}</span>
                      </div>
                      <img
                        src="../../static/img/icon_arrow_menu.6174654e.svg"
                        alt=""
                        class="arrow"
                      />
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="s_title">{{ $t('516529-4') }}</div>
                  <div class="s_content">
                    <div class="menu_item" @click="clickToOpen('isEmail')">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set3.59faf6aa.svg"
                          alt=""
                        /><span>{{ $t('516529-5') }}</span>
                      </div>
                      <img
                        src="../../static/img/icon_arrow_menu.6174654e.svg"
                        alt=""
                        class="arrow"
                      />
                    </div>
                    <div class="menu_item" @click="clickToOpen('languageOpen')">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set4.2b154613.svg"
                          alt=""
                        /><span>{{ $t('516529-6') }}</span>
                      </div>
                      <img
                        src="../../static/img/icon_arrow_menu.6174654e.svg"
                        alt=""
                        class="arrow"
                      />
                    </div>
                    <div class="menu_item">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set5.ac9e6267.svg"
                          alt=""
                        /><span>{{ $t('516529-7') }}</span>
                      </div>
                      <div class="switch_btn">
                        <div class="btn_bg close">
                          <div class="btn_dot close"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="s_title">{{ $t('516529-8') }}</div>
                  <div class="s_content">
                    <div class="menu_item" @click="gotoService">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set3.59faf6aa.svg"
                          alt=""
                        /><span>{{ $t('516529-9') }}</span>
                      </div>
                      <img
                        src="../../static/img/icon_arrow_menu.6174654e.svg"
                        alt=""
                        class="arrow"
                      />
                    </div>
                    <div class="menu_item" @click="gotoPolicy">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set4.2b154613.svg"
                          alt=""
                        /><span>{{ $t('516529-10') }}</span>
                      </div>
                      <img
                        src="../../static/img/icon_arrow_menu.6174654e.svg"
                        alt=""
                        class="arrow"
                      />
                    </div>
                    <div class="menu_item" @click="gotocookie">
                      <div class="name">
                        <img
                          src="../../static/img/icon_set5.ac9e6267.svg"
                          alt=""
                        /><span>{{ $t('516529-11') }}</span>
                      </div>
                      <img
                        src="../../static/img/icon_arrow_menu.6174654e.svg"
                        alt=""
                        class="arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="logo_content">
                <div class="logo">
                  <img src="../../static/img/logo.54608cbb.svg" alt="" />
                </div>
                <div class="info">
                  <div>Trust Swap</div>
                  <div class="ver">Major Version 2.1.0</div>
                </div>
              </div>
            </div>
          </div>
          <div class="van-overlay" style="z-index: 2003; display: none"></div>
          <div
            class="bottom_popup van-popup van-popup--right"
            style="z-index: 2004; display: none"
          >
            <div class="popup_container">
              <div class="popup_content">
                <div class="popup_header">
                  <div class="header_top">
                    <div>FAQ</div>
                    <img
                      src="../../static/img/icon_close.0f8cb496.svg"
                      alt=""
                      class="close"
                    />
                  </div>
                  <div class="header_content">
                    <div class="intro">{{ $t('516529-12') }}</div>
                  </div>
                </div>
                <div class="popup_body">
                  <div class="body_content">
                    <div class="switch_container">
                      <div class="switch_wrapper">
                        <div class="switch_content">
                          <div class="switch_item">{{ $t('516529-13') }}</div>
                          <div class="switch_item active">{{ $t('516529-14') }}</div>
                          <div class="switch_item">{{ $t('516529-15') }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="faq_container">
                      <div class="faq_item">
                        <div class="faq_header">
                          <span
                            >Why is there a price difference for the same token
                            on the exchange?</span
                          ><img
                            src="../../static/img/icon_add.7cbd270f.svg"
                            alt=""
                          />
                        </div>
                        <!---->
                      </div>
                      <div class="faq_item">
                        <div class="faq_header">
                          <span>What is an exchange rate spread?</span
                          ><img
                            src="../../static/img/icon_add.7cbd270f.svg"
                            alt=""
                          />
                        </div>
                        <!---->
                      </div>
                      <div class="faq_item">
                        <div class="faq_header">
                          <span>What is DEX exchange?</span
                          ><img
                            src="../../static/img/icon_add.7cbd270f.svg"
                            alt=""
                          />
                        </div>
                        <!---->
                      </div>
                      <div class="faq_item">
                        <div class="faq_header">
                          <span
                            >Why did the spread disappear on major
                            exchanges?</span
                          ><img
                            src="../../static/img/icon_add.7cbd270f.svg"
                            alt=""
                          />
                        </div>
                        <!---->
                      </div>
                      <div class="faq_item">
                        <div class="faq_header">
                          <span>What is a designated address?</span
                          ><img
                            src="../../static/img/icon_add.7cbd270f.svg"
                            alt=""
                          />
                        </div>
                        <!---->
                      </div>
                      <div class="faq_item">
                        <div class="faq_header">
                          <span>What is price slippage?</span
                          ><img
                            src="../../static/img/icon_add.7cbd270f.svg"
                            alt=""
                          />
                        </div>
                        <!---->
                      </div>
                      <div class="faq_item">
                        <div class="faq_header">
                          <span>What is exchanging GAS?</span
                          ><img
                            src="../../static/img/icon_add.7cbd270f.svg"
                            alt=""
                          />
                        </div>
                        <!---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="van-overlay"
            style="z-index: 2021"
            v-show="isInvited"
          ></div>
          <div
            class="bottom_popup van-popup van-popup--right"
            style="z-index: 2022"
            v-show="isInvited"
          >
            <div class="popup_container">
              <div class="popup_content">
                <div class="popup_header">
                  <div class="header_top">
                    <div class="accountColor">{{ $t('516529-16') }}</div>
                    <img
                      src="../../static/img/icon_close.0f8cb496.svg"
                      alt=""
                      class="close"
                      @click="isInvited = false"
                    />
                  </div>
                  <div class="header_content">
                    <div class="intro">{{ $t('516529-17') }}</div>
                  </div>
                </div>
                <div class="popup_body">
                  <div class="body_content">
                    <div class="icon_share">
                      <img
                        src="../../static/img/icon_share.94ebf71d.svg"
                        alt=""
                      />
                    </div>
                    <div class="share_tip">
                      <p>
                        Share SumSwap with your friends<br />A free marketplace
                        open and accessible to all
                      </p>
                    </div>
                    <div class="address_content">
                      {{ title }}
                    </div>
                  </div>
                </div>
                <div class="popup_footer" @click="getCopyLink()">
                  <div class="footer_btn">{{ $t('516529-18') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="van-overlay" style="z-index: 2019" v-show="isEmail"></div>
          <div
            class="bottom_popup van-popup van-popup--right"
            style="z-index: 2020"
            v-show="isEmail"
          >
            <div class="popup_container">
              <div class="popup_content">
                <div class="popup_header">
                  <div class="header_top">
                    <div class="accountColor">{{ $t('516529-5') }}</div>
                    <img
                      src="../../static/img/icon_close.0f8cb496.svg"
                      alt=""
                      class="close"
                      @click="isEmail = false"
                    />
                  </div>
                  <div class="header_content">
                    <div class="intro">{{ $t('516529-19') }}</div>
                  </div>
                </div>
                <div class="popup_body">
                  <div class="body_content">
                    <div class="input_form">
                      <div class="form_title">
                        <img
                          src="../../static/img/icon_set3.59faf6aa.svg"
                          alt=""
                        /><span class="accountColor">{{ $t('516529-20') }}</span>
                      </div>
                      <div class="form_content">
                        <div class="form_input">
                          <input
                            type="text"
                            v-model="mailbox"
                            :placeholder="$t('516529-21')"
                          />
                        </div>
                        <div class="line"></div>
                        <div class="form_input">
                          <input
                            type="text"
                            :placeholder="$t('516529-22')"
                            v-model="verificationCode"
                          />
                          <span @click="sendEmail" v-if="!countdownActive">{{
                            countdownText
                          }}</span>
                          <span v-else>{{ secondsRemaining }}s</span>
                        </div>
                      </div>
                    </div>
                    <div class="tip_content">
                      {{ $t('516529-23') }}
                    </div>
                    <div
                      data-v-b9ed0828=""
                      class="load_button"
                      @click="confirmAuthentication()"
                    >
                      {{ $t('516529-24') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="van-overlay van-fade-enter-active van-fade-enter-to"
            style="z-index: 2023"
            v-show="languageOpen"
          ></div>
          <div
            class="bottom_popup van-popup van-popup--right"
            style="z-index: 2024"
            v-show="languageOpen"
          >
            <div class="popup_container" style="top:60px !important;">
              <div class="popup_content">
                <div class="popup_header">
                  <div class="header_top">
                    <div class="accountColor">{{ $t('516529-6') }}</div>
                    <img
                      src="../../static/img/icon_close.0f8cb496.svg"
                      alt=""
                      class="close"
                      @click="languageOpen = false"
                    />
                  </div>
                  <div class="header_content">
                    <div class="intro">{{ $t('516529-25') }}</div>
                  </div>
                </div>
                <div class="popup_body">
                  <div class="body_content">
                    <div class="lang_list">
                      <div
                        v-for="(lang, index) in languages"
                        :key="index"
                        class="lang_item"
                        @click="selectLanguage(lang.code)"
                      >
                        <span>{{ lang.name }}</span>
                        <div
                          :class="{
                            radio: true,
                            selected: lang.code === nowlang,
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="popup_footer" @click="confirmReplacement()">
                  <div class="footer_btn">{{ $t('516529-24') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="van-overlay" style="z-index: 2013; display: none"></div>
          <div
            class="bottom_popup van-popup van-popup--right"
            style="z-index: 2014; display: none"
          >
            <div class="popup_container">
              <div class="popup_content">
                <div class="popup_header">
                  <div class="header_top">
                    <div>Terms of Service</div>
                    <img
                      src="../../static/img/icon_close.0f8cb496.svg"
                      alt=""
                      class="close"
                    />
                  </div>
                  <div class="header_content">
                    <div class="intro">{{ $t('516529-26') }}</div>
                  </div>
                </div>
                <div class="popup_body">
                  <div class="body_content">
                    <p>
                      Before using the Exchange, please carefully read and
                      understand the following Terms of Service. By accessing or
                      using the Exchange, you acknowledge that you have read,
                      understood, and agreed to comply with these terms. If you
                      do not agree with any part of these terms, please refrain
                      from using the Exchange.<br /><br />Acceptance of Terms<br />By
                      using the Exchange, you acknowledge and agree that these
                      Terms of Service constitute a legal agreement between you
                      and the Exchange, and govern your actions. If you do not
                      agree to these terms, please do not use the Exchange.<br /><br />Service
                      Description<br />The Exchange is a decentralized platform
                      designed to provide trading and exchange services for
                      digital assets. The Exchange acts solely as a venue for
                      transactions and does not directly participate in
                      transactions between users. The Exchange does not provide
                      legal, financial, or investment advice and is not
                      responsible for your trading activities.<br /><br />User
                      Eligibility<br />You must be at least 18 years old (or the
                      legal age of majority) and have full legal capacity to use
                      the Exchange. If you are using the Exchange on behalf of
                      an entity or organization, you represent and warrant that
                      you have the authority to accept these terms on behalf of
                      that entity or organization.<br /><br />Registration and
                      Account Security<br />You need to register an account to
                      use the Exchange. You must provide accurate, complete, and
                      up-to-date personal information for registration and are
                      responsible for protecting the security of your account
                      information. You are solely responsible for all activities
                      conducted through your account.<br /><br />Rules of Use<br />By
                      using the Exchange, you agree to comply with all
                      applicable laws, regulations, and rules, and adhere to the
                      following guidelines:<br />(a) Do not violate any
                      applicable laws, regulations, or rules.<br />(b) Do not
                      engage in any illegal, fraudulent, deceptive, or
                      defamatory activities.<br />(c) Do not interfere with or
                      disrupt the normal operation of the Exchange.<br />(d) Do
                      not infringe upon the rights or interests of any third
                      party.<br />(e) Do not manipulate the market or abuse the
                      services of the Exchange.<br />(f) Do not use any
                      automated tools, scripts, or other means to interfere with
                      the normal operation of the Exchange.<br />(g) Do not
                      propagate any spam, junk mail, or unsolicited messages.<br /><br />Risk
                      Disclosure<br />Digital asset trading involves risks,
                      including but not limited to market risks, technical
                      risks, and security risks. You must carefully assess your
                      own risk tolerance and decide whether to participate in
                      trading. The Exchange is not liable for any investment
                      losses incurred by you.<br /><br />Fees and Charges<br />The
                      Exchange may impose fees and charges related to your use
                      of the Exchange services. You agree to pay the applicable
                      fees and charges and understand that these fees and
                      charges may be subject to change at any time.<br /><br />Privacy
                      Policy<br />The Exchange will protect your personal
                      information in accordance with applicable privacy laws.
                      Please review our Privacy Policy to understand how we
                      collect, use, and protect your personal information.<br /><br />Intellectual
                      Property<br />The Exchange and its related content,
                      including but not limited to trademarks, logos, graphics,
                      text, and software, are the property of the Exchange and
                      are protected by applicable intellectual property laws.
                      Without the written permission of the Exchange, you may
                      not use, copy, modify, distribute, or display any content
                      of the Exchange.<br /><br />Disclaimer of Liability<br />The
                      Exchange is not liable for any direct or indirect losses
                      incurred as a result of using the Exchange, including but
                      not limited to trading losses, data loss, business
                      interruption, or other economic losses.<br /><br />Termination<br />The
                      Exchange reserves the right to terminate, suspend, or
                      restrict your access to the Exchange at any time. The
                      Exchange also reserves the right to modify or update these
                      Terms of Service at any time. You should review these
                      Terms of Service periodically for the latest
                      information.<br /><br />Applicable Law and Jurisdiction<br />These
                      Terms of Service are governed by the laws of [European
                      country/United States/Canada, etc.] and shall be
                      interpreted in accordance with them. Any disputes arising
                      from the use of the Exchange shall be submitted to the
                      competent courts of [applicable jurisdiction].<br /><br />Miscellaneous<br />These
                      Terms of Service constitute the entire agreement between
                      you and the Exchange regarding the Exchange and supersede
                      all prior oral or written agreements between you and the
                      Exchange. If any provision of these Terms of Service is
                      deemed invalid or unenforceable, the remaining provisions
                      shall remain in effect.<br /><br />If you have any
                      questions or concerns, please contact our customer support
                      team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav class="nav_mobile">
        <div class="menu_content">
          <div class="nav_item">
            <img src="../../static/img/icon_menu1.188ef434.svg" alt="" />
          </div>
          <div class="nav_item">
            <img src="../../static/img/icon_menu2.853a4cd5.svg" alt="" />
          </div>
          <div class="nav_item">
            <img src="../../static/img/icon_menu3_active.1e4919b6.svg" alt="" />
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import {
  user,
  purse,
  emailCode,
  emailAuthentication,
  invite,
} from "@/api/tab5";
import { initIndex, loginout } from "@/api/tab4";
import { showNotify, showToast, showConfirmDialog } from "vant";
import { ElLoading } from "element-plus";
import { adminuplang } from "@/api/hometab";

export default {
  data() {
    return {
      increase: "", //涨幅
      new_price: "", //当前价
      walletaccount: "", //钱包账号
      userid: "", //当前用户id
      isBlack: "true", //默认黑色主题 //true代表黑色主题，false代表白色主题。。。
      setThemeVal: true,
      languages: [
        { code: "en", name: "English" },
        { code: "fr", name: "French" },
        { code: "es", name: "Español" },
        { code: "ar", name: "عربي" },
        { code: "ru", name: "рyCCKИЙ" },
        { code: "ko", name: "Korean" },
        { code: "tc", name: this.$t('516529-27') },
        { code: "jp", name: this.$t('516529-28') },
      ],
      vipimgsrc: "",
      vipshow: false,
      languageOpen: false,
      isInvited: false,
      isEmail: false,
      nowlang: "",
      mailbox: "",
      verificationCode: "",
      title: "",
      buttonText: "",
      countdownActive: false,
      secondsRemaining: 60,
      countdownText: this.$t('516529-29'),
    };
  },
  created() {
    var element = document.getElementById("app");
    element.style.height = "100%";
    this.getHomeData();
    this.nowlang = localStorage.getItem("locale");
    this.getInvite();
  },
  methods: {
    getCopyLink() {
      const buttonText = this.buttonText;
      console.log(buttonText);
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", buttonText);
      input.setAttribute("create", 1);
      input.select();
      document.execCommand("Copy");
      var list = document.getElementsByTagName("input");
      var inputList = Array.prototype.slice.call(list);
      inputList.forEach((item) => {
        if (item.getAttribute("create")) document.body.removeChild(item);
      });
      showToast({
        message: this.$t('516529-30'),
        duration: 1500,
        forbidClick: true,
        icon: "success",
      });
    },
    getInvite() {
      invite().then((res) => {
        console.log(res.data);
        console.log(res.data.status);
        if (!res.data.status) {
          showFailToast(res.data.info);
        } else {
          const origin = window.location.origin;
          const inviteCode = res.data.info.code;
          this.title = `${origin}/invit?code=${inviteCode}`;
          console.log(this.title);
          //console.log(window.location.origin);
          this.buttonText = this.title;
          //this.buttonText = window.location.origin + '/#/empty_wallet?invit=' + res.data.info.code
          console.log(this.buttonText);
        }
      });
    },
    quehuan(langtype) {
      this.nowlang = langtype;
    },
    selectLanguage(code) {
      this.nowlang = code;
    },

    confirmAuthentication() {
      let obl = {
        mail: this.mailbox,
        code: this.verificationCode,
      };
      emailAuthentication(obl)
        .then((res) => {
          if (res.data.status == 0) {
            showToast({
              message: res.data.info,
              duration: 1500,
              forbidClick: true,
              icon: "error",
            });
          } else {
            showToast({
              message: res.data.info,
              duration: 1500,
              forbidClick: true,
              icon: "success",
            });
            this.isEmail = false;
          }
        })
        .catch((error) => {
          showToast({
            message: error.message,
            duration: 1500,
            forbidClick: true,
            icon: "error",
          });
        });
    },
    sendEmail() {
      var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (regex.test(this.mailbox)) {
        this.startCountdown();
        emailCode({ mail: this.mailbox })
          .then((res) => {
            if (res.data.status == 0) {
              showToast({
                message: res.data.info,
                duration: 1500,
                forbidClick: true,
                icon: "error",
              });
            } else {
              showToast({
                message: res.data.info,
                duration: 1500,
                forbidClick: true,
                icon: "success",
              });
            }
          })
          .catch((error) => {
            showToast({
              message: error.message,
              duration: 1500,
              forbidClick: true,
              icon: "error",
            });
          });
      } else {
        showToast({
          message: this.$t("wallet_p.text37"),
          duration: 1500,
          forbidClick: true,
          icon: "error",
        });
      }
    },

    startCountdown() {
      this.countdownActive = true;
      this.countdownTimer = setInterval(this.updateCountdown, 1000);
    },

    updateCountdown() {
      this.secondsRemaining--;
      if (this.secondsRemaining <= 0) {
        this.clearCountdown();
      }
    },

    clearCountdown() {
      clearInterval(this.countdownTimer);
      this.countdownActive = false;
      this.secondsRemaining = 60;
      this.countdownText = this.$t('516529-31');
    },

    confirmReplacement() {
      adminuplang(this.nowlang)
        .then((res) => {
          //console.log(this.nowlang)
          //sessionStorage.setItem("locale", this.nowlang);
          localStorage.setItem("locale", this.nowlang);
          this.$i18n.locale = this.nowlang;
          this.$router.back();
        })
        .catch((error) => {
          //console.log(error.message)
          showToast(error.message);
        });
    },
    clickToOpen(val) {
      this[val] = !this[val];
    },
    //获取首页数据
    getHomeData() {
      //            user().then(res => {
      //                // State.homeData = res.data.url
      //                this.increase = Number(res.data.url.banlance).toFixed(2)
      //                this.new_price = Number(res.data.url.change).toFixed(2)

      // //console.log(this.walletaccount)
      //                //console.log(this.increase);
      //                //console.log(this.new_price);
      //            }).catch(err => {
      //                console.log('err-->', err)
      //            })
      //todo 模拟切换主题动画，提升交互效果
      /*
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
	  */
      initIndex()
        .then((res) => {
          //this.increase = Number(res.data.url.eth.change).toFixed(2)
          this.new_price = Number(res.data.url.eth.new_price).toFixed(2);
          //let username = sessionStorage.getItem("username") || "";
          let username = localStorage.getItem("username") || "";
          //this.walletaccount = new Array(10).join('*') + username.substr(-4)
          this.walletaccount = username;
          this.userid = res.data.url.userid;
          if (res.data.url.vip.img) {
            this.vipimgsrc = "https://09121.13lt.com" + res.data.url.vip.img;
            this.vipshow = true;
          }
          //loading.close();
        })
        .catch((err) => {
          console.log("err-->", err);
        });
      purse()
        .then((res) => {
          //console.log(res.data.info.allfee.eth)
          this.increase = Number(res.data.info.allfee.eth).toFixed(2);
        })
        .catch((err) => {
          console.log("err-->", err);
        });
    },
	gotocookie(){
		this.$router.push({
		  path: "/setcookies",
		});
	},
    gotoService(){
      this.$router.push({
        path:"/setService"
      })
    },
    gotoPolicy(){
      this.$router.push({
        path:"/setPolicy"
      })
    },
    towallte() {
      this.$router.push({
        path: "/wallet",
      });
    },
    toNotifications() {
      this.$router.push({
        path: "/notifiCations",
      });
    },
    toInvite() {
      this.$router.push({
        path: "/invite",
      });
    },
    toLegality() {
      this.$router.push({
        path: "/legality",
      });
    },
    toFaq() {
      this.$router.push({
        path: "/faq",
      });
    },
    toLanguage() {
      this.$router.push({
        path: "/language",
      });
    },
    google() {
      this.$router.push({
        path: "/google",
      });
    },
    loginout() {
      showConfirmDialog({
        title: "",
        message: this.$t("newtext3"),
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("newtext2"),
      })
        .then(() => {
          loginout()
            .then((res) => {
              //sessionStorageNaNpxoveItem("username");
              localStorage.clear();
              this.$router.push({
                path: "/empty_wallet",
              });
            })
            .catch((err) => {
              console.log("err-->", err);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //主题切换/*0708改*/
    toChangeTheme() {
      let that = this;
      that.setThemeVal = !that.setThemeVal;
      localStorage.setItem("isBlack", that.setThemeVal); //true代表黑色主题，false代表白色主题 | 注意缓存里面为字符串，，非bool型

      //todo 模拟切换主题动画，提升交互效果
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      setTimeout(() => {
        that.isBlack = localStorage.getItem("isBlack");
        //loading.close()
        location.reload();
      }, 1500);

      // showNotify({ type: 'success', message: 'success' });
    },
    setTheme() {
      this.isBlack = "true";
      if (localStorage.getItem("isBlack")) {
        this.isBlack = localStorage.getItem("isBlack"); //true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
      }
      if (this.isBlack == "true") {
        this.setThemeVal = true;
      } else {
        this.setThemeVal = false;
      }
    },
    toAuthenticator() {
      this.$router.push({
        path: "/authenticator",
      });
    },
    toRecord() {
      this.$router.push({
        path: "/record",
      });
    },
    toRecords() {
      this.$router.push({
        path: "/record",
        query: {
          active: 2,
        },
      });
    },
    toService() {
      window.open(
        "https://chat.ichatlink.net/widget/standalone.html?eid=231b4a7f30bee254e60c1be7bd19288c&language=en"
      );
    },
  },
  mounted() {
    this.setTheme(); /*0708改*/
  },
};
</script>

<style scoped>
body {
  color: #fff !important;
}

.popup_container {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.popup_container .popup_content {
  width: 100%;
  height: 100%;
  background: #151515;
  padding: 24px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
}
.popup_container .popup_content .popup_header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 10px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_header .header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
}
.popup_container .popup_content .popup_header .header_content {
  margin-top: 8px;
}
.popup_container .popup_content .popup_header .header_content .intro {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 12px;
}
.popup_container .popup_content .popup_body {
  border-top: 0.01rem solid hsla(0, 0%, 100%, 0.1);
  position: absolute;
  top: 98px;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
.popup_container .popup_content .popup_body .body_content {
  padding: 16px;
}
.load_button {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fcd23f;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #0b0b0b;
}
.load_button.loading {
  color: #9a9a9a;
}
.popup_container {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.popup_container .popup_content {
  width: 100%;
  height: 100%;
  background: #151515;
  padding: 24px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
}
.popup_container .popup_content .popup_header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 10px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_header .header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
}
.popup_container .popup_content .popup_header .header_content {
  margin-top: 8px;
}
.popup_container .popup_content .popup_header .header_content .intro {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 12px;
}
.popup_container .popup_content .popup_body {
  position: absolute;
  top: 110px;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
.popup_container .popup_content .popup_body .body_content {
  padding: 0 16px;
}
.popup_container .popup_content .popup_body .body_content .input_form {
  padding: 10px 8px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_title {
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_title
  img {
  margin-right: 16px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content {
  padding: 0 16px;
  margin-top: 12px;
  border: 0.01rem solid hsla(0, 0%, 100%, 0.1);
  border-radius: 16px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content
  .form_input {
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content
  .form_input
  input {
  flex: 1;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  caret-color: #fcd23f;
  background: none;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content
  .form_input
  input::-moz-placeholder {
  color: #8a8a8a;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content
  .form_input
  input:-ms-input-placeholder {
  color: #8a8a8a;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content
  .form_input
  input::placeholder {
  color: #8a8a8a;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content
  .form_input
  span {
  color: #fcd23f;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content
  .form_input
  span.countdown {
  color: rgba(252, 210, 63, 0.5);
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .input_form
  .form_content
  .line {
  width: 100%;
  height: 0.01rem;
  background: linear-gradient(
    270deg,
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0.1) 48.96%,
    hsla(0, 0%, 100%, 0.05)
  );
}
.popup_container .popup_content .popup_body .body_content .tip_content {
  margin-bottom: 24px;
  padding: 0 8px;
  margin-top: 16px;
  color: #8a8a8a;
  font-weight: 400;
  font-size: 14px;
}
.popup_container[data-v-319f9fec] {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.popup_container .popup_content[data-v-319f9fec] {
  width: 100%;
  height: 100%;
  background: #151515;
  padding: 24px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
}
.popup_container .popup_content .popup_header[data-v-319f9fec] {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 10px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_header .header_top[data-v-319f9fec] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
}
.popup_container .popup_content .popup_header .header_content[data-v-319f9fec] {
  margin-top: 8px;
}
.popup_container
  .popup_content
  .popup_header
  .header_content
  .intro[data-v-319f9fec] {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 12px;
}
.popup_container .popup_content .popup_body[data-v-319f9fec] {
  position: absolute;
  top: 96px;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
.popup_container .popup_content .popup_body .body_content[data-v-319f9fec] {
  padding-bottom: 24px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .switch_container[data-v-319f9fec] {
  padding: 0 16px;
  box-sizing: border-box;
  border-bottom: 0.01rem solid hsla(0, 0%, 100%, 0.1);
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .switch_wrapper[data-v-319f9fec] {
  width: 100%;
  overflow: auto;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .switch_wrapper[data-v-319f9fec]::-webkit-scrollbar {
  display: none;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .switch_wrapper
  .switch_content[data-v-319f9fec] {
  display: flex;
  align-items: center;
  color: #8a8a8a;
  font-size: 14px;
  font-weight: 500;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .switch_wrapper
  .switch_content
  .switch_item[data-v-319f9fec] {
  padding: 0 16px;
  margin-right: 8px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  white-space: nowrap;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .switch_wrapper
  .switch_content
  .switch_item[data-v-319f9fec]:last-child {
  margin-right: 24px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .switch_wrapper
  .switch_content
  .switch_item.active[data-v-319f9fec] {
  position: relative;
  color: #fff;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .switch_wrapper
  .switch_content
  .switch_item.active[data-v-319f9fec]:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.02rem;
  background: #fcd23f;
  border-radius: 0.01rem;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .faq_container[data-v-319f9fec] {
  margin-top: 16px;
  padding: 0 16px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .faq_container
  .faq_item[data-v-319f9fec] {
  margin-bottom: 8px;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  font-weight: 400;
  font-size: 14px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .faq_container
  .faq_item
  .faq_header[data-v-319f9fec] {
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .faq_container
  .faq_item
  .faq_content[data-v-319f9fec] {
  padding: 8px 0 16px 0;
  color: #8a8a8a;
}
.popup_container {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.popup_container .popup_content {
  width: 100%;
  height: 100%;
  background: #151515;
  padding: 24px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
}
.popup_container .popup_content .popup_header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 10px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_header .header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
}
.popup_container .popup_content .popup_header .header_content {
  margin-top: 8px;
}
.popup_container .popup_content .popup_header .header_content .intro {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 12px;
}
.popup_container .popup_content .popup_body {
  position: absolute;
  top: 96px;
  left: 0;
  bottom: 82px;
  width: 100%;
  overflow: auto;
}
.popup_container .popup_content .popup_body .body_content {
  padding: 24px 16px;
}
.popup_container .popup_content .popup_body .body_content .icon_share {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.popup_container .popup_content .popup_body .body_content .share_tip {
  padding: 0 12px;
  margin-top: 24px;
  text-align: center;
  color: #8a8a8a;
  font-weight: 400;
  font-size: 14px;
}
.popup_container .popup_content .popup_body .body_content .address_content {
  padding: 0 16px;
  margin-top: 44px;
  height: 48px;
  line-height: 48px;
  background: rgba(11, 11, 11, 0.5);
  border: 0.01rem dashed rgba(252, 210, 63, 0.24);
  border-radius: 16px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.popup_container .popup_content .popup_footer {
  position: absolute;
  z-index: 1;
  /* bottom: 0; */
  bottom: 80px;
  left: 0;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_footer .footer_btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fcd23f;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #0b0b0b;
}
.popup_container {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.popup_container .popup_content {
  width: 100%;
  height: 100%;
  background: #151515;
  padding: 24px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
}
.popup_container .popup_content .popup_header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 10px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_header .header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
}
.popup_container .popup_content .popup_header .header_content {
  margin-top: 8px;
}
.popup_container .popup_content .popup_header .header_content .intro {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 12px;
}
.popup_container .popup_content .popup_body {
  position: absolute;
  top: 110px;
  left: 0;
  bottom: 82px;
  width: 100%;
  overflow: auto;
}
.popup_container .popup_content .popup_body .body_content {
  padding-bottom: 24px;
}
.popup_container .popup_content .popup_body .body_content .lang_list {
  border-top: 0.01rem solid hsla(0, 0%, 100%, 0.1);
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .lang_list
  .lang_item {
  padding: 0 26px 0 16px;
  /* height: 56px; */
  height: 50px;
  border-bottom: 0.01rem solid hsla(0, 0%, 100%, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .lang_list
  .lang_item
  .radio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 0.015rem solid hsla(0, 0%, 54.1%, 0.5);
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .lang_list
  .lang_item
  .radio.selected {
  position: relative;
  border: 0.015rem solid #fcd23f;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .lang_list
  .lang_item
  .radio.selected:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fcd23f;
}
.popup_container .popup_content .popup_footer {
  position: absolute;
  z-index: 1;
  /* bottom: 0; */
  bottom: 80px;
  left: 0;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_footer .footer_btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fcd23f;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #0b0b0b;
}
.popup_container {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.popup_container .popup_content {
  width: 100%;
  height: 100%;
  background: #151515;
  padding: 24px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
}
.popup_container .popup_content .popup_header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 16px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_header .header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
}
.popup_container .popup_content .popup_header .header_content {
  margin-top: 8px;
}
.popup_container .popup_content .popup_header .header_content .intro {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 12px;
}
.popup_container .popup_content .popup_body {
  position: absolute;
  top: 110px;
  left: 0;
  bottom: 70px;
  width: 100%;
  overflow: auto;
}
.popup_container .popup_content .popup_body .body_content .section {
  margin-bottom: 16px;
  /* padding:0 16px*/
}
.popup_container .popup_content .popup_body .body_content .section .s_title {
  padding: 0 8px;
  font-weight: 400;
  font-size: 12px;
  color: #8a8a8a;
}
.popup_container .popup_content .popup_body .body_content .section .s_content {
  margin-top: 8px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item {
  position: relative;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item:last-child:after {
  display: none;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.01rem;
  background: linear-gradient(
    270deg,
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0.1) 48.96%,
    hsla(0, 0%, 100%, 0.05)
  );
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .name {
  display: flex;
  align-items: center;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .name
  img {
  margin-right: 16px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .switch_btn {
  width: 48px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .switch_btn
  .btn_bg {
  position: relative;
  width: 100%;
  height: 100%;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .switch_btn
  .btn_bg.open {
  background: #fcd23f;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .switch_btn
  .btn_bg.close {
  background: #cdcdcd;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .switch_btn
  .btn_bg
  .btn_dot {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .switch_btn
  .btn_bg
  .btn_dot.open {
  right: 2px;
}
.popup_container
  .popup_content
  .popup_body
  .body_content
  .section
  .s_content
  .menu_item
  .switch_btn
  .btn_bg
  .btn_dot.close {
  left: 2px;
}
.popup_container .popup_content .popup_body .logo_content {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.popup_container .popup_content .popup_body .logo_content .logo {
  width: 56px;
  height: 56px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup_container .popup_content .popup_body .logo_content .info {
  margin-top: 12px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.popup_container .popup_content .popup_body .logo_content .info .ver {
  margin-top: 4px;
  font-weight: 400;
  color: #8a8a8a;
}
.popup_container .bottom_popup {
  width: 100%;
  height: 100%;
  background: transparent;
}
.popup_container .center_popup {
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  background-color: transparent;
}

.name span {
  color: #fff;
}
.accountColor {
  color: #fff;
}

.lang_item span {
  color: #fff;
}

/**尾部样式 */

.nav_mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 100;
  background: linear-gradient(180deg, #151515, #000);
}
.nav_mobile .menu_content {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.nav_mobile .menu_content .nav_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
}
.nav_mobile .menu_content .nav_item img {
  height: 26px;
  width: auto;
}
.nav_mobile .menu_content .nav_item .item_name {
  margin-top: 4px;
  color: hsla(0, 0%, 73.3%, 0.7);
}
.nav_mobile .menu_content .nav_item .item_name.active {
  color: #4766f9;
}
.layout {
  width: 100%;
  padding-bottom: 86px;
}
</style>