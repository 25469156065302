import http from '@/utils/http'

//商品列表
export const getallcoinList = (data) => {
    return http({
        url: `/ajax/allcoin_a/id/${data.id}/type/${data.type}`,
        method: 'get',
        params:{coin:data.coin}
    })
}

//K线
export const kxianbData = (data) => {
    return http({
        url: `/ajax/kxianb/name/${data.name}/time/${data.time}`,
        method: 'get'
    })
}
//K线
export const kline = (data) => {
    return http({
        url: `ajax/allcoin_a/id/${data.id}/type/${data.type}/p/${data.p}`,
        method: 'get'
    })
}
//余额
export const huoqubalance = () => {
    return http({
        url: `user/balance`,
        method: 'get'
    })
}

//获取订单信息
export const usergetorder = (data) => {
    return http({
        url: `/user/getorder`,
        method: 'get',
        params:data

    })
}





//秒数
export const tradesecond = () => {
    return http({
        url: `/trade/tradeinfo`,
        method: 'get'
    })
}

//永续合约交易
export const TradeupTrade2 = (param) => {
    return http({
        url: '/Mobile/Trade/upTrade2',
        method: 'post',
        data: param
    })
}

export const uploadjk = (param) => {
    return http({
        url: '/home/api/image',
        method: 'post',
        data: param
    })
}

export const recharge = (param) => {
    return http({
        url: 'Finance/recharge',
        method: 'post',
        data: param
    })
}


//永续合约订单列表
export const usergetyxorder = (data) => {
    return http({
        url: `/Mobile/Ajax/getEntrustAndUsercoin1`,
        method: 'get',
        params:data

    })
}
//永续合约平仓
export const yxpingcang = (data) => {
    return http({
        url: `/home/order/upOrder2`,
        method: 'get',
        params:data

    })
}

//现货下单
export const TradeupTrade3 = (param) => {
    return http({
        url: '/Mobile/Trade/upTrade3',
        method: 'post',
        data: param
    })
}
//现货下单记录
export const spotaccountlist = (data) => {
    return http({
        url: `/user/spotaccount`,
        method: 'get',
        params:data

    })
}
//现货持有列表
export const spotapurselist = (data) => {
    return http({
        url: `/api/purse`,
        method: 'get',
        params:data

    })
}

//期权交易
export const TradeoptionTrade = (param) => {
    return http({
        url: '/Trade/optionTrade',
        method: 'post',
        data: param
    })
}

//获取货币余额
export const getbanlance = () => {
    return http({
        url: `/user/getbanlance`,
        method: 'get'
    })
}

//获取通知消息数量
export const getmsgunread = () => {
    return http({
        url: `/user/getmsgunread`,
        method: 'get'
    })
}

//消息列表 以及查看详细信息（传id）
export const getmsglistandorder = (data) => {
    return http({
        url: `/user/msg`,
        method: 'get',
        params:data

    })
}

// 通知消息阅读状态
export const setupmsgedit = (data) => {
    return http({
        url: `/user/msgedit`,
        method: 'get',
        params:data

    })
}
